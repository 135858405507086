import { useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import ButtonCustom from "../../components/button/ButtonCustom";
import ButtonsGruoup from "../../components/button/ButtonsGroup";
import ChartLineCustom from "../../components/chart/ChartLineCustom";
import TradeTabs from "../components/TradeTabs";
import {
	CircularProgressbarWithChildren,
	buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FunBenefitsModal from "../components/modal/FunBenefitsModal";

const BUTTON_LIST = [
	{
		label: "24H",
		code: "0",
		btnClass: "button-grey-light",
	},
	{
		label: "7D",
		code: "1",
		btnClass: "button-grey-light",
	},
	{
		label: "30D",
		code: "2",
		btnClass: "button-grey-light",
	},
	{
		label: "ALL",
		code: "3",
		btnClass: "button-grey-light",
	},
];

const initialData = [
	{ time: "2018-12-01", value: 12.68 },
	{ time: "2018-12-02", value: 12.68 },
	{ time: "2018-12-03", value: 11.11 },
	{ time: "2018-12-04", value: 17.02 },
	{ time: "2018-12-05", value: 17.32 },
	{ time: "2018-12-06", value: 15.17 },
	{ time: "2018-12-07", value: 18.89 },
	{ time: "2018-12-08", value: 15.46 },
	{ time: "2018-12-09", value: 3.92 },
	{ time: "2018-12-10", value: 2.68 },
	{ time: "2018-12-11", value: 2.68 },
	{ time: "2018-12-12", value: 2.68 },
	{ time: "2018-12-13", value: 1.11 },
	{ time: "2018-12-14", value: 7.02 },
	{ time: "2018-12-15", value: 7.32 },
	{ time: "2018-12-16", value: 5.17 },
	{ time: "2018-12-17", value: 8.89 },
	{ time: "2018-12-18", value: 5.46 },
	{ time: "2018-12-19", value: 3.92 },
	{ time: "2018-12-20", value: 2.68 },
	{ time: "2018-12-21", value: 2.67 },
	{ time: "2018-12-22", value: 2.51 },
	{ time: "2018-12-23", value: 8.89 },
	{ time: "2018-12-24", value: 7.02 },
	{ time: "2018-12-25", value: 7.32 },
	{ time: "2018-12-26", value: 5.17 },
	{ time: "2018-12-27", value: 8.89 },
	{ time: "2018-12-28", value: 5.46 },
	{ time: "2018-12-29", value: 3.92 },
	{ time: "2018-12-30", value: 2.68 },
	{ time: "2018-12-31", value: 2.67 },
];

const series1 = [
	{
		lineColor: "#72f238",
		areaTopColor: "#263333",
		areaBottomColor: "#1f2330",
		data: initialData,
	},
];

const HoldingsPage = () => {
	const [shouldOpenModal, setShouldOpenModal] = useState(false);

	return (
		<div className="holdings-page-container">
			<div className="spaces mb-24">
				<div className="holding-chart-container holding-frame">
					<div className="d-flex flex-column justify-content-between">
						<div className="spaces mb-24">
							<div className="spaces fw-800">FUNBIT HOLDINGS</div>
							<div className="text-color-white spaces fs-32 fw-800">
								0.00 FUNBIT
							</div>
							<div className="spaces fs-16 fw-500">$0.00</div>
							<div className="d-flex align-items-center">
								<span className="text-color-green spaces fs-16">15.34%</span>
								<KTSVG
									path="/media/svg/icons/increment.svg"
									svgClassName="spaces mb-2 w-9 h-11 text-color-green"
								/>
								<span className="text-color-gray spaces fs-12">
									FUNBIT Price
								</span>
							</div>
						</div>
						<ButtonsGruoup
							buttonList={BUTTON_LIST}
							btnActiveDefault={BUTTON_LIST[2].code}
						/>
					</div>
					<div className="chart-content">
						<div className="d-flex align-items-center spaces gap-24 mb-24">
							<div className="header-content">
								<div className="spaces pb-15">
									<span className="spaces fw-800">FUNBIT BENEFITS</span>
									<span className="color-orange spaces fw-800 mr-10">
										{" "}
										LEVEL 0
									</span>
									$0<span className="text-color-white">/$0</span>
								</div>
								<div className="progress-bar"></div>
							</div>
							<ButtonCustom
								label="View All"
								labelClassName="text-capitalize text-color-gray"
								btnClassName="button button-grey-light"
								handleClick={() => setShouldOpenModal(true)}
							/>
						</div>
						<ChartLineCustom
							data={initialData}
							height={220}
							seriesData={series1}
						/>
					</div>
				</div>
				<div className="holding-item-list flex-wrap">
					<div className="holding-item-wrapper holding-frame">
						<div className="header">
							FUNBIT RAKEBACK <span className="color-yellow-green">LVL 0</span>
						</div>
						<div className="spaces w-110 mt-15 mb-15">
							<CircularProgressbarWithChildren
								value={50}
								text={""}
								strokeWidth={12}
								styles={buildStyles({
									pathColor: "#c9ff2e",
									trailColor: "#292d3c",
								})}
							>
								<KTSVG
									path="/media/svg/navigation/Degen EXchange.svg"
									svgClassName="spaces w-26 h-26 color-yellow-green"
								/>
							</CircularProgressbarWithChildren>
						</div>
						<span className="color-yellow-green spaces pt-8 pb-8">+50%</span>
						<div>Increase your current rakeback</div>
					</div>
					<div className="holding-item-wrapper holding-frame">
						<div className="header">
							FUNBIT RAKEBACK{" "}
							<span className="color-turquoise-blue">LVL 0</span>
						</div>
						<div className="spaces w-110 mt-15 mb-15">
							<CircularProgressbarWithChildren
								value={30}
								text={""}
								strokeWidth={12}
								styles={buildStyles({
									pathColor: "#00f0ff",
									trailColor: "#292d3c",
								})}
							>
								<KTSVG
									path="/media/svg/navigation/rollbit-coin/fixed-fee.svg"
									svgClassName="spaces w-26 h-26 color-turquoise-blue"
								/>
							</CircularProgressbarWithChildren>
						</div>
						<span className="color-turquoise-blue spaces pt-8 pb-8">+30%</span>
						<div>Increase your current rakeback</div>
					</div>
					<div className="holding-item-wrapper holding-frame">
						<div className="header">
							FUNBIT RAKEBACK <span className="color-lime-yellow">LVL 0</span>
						</div>
						<div className="spaces w-110 mt-15 mb-15">
							<CircularProgressbarWithChildren
								value={80}
								text={""}
								strokeWidth={12}
								styles={buildStyles({
									pathColor: "#eeff00",
									trailColor: "#292d3c",
								})}
							>
								<KTSVG
									path="/media/svg/navigation/crypto-futures.svg"
									svgClassName="spaces w-21 h-21 color-lime-yellow"
								/>
							</CircularProgressbarWithChildren>
						</div>
						<span className="color-lime-yellow spaces pt-8 pb-8">+80%</span>
						<div>Increase your current rakeback</div>
					</div>
				</div>
			</div>
			<TradeTabs />
			{shouldOpenModal && (
				<FunBenefitsModal handleClose={() => setShouldOpenModal(false)} />
			)}
		</div>
	);
};

export default HoldingsPage;
