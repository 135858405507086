import { useQuery } from "@tanstack/react-query";
import { useWallet } from "..";

export const useBalance = () => {
	const { account, unisat } = useWallet();
	const { data, refetch, ...rest } = useQuery({
		queryKey: unisat ? ["unisat/btc-balance", account] : [null],
		enabled: !!account,
		queryFn: async () => {
			const balance = await unisat?.getBalance();
			return balance;
		},
		refetchInterval: 10000,
	});

	return { data, refetch, ...rest };
};
