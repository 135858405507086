// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link } from "react-router-dom";
import "./tableCustom.scss";

type TProps = {
	columns: any[];
	data: any[];
	className?: string;
	rowChoosed?: any;
	handleClickRow?: (data: any) => void;
};

const TableCustom = ({
	columns,
	data,
	className,
	handleClickRow,
	rowChoosed,
}: TProps) => {
	return (
		<div className="table-container">
			<table className={`table-custom ${className}`}>
				<thead>
					<tr>
						{columns.length > 0 &&
							columns?.map((column: any, idx: number) => (
								<th
									key={`${column.name}-${idx}`}
									className={column.classHeader}
								>
									{column.name}
								</th>
							))}
					</tr>
				</thead>
				<tbody>
					{data?.length > 0 ? (
						data?.map((rowData: any, rowIndex: number) => (
							<tr
								className={`${
									rowChoosed && rowData.rowId === rowChoosed.rowId && "active"
								}`}
								key={rowIndex}
								onClick={() => {
									handleClickRow && handleClickRow(rowData);
								}}
							>
								{columns.map((column: any, indexColumn: number) => (
									<td className={column.classCell} key={column?.field}>
										{column?.render ? (
											column?.toPath ? (
												<Link key={indexColumn} to={column.toPath}>
													{column.render(rowData)}
												</Link>
											) : (
												column.render(rowData)
											)
										) : (
											rowData[column.field]
										)}
									</td>
								))}
							</tr>
						))
					) : (
						<tr className="text-center">
							<td colSpan={columns.length}>No Data</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default TableCustom;
