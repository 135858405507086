import { FC, useState } from "react";
import ConnectWalletModal from "../../../../components/modal/connect-wallet/ConnectWalletModal";
import TokenDetailModal from "./TokenDetailModal";
import MarketCapPopup from "./MarketCapPopup";
import { Tooltip } from "antd";
import { useQuery } from "@tanstack/react-query";
import FunbitService from "../../../../../services/funbitServices";
import { TickersInfo } from "../../../../../../types/marketplace-service";
import { toNumberFormat } from "../../../../../utils";

interface IProps {
	ticker?: string;
}

const TooltipCoin = () => {
	return (
		<>
			<div>
				<div>Progress: 2,100,000,000,000/2,100,000,000,000</div>
				<div>
					Confirmed 24h before:
					<span className="text-color-green"> 2,100,000,000,000</span>
				</div>
				<div>
					Confirmed between 1h and 24h:
					<span className="text-color-green"> 0</span>
				</div>
				<div>
					Confirmed in 1h:
					<span className="text-color-red"> 0</span>
				</div>
				<div>
					In memory pool:
					<span className="text-color-red"> 0</span>
				</div>
			</div>
		</>
	);
};

const CollectionBasicInfo: FC<IProps> = ({ ticker }) => {
	const [shouldOpenConnectWalletModal, setShouldOpenConnectWalletModal] =
		useState(false);
	const [shouldOpenTokenDetailModal, setShouldOpenTokenDetailModal] =
		useState(false);
	const [shouldOpenMarketCapPopup, setShouldOpenMarketCapPopup] =
		useState(false);

	// const { data: marketData } = useQuery({
	// 	queryKey: ["fetchMarketTicker", { ticker }],
	// 	queryFn: () => FunbitService.fetchMarketTicker(ticker || ""),
	// 	enabled: !!ticker,
	// });

	// const { data } = useQuery<TickersInfo, Error>({
	// 	queryKey: ["fetchInfoTicker", { ticker }],
	// 	queryFn: () => FunbitService.fetchInfoTicker(ticker || ""),
	// 	enabled: !!ticker,
	// });

	return (
		<>
			<div className="coin-basic-info-container">
				<div className="basic-info-wrapper">
					<div className="basic-info-content">
						{/* <img src="/media/images/coins/BTC.png" width={90} alt="" /> */}
						<div className="coin-info">
							<div className="d-flex align-items-center gap-2">
								<img
									src="/media/images/nft/collections/avatar/Crypto-Punks.webp"
									width={90}
									alt=""
								/>
								<div className="d-flex flex-column justify-content-between gap-32">
									<div
										className="spaces fs-32 fw-bold"
										style={{
											fontFamily: "Proto Mono, Helvetica, Arial, sans-serif",
										}}
									>
										{ticker?.toUpperCase()}
									</div>
									<div className="d-flex gap-12">
										<div
											className="d-flex gap-4"
											style={{ fontWeight: 500, fontSize: 14 }}
										>
											<span>0.013266 BTC</span>
											<span className="">Volumn</span>
										</div>
										<div
											className="d-flex gap-4"
											style={{ fontWeight: 500, fontSize: 14 }}
										>
											<span>0 BTC</span>
											<span>Floor Price</span>
										</div>
										<div
											className="d-flex gap-4"
											style={{ fontWeight: 500, fontSize: 14 }}
										>
											<span>0</span>
											<span>Listed</span>
										</div>
										<div
											className="d-flex gap-4 "
											style={{ fontWeight: 500, fontSize: 14 }}
										>
											<span>5</span>
											<span>items</span>
										</div>
										<div
											className="d-flex gap-4"
											style={{ fontWeight: 500, fontSize: 14 }}
										>
											<span>10</span>
											<span>Supply</span>
										</div>
									</div>
								</div>

								{/* <div className="d-flex spaces fs-16 gap-16">
									<div
										className="cursor-pointer"
										onClick={() => setShouldOpenTokenDetailModal(true)}
									>
										Token details {">"}
									</div>
								</div> */}
							</div>
							{/* <div className="spaces mt-10">
								<div className="d-flex align-items-center justify-content-between text-color-gray">
									<div>Minted: {toNumberFormat(+data?.minted!)}</div>
									<div>Supply: {toNumberFormat(+data?.max!)}</div>
								</div>
								<Tooltip
									placement="top"
									title={<TooltipCoin />}
									overlayStyle={{ maxWidth: "400px" }}
								>
									<div
										style={{
											width: `${
												((+data?.minted! || 0) / (+data?.max! || 0)) * 100
											}%`,
										}}
										className="progress-line"
									/>
								</Tooltip>
							</div> */}
						</div>
					</div>
					{/* <div className="btn-list-sale">
						<ButtonCustom
							label="List for sale"
							btnClassName="button button-yellow "
							handleClick={() => setShouldOpenConnectWalletModal(true)}
						/>
					</div> */}
				</div>
				{/* <div className="info-list">
					<div className="info-item-content">
						<div className="d-flex align-items-center justify-content-center spaces gap-10">
							<img src="/media/images/coins/BTC.png" width={24} alt="" />
							<span className="item-value">
								{marketData?.volume / 100_000_000}
							</span>
						</div>
						<div className="sub-content">Total volume</div>
					</div>
					<div className="border-end"></div>
					<div className="info-item-content">
						<div className="d-flex align-items-center justify-content-center spaces gap-10">
							<img src="/media/images/coins/BTC.png" width={24} alt="" />
							<span className="item-value">
								{marketData?.volume_of_24h / 100_000_000}
							</span>
						</div>
						<div className="sub-content">24h volume</div>
					</div>
					<div className="border-end"></div>
					<div className="info-item-content">
						<div className="d-flex align-items-center justify-content-center spaces gap-10">
							<img src="/media/images/coins/BTC.png" width={24} alt="" />
							<span className="item-value">
								{(marketData?.floor_price * marketData?.minted) / 100_000_000}
							</span>
						</div>
						<div
							className="sub-content cursor-pointer"
							onClick={() => setShouldOpenMarketCapPopup(true)}
						>
							Market cap {">"}
						</div>
					</div>
					<div className="border-end"></div>
					<div className="info-item-content">
						<div className="d-flex align-items-center justify-content-center spaces gap-10">
							<span className="item-value">
								{toNumberFormat(marketData?.total_transactions)}
							</span>
						</div>
						<div className="sub-content">Total transactions</div>
					</div>
					<div className="border-end"></div>
					<div className="info-item-content">
						<div className="d-flex align-items-center justify-content-center spaces gap-10">
							<span className="item-value">{marketData?.listed}</span>
						</div>
						<div className="sub-content">Num of sales</div>
					</div>
					<div className="border-end"></div>
					<div className="info-item-content">
						<div className="d-flex align-items-center justify-content-center spaces gap-10">
							<span className="item-value">
								{toNumberFormat(marketData?.holders_count)}
							</span>
						</div>
						<div className="sub-content">Holders</div>
					</div>
				</div> */}
			</div>
			{shouldOpenConnectWalletModal && (
				<ConnectWalletModal
					handleClose={() => setShouldOpenConnectWalletModal(false)}
				/>
			)}
			{/* {shouldOpenTokenDetailModal && (
				<TokenDetailModal
					handleClose={() => setShouldOpenTokenDetailModal(false)}
					max={data?.max!}
					deploy_blocktime={data?.deploy_blocktime!}
					inscription_id={data?.inscription_id!}
					creator={data?.creator!}
					inscription_number_end={data?.inscription_number_end!}
					inscription_number_start={data?.inscription_number_start!}
					limit={data?.limit!}
				/>
			)} */}
			{shouldOpenMarketCapPopup && (
				<MarketCapPopup
					handleClose={() => setShouldOpenMarketCapPopup(false)}
				/>
			)}
		</>
	);
};

export default CollectionBasicInfo;
