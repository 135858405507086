import React from "react";
import { shortenAddress } from "../../../../app/utils/funtionHelper";
import ButtonCustom from "../../../../app/modules/components/button/ButtonCustom";

type Props = {
	account: string;
};

const DisplayAccount = ({ account }: Props) => {
	return (
		<ButtonCustom
			btnClassName="button button-green btn--register"
			handleClick={() => {}}
			label={shortenAddress(account)}
		/>
	);
};

export default DisplayAccount;
