import { Outlet } from "react-router-dom";
import "./marketplace.scss";
import SubNavigation from "../components/sub-navigation/SubNavigation";
import { SUB_NAVIGATIONS_MARKETPLACE } from "./MarketPlaceConst";
import ButtonCustom from "../components/button/ButtonCustom";
import "../../modules/trading/modals/modals.scss";
import Brc20Popover from "./components/Brc20Popover";

const Marketplace = () => {
	

	return (
		<div className="marketplace-page-container page-container">
			<div className="marketplace-header">
				<span className="header-title">Funbit Marketplace</span>
				<div className="market-tag spaces ml-8">Ordinals</div>
			</div>
			<div className="d-flex justify-content-between w-100 spaces gap-4">
				<div className="d-flex spaces gap-4">
					<SubNavigation navigations={SUB_NAVIGATIONS_MARKETPLACE} />
					<Brc20Popover  />
				</div>
				<div className="d-flex spaces gap-2">
					<ButtonCustom
						svgClassName="spaces w-16 h-16 me-2 text-color-green"
						btnClassName="button button-grey spaces px-16 py-8 border"
						iconPath="/media/svg/general/circle_1.svg"
						label="ordinals"
						labelClassName="text-color-green"
					/>
					<ButtonCustom
						svgClassName="spaces w-16 h-16 me-2"
						btnClassName="button button-grey spaces px-16 py-8 border"
						label="automicals"
						iconPath="/media/svg/general/automicals.svg"
					/>
				</div>
			</div>
			<Outlet />
		</div>
	);
};

export default Marketplace;
