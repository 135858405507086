import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";

type Props = {
	to: string;
	title: string;
	icon?: string;
	fontIcon?: string;
	hasBullet?: boolean;
	target?: string;
	iconSize?: {
		width: number;
		height: number;
	};
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
	children,
	to,
	title,
	icon,
	fontIcon,
	iconSize,
	hasBullet = false,
	target,
}) => {
	const { pathname } = useLocation();
	const isActive = checkIsActive(pathname, to);
	const { config } = useLayout();
	const { app } = config;

	return (
		<div className="menu-item">
			{!!target ? (
				<a
					className="menu-link without-sub"
					href={to}
					target="_blank"
					rel="noreferrer"
				>
					{hasBullet && (
						<span className="menu-bullet">
							<span className="bullet bullet-dot"></span>
						</span>
					)}
					{icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
						<span className="menu-icon">
							{" "}
							<KTSVG
								path={icon}
								className="svg-icon-2"
								width={iconSize?.width}
								height={iconSize?.height}
							/>
						</span>
					)}
					{fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
						<i className={clsx("bi fs-3", fontIcon)}></i>
					)}
					<span className="menu-title">{title}</span>
				</a>
			) : (
				<Link
					className={clsx("menu-link without-sub", { active: isActive })}
					to={to}
				>
					{hasBullet && (
						<span className="menu-bullet">
							<span className="bullet bullet-dot"></span>
						</span>
					)}
					{icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
						<span className="menu-icon">
							{" "}
							<KTSVG
								path={icon}
								className="svg-icon-2"
								width={iconSize?.width}
								height={iconSize?.height}
							/>
						</span>
					)}
					{fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
						<i className={clsx("bi fs-3", fontIcon)}></i>
					)}
					<span className="menu-title">{title}</span>
				</Link>
			)}
			{children}
		</div>
	);
};

export { SidebarMenuItem };
