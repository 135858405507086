import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { ItemAsset } from "../../../../../../types/unisat";
import FunbitService from "../../../../../services/funbitServices";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import TextField from "../../../../components/text-field/TextField";
import { useListForSale } from "../../../hooks";
import AddInscribeTransferModal from "./AddInscribeTransferModal";
import { refListedControl } from "./ListedTab";
import SignTranSacTionModal from "./SignTranSactionModal";
import useGetBitcoinPrices from "../../../../../hooks/useGetBitcoinPrices";
import { satsToDollars } from "../../../../../utils";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { toast } from "react-toastify";
import { useWallet } from "../../../../../../providers/wallet_provider";

type TProps = {
	handleClose: () => void;
	nftInfo: ItemAsset;
};

const ListNFTForSaleModal = ({ handleClose, nftInfo }: TProps) => {
	const { account, unisat } = useWallet();
	const [messageApi, contextHolder] = message.useMessage();

	const [isLoading, setIsLoading] = useState(false);
	const { data } = useListForSale({
		ticker: nftInfo.ticker.toLowerCase(),
		start: 0,
		limit: 100,
		address: account,
	});
	const btcPrice = useGetBitcoinPrices();

	const { mutateAsync } = useMutation(FunbitService.createListItem);
	const { mutateAsync: mutateAsyncConfirmListItem } = useMutation(
		FunbitService.confirmListItem
	);

	const [nftTransferableChoosed, setNftTransferableChoosed] = useState<any>();
	const [step, setStep] = useState(1);
	const [priceValue, setPriceValue] = useState("");
	const navigate = useNavigate();
	const [
		shouldOpenAddInscribeTransferModal,
		setShouldOpenAddInscribeTransferModal,
	] = useState(false);
	const [shouldOpenSignTransactionModal, setShouldOpenSignTransactionModal] =
		useState(false);

	const handleSale = async () => {
		if (!nftTransferableChoosed?.inscriptionId || !account) {
			return;
		}
		try {
			setIsLoading(true);
			const pubkey = await unisat?.getPublicKey();
			const data = await mutateAsync({
				pubkey: pubkey || "",
				unitPrice: Number(priceValue),
				initPrice: Number(priceValue) * nftTransferableChoosed?.data?.amt,
				marketType: "fixed",
				address: account,
				eventType: "listed",
				inscriptionNumber: nftTransferableChoosed?.inscriptionNumber,
				isConfirmed: false,
				inscriptionId: nftTransferableChoosed?.inscriptionId,
				inscriptionType: "brc_20",
			});

			if (data?.psbt) {
				console.log("run sign");

				const psbtSign = await unisat?.signPsbt(data?.psbt, {
					autoFinalized: false,
					toSignInputs: data?.signIndexes?.map((item: number) => ({
						index: item,
						address: account,
						sighashTypes: [131],
					})),
				});

				await mutateAsyncConfirmListItem({
					auctionId: data?.id,
					psbt: psbtSign!,
				});
				handleClose();
				toast.success("List was successfully");
				refListedControl.refetch();
			}
		} catch (error: any) {
			let errorMessage = "Something went wrong";
			if (error?.response?.status === 403) {
				errorMessage = error?.response?.data?.message;
			}
			if (error?.code === 4001) {
				errorMessage = error?.message;
			}
			toast.error(errorMessage);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{contextHolder}
			<Modal
				contentClassName={"modal-wrapper list-nft-for-sale-modal"}
				show={true}
				size="lg"
				onHide={handleClose}
			>
				<div className="btn-exit">
					<div className="exit-icon" onClick={handleClose}>
						<KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
					</div>
				</div>
				<div className="modal-content">
					<div className="title">
						List <span className="text-color-yellow">{nftInfo?.ticker}</span>{" "}
						for sale
					</div>
					{step === 1 && (
						<>
							<div className="content-container">
								<div className="text-start">
									Transferable:{" "}
									<span className="text-color-yellow">
										{nftInfo?.transferableBalance}
									</span>{" "}
									{nftInfo?.ticker}
								</div>
								<div className="nft-transferable-list">
									{data?.detail?.map((nftItem) => (
										<div
											key={nftItem.inscriptionId}
											className={`nft-transferable-item ${
												nftTransferableChoosed?.inscriptionId ===
													nftItem?.inscriptionId && "active"
											} ${
												nftItem?.event ||
												nftItem?.inscription_listing?.from ||
												nftItem?.confirmations === 0
													? "disabled"
													: ""
											}`}
											onClick={() => {
												console.log("click");
												!(
													nftItem?.event ||
													nftItem?.inscription_listing?.from ||
													nftItem?.confirmations === 0
												) && setNftTransferableChoosed(nftItem);
											}}
										>
											<div className="nft-content">
												<div className="name">{nftItem.data.tick}</div>
												{nftItem?.event && (
													<div className="label">{nftItem.event}</div>
												)}
												<div className="value">{nftItem.data.amt}</div>
											</div>

											<div className="number">
												{nftItem?.confirmations === 0
													? "unconfirmed"
													: `#${nftItem.inscriptionNumber}`}
											</div>
										</div>
									))}
									<div
										className="nft-transferable-item"
										onClick={() => {
											navigate("/inscribe");
											// setShouldOpenAddInscribeTransferModal(true)
										}}
									>
										<div className="spaces fs-50 text-color-white">+</div>
										<div className="d-flex flex-column text-color-gray">
											<span>Inscribe</span>
											<span>TRANSFER</span>
										</div>
									</div>
								</div>
								<hr></hr>
								<div className="d-flex align-items-center justify-content-between">
									<div>
										Available:{" "}
										<span className="text-color-yellow">
											{nftInfo?.availableBalance}
										</span>{" "}
										{nftInfo?.ticker}
									</div>
									<div
										className="text-color-yellow cursor-pointer"
										onClick={() => {
											setShouldOpenAddInscribeTransferModal(true);
										}}
									>
										Inscribe TRANSFER
									</div>
								</div>
							</div>
							<div className="spaces my-16">
								The total value is limited to between 50000 sats(0.0005 BTC) and
								5000000 sats(0.05 BTC).
							</div>
						</>
					)}
					{step === 2 && (
						<>
							<div className="nft-transferable-list justify-content-center">
								<div className={`nft-transferable-item`}>
									<div className="nft-content">
										<div className="name">
											{nftTransferableChoosed?.data?.tick}
										</div>
										{/* {nftTransferableChoosed?.label && (
                      <div className="label">Listed</div>
                    )} */}
										<div className="value">
											{nftTransferableChoosed?.data?.amt}
										</div>
									</div>
									<div className="number">
										{nftTransferableChoosed?.inscriptionNumber}
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center spaces gap-12 fs-14">
								<div>Price</div>
								<TextField
									className="spaces mt-20"
									value={priceValue ? priceValue : ""}
									setValue={setPriceValue}
									type="number"
									placeholder="10.05"
								/>
								<div>
									sats/{nftTransferableChoosed?.data?.tick?.toLowerCase()}
								</div>
							</div>
							<div className="text-start spaces mb-36 mt-16">
								Total Value:{" "}
								{priceValue
									? (Number(priceValue) * nftTransferableChoosed?.data?.amt) /
									  100_000_000
									: 0}{" "}
								BTC ($
								{satsToDollars(
									Number(priceValue) * nftTransferableChoosed?.data?.amt,
									btcPrice
								)}
								)
							</div>
						</>
					)}
				</div>
				<div className="modal-btns spaces gap-12">
					{step === 1 && (
						<ButtonCustom
							label={`List - ${nftInfo?.ticker}`}
							btnClassName={`button ${
								nftTransferableChoosed ? "button-yellow" : "button-grey-light"
							} spaces px-20 w-100 max-w-200`}
							handleClick={() => {
								nftTransferableChoosed && setStep((prevStep) => prevStep + 1);
							}}
						/>
					)}
					{step === 2 && (
						<>
							<ButtonCustom
								label={"Back"}
								labelClassName="text-color-yellow"
								btnClassName={`button button-grey-light spaces px-20 w-100 max-w-90`}
								handleClick={() => {
									setStep((prevStep) => prevStep - 1);
									setNftTransferableChoosed(null);
								}}
							/>
							<ButtonCustom
								label={
									isLoading ? (
										<div className="d-flex align-items-center gap-4">
											<Spinner animation="border" size="sm" /> Loading...
										</div>
									) : (
										`List & ${nftInfo?.ticker} for sale`
									)
								}
								btnClassName={`button ${
									priceValue ? "button-yellow" : "button-grey-light"
								} spaces px-20 w-100 max-w-240 ${isLoading ? "disabled" : ""}`}
								handleClick={handleSale}
							/>
						</>
					)}
				</div>
			</Modal>
			{shouldOpenAddInscribeTransferModal && (
				<AddInscribeTransferModal
					nftInfo={nftInfo}
					handleClose={() => setShouldOpenAddInscribeTransferModal(false)}
				/>
			)}
			{shouldOpenSignTransactionModal && (
				<SignTranSacTionModal
					handleClose={() => setShouldOpenSignTransactionModal(false)}
				/>
			)}
		</>
	);
};

export default ListNFTForSaleModal;
