import axios from "axios";
import { useQueries } from "react-query";

const ids = [
	"https://api.coinbase.com/v2/prices/BTC-USD/spot",
	"https://api.kraken.com/0/public/Ticker?pair=XBTUSD",
	"https://api.coindesk.com/v1/bpi/currentprice.json",
	"https://api.gemini.com/v2/ticker/BTCUSD",
	"https://api-testnet.bybit.com/derivatives/v3/public/order-book/L2?category=linear&symbol=BTCUSDT",
];

const useGetBitcoinPrices = () => {
	const results = useQueries(
		ids.map((url) => ({
			queryKey: ["bitcoin_price", url],
			queryFn: () =>
				axios.get(url).then((res) => {
					return res.data;
				}),
			refetchInterval: 3600000,
		}))
	);

	if (results.every((result) => result.status === "success")) {
		const price = results
			.map((result, idx) => {
				switch (idx) {
					case 0:
						return result?.data?.data?.amount || 0;
					case 1:
						return result?.data?.result?.XXBTZUSD?.["a"]?.[0] || 0;
					case 2:
						return result?.data?.["bpi"]?.["USD"]?.["rate_float"] || 0;
					case 3:
						return result?.data?.bid || 0;
					case 4:
						return result?.data?.["result"]?.["b"]?.[0]?.[0] || 0;
				}
			})
			.sort()[2];
		return Number(price);
	}

	return 0;
};

export default useGetBitcoinPrices;
