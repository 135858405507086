import { Link, useLocation } from "react-router-dom"
import { KTSVG, checkIsActive } from "../../../../../helpers"
import { useEffect, useState } from "react"

const NAVBAR_CODE_LIST = {
    MENU: "1",
    INSCRIBE: "2",
    TRADE: "3",
    MARKETPLACE: "4",
    CHAT: "5",
}

const NAVBAR_LIST = [
    {
        code: NAVBAR_CODE_LIST.MENU,
        component: (props: any) => (
            <div {...props} id='kt_app_sidebar_mobile_toggle'>
                <div>
                    <KTSVG path="/media/svg/navigation/menu-icon.svg" className="text-color-gray" width={15} height={15} />
                </div>
                <div>Menu</div>
            </div>
        )
    },
    {
        code: NAVBAR_CODE_LIST.INSCRIBE,
        pathName: "/inscribe",
        component: (props: any) => (
            <Link to={"/inscribe"} {...props}>
                <div>
                    <KTSVG path="/media/svg/navigation/inscribe.svg" className="text-color-gray" width={14} height={16} />
                </div>
                <div>Inscribe</div>
            </Link>
        )
    },
    {
        code: NAVBAR_CODE_LIST.TRADE,
        pathName: "/trading",
        component: (props: any) => (
            <Link to={"/trading"} {...props}>
                <div>
                    <KTSVG path="/media/svg/navigation/rollbit-coin/Trade RLB.svg" className="text-color-gray" width={10} height={16} />
                </div>
                <div>Future</div>
            </Link>
        )
    },
    {
        code: NAVBAR_CODE_LIST.MARKETPLACE,
        pathName: "/marketplace",
        component: (props: any) => (
            <Link to={"/marketplace"} {...props}>
                <div>
                    <KTSVG path="/media/svg/navigation/nft/marketplace.svg" className="text-color-gray" width={15} height={15} />
                </div>
                <div>Marketplace</div>
            </Link>
        )
    },
    {
        code: NAVBAR_CODE_LIST.CHAT,
        component: (props: any) => (
            <Link to={"#"} {...props}>
                <div>
                    <KTSVG path="/media/svg/general/message.svg" className="text-color-gray" width={14} height={15} />
                </div>
                <div>Chat</div>
            </Link>
        )
    },
]

const NavbarMobile = ({ toggleMessageBox }: any) => {
    const [navbarActive, setNavbarActive] = useState<any>();
    const [navbarLinkActive, setNavbarLinkActive] = useState<any>("");
    const { pathname } = useLocation();

    useEffect(() => {
        const isActive = navbarActive?.pathName && checkIsActive(pathname, navbarActive?.pathName);
        isActive && setNavbarLinkActive(navbarActive);
    }
    , [navbarActive, pathname])

    useEffect(() => {
        NAVBAR_LIST.forEach(navbar => {
            const isActive = navbar?.pathName && checkIsActive(pathname, navbar?.pathName);
            isActive && setNavbarActive(navbar);
        })
    }, [pathname])
    
    const handleClickNavbar = (navbar: any) => {
        setNavbarActive(navbar);

        ((navbar.code !== NAVBAR_CODE_LIST.CHAT && navbarActive?.code === NAVBAR_CODE_LIST.CHAT) || navbar.code === NAVBAR_CODE_LIST.CHAT) && toggleMessageBox();
        navbar.code === NAVBAR_CODE_LIST.CHAT && navbarActive?.code === NAVBAR_CODE_LIST.CHAT && setNavbarActive(navbarLinkActive);

        navbar.code === NAVBAR_CODE_LIST.MENU && navbarActive?.code === NAVBAR_CODE_LIST.MENU && setNavbarActive(navbarLinkActive);
    }
   
    return (
        <>
            <div className="navbar-container">
                {NAVBAR_LIST.map(navbar => {
                    return (
                        <>
                            {navbar.component({
                                className: `navbar-item ${navbar.code === navbarActive?.code ? "active" : ""}`,
                                onClick: () => handleClickNavbar(navbar)
                            })}
                        </>
                    )
                })}
            </div>
        </>
    )
}

export default NavbarMobile 