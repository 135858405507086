import { ConfigProvider, Tabs } from "antd";
import { KTSVG } from "../../../_metronic/helpers";
import SelectSearch from "../components/select/SelectSearch";
import InputCustom from "../components/text-field/InputCustom";
import ButtonCustom from "./../components/button/ButtonCustom";
import { TIMES_OPTIONS, columns4, data4 } from "./constant";
import "./homePage.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BRC20Tab from "./components/BRC-20-tab/BRC20Tab";
import CollectionsTab from "./components/collection-tab/CollectionsTab";
import { useEffect, useState } from "react";
import TableCustom from "../components/table/TableCustom";
import { useFetchTickers } from "../marketplace/hooks";

const BUTTON_CODE = {
	ALL: "all",
	MINTING: "minting",
	COMPLETED: "completed",
};

const GROUP_BUTTONS = [
	{
		label: "All",
		code: BUTTON_CODE.ALL,
	},
	{
		label: "Minting",
		code: BUTTON_CODE.MINTING,
	},
	{
		label: "Completed",
		code: BUTTON_CODE.COMPLETED,
	},
];

type Props = {};

const FunbitHomepage = (props: Props) => {
	const navigate = useNavigate();
	const [tickInfo, setTickInfo] = useState<any>(null);
	const [rankingTab, setRankingTab] = useState<any>();
	const [buttonActiveCode, setButtonActiveCode] = useState(BUTTON_CODE.ALL);
	const [activeTab, setActiveTab] = useState("1");
	const location = useLocation();

	const { list } = useFetchTickers({
		limit: 15,
		start: 1,
		status: buttonActiveCode,
	});

	useEffect(() => {
		tickInfo && navigate(`/marketplace/${tickInfo?.tick}`);
	}, [navigate, tickInfo]);

	useEffect(() => {
		if (location?.search?.includes("collections") && activeTab !== "2") {
			setActiveTab("2");
		}
	}, [activeTab, location?.search]);

	useEffect(() => {
		setRankingTab([
			{
				key: "1",
				label: <span className="fs-3">BRC-20</span>,
				children: (
					<BRC20Tab
						children={
							<TableCustom
								columns={columns4}
								data={list}
								handleClickRow={setTickInfo}
							/>
						}
					/>
				),
			},
			{
				key: "2",
				label: <span className="fs-3">Collections</span>,
				children: <CollectionsTab />,
			},
		]);
	}, [buttonActiveCode, list]);

	return (
		<>
			<div className="banner-container-homepage">
				<div className="banner-left">
					<div className="banner-tab-group">
						<div className="banner-tab">
							<img
								src="/media/svg/coins/btc.png"
								width={32}
								height={32}
								alt=""
							/>
							<span className="banner-tab-label">Ordinals</span>
						</div>
					</div>
					<div className="banner-subtitle">
						One-stop hub for trading and creating BRC-20 and inscriptions
						<br />
						Explore Ordinals & NFT on Funbit Web3 Marketplace
					</div>
					<div className="banner-group-btn">
						<ButtonCustom
							btnClassName="button button-green"
							label="List collection"
							handleClick={() => setActiveTab("2")}
						/>
						<Link to={"/inscribe"}>
							<ButtonCustom
								btnClassName="button button-yellow"
								label="Inscribe"
							/>
						</Link>
					</div>
				</div>
				<div className="banner-right">
					<img src="/media/images/BRC_baner.png" alt="" />
				</div>
			</div>
			<div className="ranking-content">
				<ConfigProvider
					theme={{
						components: {
							Tabs: {
								inkBarColor: "#ffb018",
								itemColor: "#b1b6c6",
								itemHoverColor: "#ffb018",
								itemSelectedColor: "#ffb018",
							},
						},
						token: {
							colorBorderSecondary: "#888888",
						},
					}}
				>
					<Tabs
						defaultActiveKey={activeTab}
						items={rankingTab}
						activeKey={activeTab}
						onChange={(key) => {
							setActiveTab(key);
						}}
					/>
				</ConfigProvider>
				<div className="search-box">
					<SelectSearch
						classHeight="spaces h-40 mb-8"
						options={TIMES_OPTIONS}
						valueDefault={{ label: "All time", value: "all" }}
						position="absolute"
					/>
					<InputCustom
						className="spaces h-40 input-outline--yellow"
						placeholder="Search.."
						startInput={
							<KTSVG
								path={"/media/svg/icons/search.svg"}
								className="svg-icon-2"
								width={20}
								height={20}
							/>
						}
					/>
					<div className="d-flex justify-content-end group-button-container">
						<div className="group-button">
							{GROUP_BUTTONS.map((buttonItem) => {
								return (
									<ButtonCustom
										key={buttonItem.code}
										label={buttonItem.label}
										btnClassName={`button ${
											buttonActiveCode === buttonItem.code &&
											"button-grey-light"
										}`}
										handleClick={() => setButtonActiveCode(buttonItem.code)}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default FunbitHomepage;
