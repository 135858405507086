import { create } from "zustand";
import { AuctionOrderParam, NftType } from "../../types/marketplace-service";

export const AUCTION_ORDER_PARAM_INITIAL: AuctionOrderParam = {
  start: 1,
  limit: 20,
  nftType: NftType.BRC_20,
  tick: "like",
};

export type AuctionOrderParamState = {
  auctionOrderParam: AuctionOrderParam;
  setAuctionOrderParam: (param: Partial<AuctionOrderParam>) => void;
};

export const useAuctionOrderParamStore = create<AuctionOrderParamState>()(
  (set) => ({
    auctionOrderParam: AUCTION_ORDER_PARAM_INITIAL,
    setAuctionOrderParam: (param: Partial<AuctionOrderParam>) => {
      set((prev) => ({
        auctionOrderParam: { ...prev.auctionOrderParam, ...param },
      }));
    },
  })
);
