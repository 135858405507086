import "./TextField.scss";
type Props = {
  required?: boolean;
  disabled?: boolean;
  inputStart?: any;
  inputEnd?: any;
  label?: string;
  inputDes?: string;
  className?: string;
  inputClassName?: string;
  value?: string;
  setValue?: any;
  placeholder?: string;
  maxLength?: number;
  type?: string;
};

export default function TextField({
  required,
  inputStart,
  inputEnd,
  label,
  disabled,
  inputDes,
  className,
  inputClassName,
  value,
  setValue,
  placeholder,
  maxLength,
  type,
}: Props) {
  return (
    <div className="input-container w-100">
      {label && (
        <div className={`input-label ${className}`}>
          {label} {required && <span className="text-danger">*</span>}
        </div>
      )}
      <div className={`input-search-wrapper input-outline--yellow ${inputClassName}`}>
        {inputStart}
        <input
          disabled={disabled}
          className="input-search w-100"
          value={value}
          onChange={(e) => setValue?.(e.target.value)}
          placeholder={placeholder}
          maxLength={maxLength}
          type={type}
        />
        {inputEnd}
      </div>
      {inputDes && <div className="input-des">{inputDes}</div>}
    </div>
  );
}
