import { useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import TableCustom from "../../components/table/TableCustom";
import InputCustom from "../../components/text-field/InputCustom";
import { ITickers } from "../../../../types/funbit-service";
import { useFetchTickers } from "../hooks";

const culumns = [
	{
		name: "",
		field: "index",
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start text-capitalize spaces fw-6 fs-14",
		render: (rowData: any) => (
			<div className="text-color-white">
				<i className="bi bi-star-fill spaces fs-16 text-color-white"></i>
				{rowData.idx}
			</div>
		),
	},
	{
		name: "Tick",
		field: "tick",
		classHeader: "text-start text-capitalize spaces fs-16 min-w-100px",
		classCell: "text-start text-capitalize spaces fw-6 fs-14",
	},
	{
		name: "Volume (BTC)",
		field: "volume",
		classHeader: "text-end text-capitalize spaces fs-16 min-w-100px",
		classCell: "text-end text-capitalize spaces fw-6 fs-14",
	},
	{
		name: "Price (sats)",
		field: "floor_price",
		classHeader: "text-end text-capitalize spaces fs-16 min-w-100px",
		classCell: "text-end text-capitalize spaces fw-6 fs-14",
	},
	{
		name: "24h",
		field: "volume_of_24h",
		classHeader: "text-end text-capitalize spaces fs-16 min-w-100px",
		classCell: "text-end text-capitalize spaces fw-6 fs-14",
		render: (rowData: any) => (
			<span
				className={`${
					rowData.volume_of_24h ? "text-color-green" : "text-color-red"
				}`}
			>
				{rowData.volume_of_24h}
			</span>
		),
	},
];

const Brc20PopoverContent = ({
	handleHidePopover,
	handleChangeTickInfo,
	tickInfo,
}: any) => {
	const [rowChoosed, setRowChoosed] = useState(tickInfo);
	const [search, setSearch] = useState("");

	const { list, total, ads, deploy } = useFetchTickers({
		limit: 20,
		start: 1,
		status: "all",
		tick: search,
	});

	const TICK_RANK_LIST = [
		{
			sortBy: "Sorted by Deploy Time",
			tickList: ads,
		},
		{
			sortBy: "Sorted by Market Cap (Avg. Volume in 7d >= 1BTC)",
			tickList: deploy,
		},
		{
			sortBy: "Sorted by Volume (24h)",
			tickList: list,
		},
	];

	const handleChooseTick = (tickData: any) => {
		handleHidePopover();
		handleChangeTickInfo(tickData);
		setRowChoosed(tickData);
	};

	return (
		<div className="brc-20-popover-content">
			<div>
				<InputCustom
					value={search}
					onChange={setSearch}
					placeholder="Tick..."
					className="spaces h-40 bg-input input-outline--yellow"
					startInput={
						<KTSVG
							path={"/media/svg/icons/search.svg"}
							className="svg-icon-2"
							width={20}
							height={20}
						/>
					}
				/>
			</div>
			<div className="body">
				{search.length === 0 &&
					TICK_RANK_LIST.map((tickRankItem) => {
						return (
							<div className="tick-rank-container" key={tickRankItem.sortBy}>
								<div className="tick-rank--type">
									<div className="line"></div>
									<span className="name">{tickRankItem.sortBy}</span>
								</div>
								<div className="tick-rank-list">
									{tickRankItem.tickList.map(
										(tickItem: ITickers, index: number) => {
											return (
												<div
													key={index}
													className={`tick-choose-item ${
														tickInfo?.tickId === tickItem.tick && "active"
													}`}
													onClick={() => handleChooseTick(tickItem)}
												>
													<div className="index">
														<i className="bi bi-star-fill spaces fs-16 text-color-white"></i>
														#{index + 1}
													</div>
													<div className="name">
														{tickItem.tick.toUpperCase()}
													</div>
													<div className="price-container">
														<div className="price">{tickItem.unit_price}</div>
														<div className="unit"> sats/{tickItem.tick}</div>
														<div
															className={`usd ${
																tickItem.volume_of_24h
																	? "up-color"
																	: "down-color"
															}`}
														>
															{!tickItem.volume_of_24h && "-"}
															{tickItem.floor_price}
														</div>
													</div>
												</div>
											);
										}
									)}
								</div>
							</div>
						);
					})}
				<div>
					<TableCustom
						columns={culumns}
						data={
							search
								? list
								: list
										.map((item: any, idx: number) => ({
											...item,
											idx: idx + 1,
										}))
										.slice(3, -1)
						}
						rowChoosed={rowChoosed}
						handleClickRow={handleChooseTick}
					/>
				</div>
			</div>
		</div>
	);
};

export default Brc20PopoverContent;
