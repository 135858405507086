import React from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { KTSVG } from "../../../../_metronic/helpers";
import ButtonCustom from "../../components/button/ButtonCustom";

type Props = {
	handleClose: () => void;
	handleAgreedBtn: () => void;
	isShowBtn: boolean;
	isShowModal: boolean;
};

const RiskWarningModal = ({
	isShowBtn,
	isShowModal,
	handleClose,
	handleAgreedBtn,
}: Props) => {
	return (
		<Modal show={isShowModal} size="lg" contentClassName="spaces max-w-600">
			<Modal.Body>
				<div onClick={handleClose}>
					<KTSVG
						className="btn-exit"
						svgClassName="spaces h-20 h-20"
						path="/media/svg/action/exit.svg"
					/>
				</div>
				<div className="order-modal-container">
					<div className="order-header">
						<div className="title">Risk Warning</div>
					</div>
				</div>

				<ol
					className="d-flex flex-column gap-6 fs-5 p-0"
					style={{
						color: "hsla(0, 0%, 100%, .7)",
						listStylePosition: "inside",
					}}
				>
					<li>
						In case of platform order congestion, there might be delays in
						on-chain processing, and we cannot guarantee the on-chain processing
						time.
					</li>
					<li>
						The rate you submit now does not represent the real-time on-chain
						rate. Please be aware of the fluctuating on-chain rate.
					</li>
					<li>
						If there are unconfirmed outbound transactions in your payment
						account, please stop the payment or switch to another account for
						operation.
					</li>
					<li>
						When encountering orders with ongoing engraving, please patiently
						wait for the miner's confirmation, as the transaction cannot be
						revoked.
					</li>
				</ol>
				<div style={{ color: "hsla(0, 0%, 100%, .5)" }}>
					Please carefully evaluate and ensure that you fully understand these
					risks, and are willing to bear all consequences arising from them.
				</div>
				{isShowBtn && (
					<div className="mt-10 d-flex w-100 justify-content-center">
						<ButtonCustom
							label="I have read and agreed to the risk warning"
							btnClassName="button button-yellow"
							handleClick={handleAgreedBtn}
						/>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default RiskWarningModal;
