import clsx from "clsx";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../../../../_metronic/helpers";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import { TickersInfo } from "../../../../../../types/marketplace-service";
import moment from "moment";
import { shortenAddress, toNumberFormat } from "../../../../../utils";

interface TProps
	extends Pick<
		TickersInfo,
		| "max"
		| "deploy_blocktime"
		| "inscription_id"
		| "creator"
		| "inscription_number_end"
		| "inscription_number_start"
		| "limit"
	> {
	handleClose: () => void;
}

const TokenDetailModal = ({
	handleClose,
	max,
	deploy_blocktime,
	inscription_id,
	creator,
	inscription_number_start,
	inscription_number_end,
	limit,
}: TProps) => {
	return (
		<>
			<Modal
				contentClassName={clsx("modal-wrapper")}
				show={true}
				onHide={handleClose}
			>
				<div className="modal-content spaces fs-14">
					<div className="d-flex align-items-center justify-content-between spaces h-32">
						<div className="text-color-gray">Total max</div>
						<div>{toNumberFormat(+max)}</div>
					</div>
					<div className="d-flex align-items-center justify-content-between spaces h-32">
						<div className="text-color-gray">Deployed</div>
						<div>{moment(deploy_blocktime).format("DD/MM/YYYY hh:mm:ss")}</div>
					</div>
					<div className="d-flex align-items-center justify-content-between spaces h-32">
						<div className="text-color-gray">Inscription ID</div>
						<div className="d-flex align-items-center spaces gap-10">
							{shortenAddress(inscription_id || "")}
							{/* <KTSVG
                svgClassName="spaces w-14 h-14"
                path="/media/svg/icons/copy.svg"
              /> */}
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-between spaces h-32">
						<div className="text-color-gray">Deployed by</div>
						<div className="d-flex align-items-center spaces gap-10">
							{shortenAddress(creator || "")}
							{/* <KTSVG
                svgClassName="spaces w-14 h-14"
                path="/media/svg/icons/copy.svg"
              /> */}
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-between spaces h-32">
						<div className="text-color-gray">Inscription starting no.</div>
						<div>#{inscription_number_start}</div>
					</div>
					<div className="d-flex align-items-center justify-content-between spaces h-32">
						<div className="text-color-gray">Inscription ending no.</div>
						<div>#{inscription_number_end}</div>
					</div>
					<div className="d-flex align-items-center justify-content-between spaces h-32">
						<div className="text-color-gray">Limit per mint</div>
						<div>{toNumberFormat(+limit)}</div>
					</div>
					<div className="d-flex align-items-center justify-content-between spaces h-32">
						<div className="text-color-gray">Decimal</div>
						<div>18</div>
					</div>
				</div>
				<div className="modal-btns text-center">
					<ButtonCustom
						label="Ok"
						btnClassName="button button-grey-light spaces px-20 w-100"
						handleClick={handleClose}
					/>
				</div>
			</Modal>
		</>
	);
};

export default TokenDetailModal;
