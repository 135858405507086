import { ConfigProvider, Tabs } from "antd";
import { ORDER_TABS } from "../constants";

const OrdersContent = () => {
	return (
		<div className="orders-tab-container spaces mt-40">
			<ConfigProvider
				theme={{
					components: {
						Tabs: {
							inkBarColor: "#ffb018",
							itemColor: "#b1b6c6",
							itemHoverColor: "#ffb018",
							itemSelectedColor: "#ffb018",
						},
					},
					token: {
						colorBorderSecondary: "#888888",
					},
				}}
			>
				<Tabs defaultActiveKey="2" items={ORDER_TABS} />
			</ConfigProvider>
		</div>
	);
};

export default OrdersContent;
