import { useCallback, useEffect, useState } from "react";
import ButtonCustom from "../../components/button/ButtonCustom";
import ProofRadio from "../../components/text-field/ProofRadio";
import TextField from "../../components/text-field/TextField";
import { KTSVG } from "../../../../_metronic/helpers";
import { ConfigProvider, InputNumber, Slider } from "antd";
import DoubleCheckStep from "./DoubleCheckStep";
import InscribingOrderStep from "./InscribingOrderStep";
import { Controller, useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Inscription_Kind } from "../../../utils";

const brc20Schema = z.object({
	tick: z.string().length(4, "Tick must be 4 characters long"),
	max: z.string(),
	lim: z.string(),
	amount: z.string(),
	repeat: z.number(),
});

const RADIO_ID_LIST = {
	MINT: "Mint",
	DEPLOY: "Deploy",
	TRANSFER: "Transfer",
};

const radioList = [
	{
		label: "Mint",
		id: RADIO_ID_LIST.MINT,
	},
	{
		label: "Deploy",
		id: RADIO_ID_LIST.DEPLOY,
	},
	{
		label: "Transfer",
		id: RADIO_ID_LIST.TRANSFER,
	},
];

const Brc20Tab = ({ resetField }: { resetField: number }) => {
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<z.infer<typeof brc20Schema>>({
		mode: "onChange",
		resolver: zodResolver(brc20Schema),
		defaultValues: {
			tick: "",
			max: "",
			lim: "",
			amount: "",
			repeat: 1,
		},
	});

	const [radioIdChecked, setRadioIdChecked] = useState<string>(
		RADIO_ID_LIST.MINT
	);
	const [step, setStep] = useState<number>(1);
	const [inscribeList, setInscribeList] = useState<string[] | undefined>(
		undefined
	);
	const [brc20Data, setBrc20Data] = useState({
		tick: "",
		lim: 0,
		max: 0,
		amount: 0,
		repeat: 1,
	});

	const handlePrevStep = () => {
		setStep((prevStep) => prevStep - 1);
	};

	const handleNextStep = () => {
		setStep((prevStep) => prevStep + 1);
	};

	useEffect(() => {
		reset();
		setStep(1);
		setRadioIdChecked(RADIO_ID_LIST.MINT);
	}, [reset, resetField]);

	useEffect(() => {
		const inscribeListTemp = [];

		switch (radioIdChecked) {
			case RADIO_ID_LIST.MINT: {
				for (let i = 0; i < brc20Data.repeat; i++) {
					inscribeListTemp.push(
						`{"p":"brc-20","op":"mint","tick":"${brc20Data.tick}","amt":"${brc20Data.amount}"}`
					);
				}
				break;
			}
			case RADIO_ID_LIST.DEPLOY: {
				inscribeListTemp.push(
					`{"p":"brc-20","op":"deploy","tick":"${brc20Data.tick}","max":"${brc20Data.max}","lim":"${brc20Data.lim}"}`
				);
				break;
			}
			case RADIO_ID_LIST.TRANSFER: {
				inscribeListTemp.push(
					`{"p":"brc-20","op":"transfer","tick":"${brc20Data.tick}","amt":"${brc20Data.amount}"}`
				);
				break;
			}
		}

		setInscribeList([...inscribeListTemp]);
	}, [brc20Data, radioIdChecked]);

	const onSubmit = (data: any) => {
		setBrc20Data((prev) => ({ ...prev, ...data }));
		handleNextStep();
	};

	return (
		<div className={`inscribe-tab-container`}>
			<div className="linear-gradient-blur"></div>
			{step === 1 && (
				<>
					<div className="notice">
						<a
							href="https://domo-2.gitbook.io/brc-20-experiment/"
							target="_blank"
							rel="noreferrer"
							className="link-item"
						>
							Read more details about brc-20
						</a>
					</div>
					<ProofRadio
						radioClassName="spaces fs-16 mt-16 d-flex gap-36"
						labelClassName="spaces fs-16"
						radioName="radioTextTab"
						radioList={radioList}
						radioIdChecked={radioIdChecked}
						handleChangeChecked={(radioId: string) => {
							reset();
							setRadioIdChecked(radioId);
						}}
					/>
					<div className="spaces mt-16 w-100 text-center">
						<div className="d-flex align-items-center justify-content-end spaces gap-12">
							<div className="spaces fs-14 fw-500 text-color-white w-355 justify-content-end d-flex align-items-center label-title">
								Tick
								{RADIO_ID_LIST.DEPLOY === radioIdChecked && (
									<KTSVG
										svgClassName="spaces w-14 h-14 ml-6"
										path="/media/svg/icons/question-mark.svg"
									/>
								)}
							</div>
							<Controller
								control={control}
								render={function ({ field }) {
									return (
										<TextField
											inputClassName="spaces h-32 min-h-32"
											placeholder='4 characters like "abcd"...'
											maxLength={4}
											value={field.value}
											setValue={field.onChange}
										/>
									);
								}}
								name={"tick"}
							/>
							<div className="spaces w-300 space-box"></div>
						</div>
						{RADIO_ID_LIST.DEPLOY !== radioIdChecked && (
							<div className="d-flex align-items-center spaces gap-12 mt-16 mb-16">
								<div className="spaces fs-14 fw-500 text-color-white w-355 text-end label-title">
									Amount
								</div>
								<Controller
									name="amount"
									control={control}
									render={({ field }) => (
										<TextField
											inputClassName="spaces h-32 min-h-32"
											value={field.value}
											setValue={field.onChange}
											type="number"
											placeholder="1000"
										/>
									)}
								/>
								<div className="spaces w-300 space-box"></div>
							</div>
						)}
						{RADIO_ID_LIST.DEPLOY === radioIdChecked && (
							<>
								<div className="d-flex align-items-center spaces gap-12 mt-16 mb-16">
									<div className="spaces fs-14 fw-500 text-color-white w-355 text-end label-title">
										Total Supply
									</div>
									<Controller
										name="max"
										control={control}
										render={({ field }) => (
											<TextField
												inputClassName="spaces h-32 min-h-32"
												value={`${field.value}`}
												setValue={field.onChange}
												type="number"
												placeholder="21000000"
											/>
										)}
									/>
									<div className="spaces w-300 space-box"></div>
								</div>
								<div className="d-flex align-items-center spaces gap-12 mt-16 mb-16">
									<div className="spaces fs-14 fw-500 text-color-white w-355 text-end label-title">
										Limit Per Mint
									</div>
									<Controller
										name="lim"
										control={control}
										render={({ field }) => (
											<TextField
												inputClassName="spaces h-32 min-h-32"
												value={field.value}
												setValue={field.onChange}
												type="number"
												placeholder="1000"
											/>
										)}
									/>
									<div className="spaces w-300 space-box"></div>
								</div>
							</>
						)}
						<hr></hr>
						{RADIO_ID_LIST.MINT === radioIdChecked && (
							<>
								<ConfigProvider
									theme={{
										components: {
											Slider: {
												handleActiveColor: "#ebb94c",
												handleColor: "#745d2e",
												trackBg: "#745d2e",
												trackHoverBg: "#a07f38",
												railBg: "#191b26",
												railHoverBg: "#463e34",
											},
											InputNumber: {
												activeBg: "#272934",
												handleBg: "#272934",
												hoverBg: "#272934",
											},
										},
										token: {
											colorPrimaryBorderHover: "#a07f38",
											colorBgElevated: "#251f19",
											colorBgContainer: "#272934",
											colorBorder: "#484c58",
											colorText: "#ffffff",
											colorPrimary: "#ebb94c",
											colorTextDescription: "#ffffff",
										},
									}}
								>
									<div className="d-flex align-items-center spaces gap-12 mt-16">
										<div className="spaces fs-14 fw-500 text-color-white w-355 text-end label-title">
											Repeat Mint
										</div>
										<div className="w-100 d-flex spaces gap-12">
											<Controller
												name="repeat"
												control={control}
												render={({ field }) => (
													<>
														<InputNumber
															min={1}
															max={1000}
															value={field.value}
															onChange={field.onChange}
														/>
														<Slider
															className="w-100"
															min={1}
															max={1000}
															onChange={field.onChange}
															value={field.value}
														/>
													</>
												)}
											/>
										</div>
										<div className="spaces w-300 space-box"></div>
									</div>
								</ConfigProvider>
								{brc20Data.repeat > 200 && (
									<div className="spaces mt-8">
										The Booster will be unavailable when the Repeat Mint is
										greater than 200.
									</div>
								)}
							</>
						)}
						{RADIO_ID_LIST.DEPLOY === radioIdChecked && (
							<div className="text-color-yellow text-center">
								Due to the current competition for "Deploy", please be aware
								that there is a high risk of "Deploy" being snatched.
							</div>
						)}
						{RADIO_ID_LIST.TRANSFER === radioIdChecked && (
							<div className="text-color-yellow text-center">
								Before inscribing, please check your available balance on our
								brc-20 page, doing so can avoid invalid inscribing. There’s
								about 3 minutes delay in data updating.
							</div>
						)}
					</div>
					<ButtonCustom
						btnClassName="button button-yellow spaces mt-16 w-260"
						labelClassName="spaces fs-14"
						label="Next"
						handleClick={handleSubmit(onSubmit)}
					/>
				</>
			)}
			{step === 2 && (
				<DoubleCheckStep
					handleNextStep={handleNextStep}
					handlePrevStep={handlePrevStep}
					inscribeList={inscribeList}
					inscribeType={`brc-20`}
				/>
			)}
			{step === 3 && (
				<InscribingOrderStep
					inscriptionKind={Inscription_Kind.BRC20}
					inscribeList={inscribeList}
					handlePrevStep={() => {
						setStep(1);
					}}
				/>
			)}
		</div>
	);
};

export default Brc20Tab;
