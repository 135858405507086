import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ITickers } from "../../../../types/funbit-service";
import ButtonCustom from "../../components/button/ButtonCustom";
import Pagination from "../../components/pagination/Pagination";
import TableCustom from "../../components/table/TableCustom";
import { columns4 } from "../../home-page/constant";
import { useFetchTickers } from "../hooks";
import { useAuctionListParamStore } from "../../../store/auction-list-param.store";
import { useAuctionOrderParamStore } from "../../../store/auction-order-param.store";

const BUTTON_CODE = {
	ALL: "all",
	MINTING: "minting",
	COMPLETED: "completed",
};

const GROUP_BUTTONS = [
	{
		label: "All",
		code: BUTTON_CODE.ALL,
	},
	{
		label: "Minting",
		code: BUTTON_CODE.MINTING,
	},
	{
		label: "Completed",
		code: BUTTON_CODE.COMPLETED,
	},
];

const SIZE_PAGE = 15;

function MarketplaceHomePage() {
	const [page, setPage] = useState<number>(1);
	const [buttonActiveCode, setButtonActiveCode] = useState(BUTTON_CODE.ALL);
	const { list, total } = useFetchTickers({
		limit: SIZE_PAGE,
		start: page,
		status: buttonActiveCode,
	});
	const navigate = useNavigate();
	const { setAuctionListParam } = useAuctionListParamStore();
	const { setAuctionOrderParam } = useAuctionOrderParamStore();

	const handleRowData = useCallback(
		(rowData: ITickers) => {
			setAuctionListParam({
				tick: rowData.tick,
			});
			setAuctionOrderParam({
				tick: rowData.tick,
			});

			navigate(`/marketplace/${rowData.tick?.toLowerCase()}`);
		},
		[navigate, setAuctionListParam, setAuctionOrderParam]
	);

	const handleChangePage = useCallback((page: number) => {
		setPage(page);
	}, []);

	return (
		<>
			<div className="marketplace-home-page-container">
				<div className="d-flex justify-content-end">
					<div className="group-button">
						{GROUP_BUTTONS.map((buttonItem, indexBtn) => {
							return (
								<ButtonCustom
									key={indexBtn}
									label={buttonItem.label}
									btnClassName={`button ${
										buttonActiveCode === buttonItem.code && "button-grey-light"
									}`}
									handleClick={() => setButtonActiveCode(buttonItem.code)}
								/>
							);
						})}
					</div>
				</div>

				{buttonActiveCode === BUTTON_CODE.ALL && (
					<TableCustom
						columns={columns4}
						data={list}
						handleClickRow={handleRowData}
					/>
				)}
				{buttonActiveCode === BUTTON_CODE.MINTING && (
					<TableCustom
						columns={columns4}
						data={list}
						handleClickRow={handleRowData}
					/>
				)}
				{buttonActiveCode === BUTTON_CODE.COMPLETED && (
					<TableCustom
						columns={columns4}
						data={list}
						handleClickRow={handleRowData}
					/>
				)}
				<div className="text-center">
					<Pagination
						current={page + 1}
						pageSize={SIZE_PAGE}
						onChange={handleChangePage}
						total={total}
					/>
				</div>
			</div>
		</>
	);
}

export default MarketplaceHomePage;
