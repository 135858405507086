import { useEffect, useState } from "react";
import ButtonCustom from "../../components/button/ButtonCustom";
import TextareaCustom from "../../components/textarea/TextareaCustom";
import DoubleCheckStep from "./DoubleCheckStep";
import InscribingOrderStep from "./InscribingOrderStep";
import { Inscription_Kind } from "../../../utils";

type TProps = {
	data?: any;
	resetField?: number;
};

const NameTab = ({ data, resetField }: TProps) => {
	const [textareaValue, setTextareaValue] = useState<string>("");
	const [inscribeList, setInscribeList] = useState<string[] | undefined>([]);
	const [step, setStep] = useState<number>(1);

	const handleNextStep = () => {
		const inscribeListTemp = textareaValue
			? textareaValue
					.split("\n")
					.map((line) =>
						line.includes(data.tickName)
							? line.toLowerCase()
							: `${line.toLowerCase()}.${data.tickName}`
					)
			: undefined;
		console.log(inscribeListTemp);

		if (inscribeListTemp) {
			setInscribeList([...new Set(inscribeListTemp)]);
			textareaValue && setStep((prevStep) => prevStep + 1);
		}
	};

	const handlePrevStep = () => {
		setStep((prevStep) => prevStep - 1);
	};

	useEffect(() => {
		setTextareaValue("");
		setInscribeList([]);
		setStep(1);
	}, [resetField]);

	return (
		<div className="inscribe-tab-container">
			<div className="linear-gradient-blur"></div>
			{step === 1 && (
				<>
					<div className="spaces fs-14 w-100">
						<p className="text-color-white spaces fw-500">
							Add your{" "}
							<span className="text-color-yellow">.{data?.tickName}</span> names
							below, one per line.
						</p>
						<p>This is a related introduction to the selected domain.</p>
					</div>
					<TextareaCustom
						classTextarea="spaces mt-16"
						rows={8}
						placeholder={`aaa or aaa.${data?.tickName}`}
						value={textareaValue}
						handleChange={(value) => setTextareaValue(value)}
					/>
					<ButtonCustom
						btnClassName="button button-yellow spaces mt-16 w-100"
						labelClassName="spaces fs-14"
						label="Next"
						handleClick={handleNextStep}
					/>
				</>
			)}
			{step === 2 && (
				<DoubleCheckStep
					handleNextStep={handleNextStep}
					handlePrevStep={handlePrevStep}
					inscribeType={`${data?.tickName} names`}
					inscribeList={inscribeList}
					notice="We are not responsible for typos or punctuation errors."
				/>
			)}
			{step === 3 && (
				<InscribingOrderStep
					inscriptionKind={Inscription_Kind.NAMES}
					inscribeList={inscribeList}
					handlePrevStep={() => {
						setStep(1);
						setTextareaValue("");
					}}
				/>
			)}
		</div>
	);
};

export default NameTab;
