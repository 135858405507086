import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../../app/hooks/useWindowDimensions";
import { useAuth } from "../../../../app/modules/auth";
import { Login } from "../../../../app/modules/auth/components/Login";
import CashierTabModal from "../../../../app/modules/cashier/CashierTabModal";
import ButtonCustom from "../../../../app/modules/components/button/ButtonCustom";
import ChainNetworkModal from "../../../../app/modules/components/modal/ChainNetworkModal";
import ConnectWalletModal from "../../../../app/modules/components/modal/connect-wallet/ConnectWalletModal";
import { SCREEN_WIDTH } from "../../../../app/utils";
import { ILayout, useLayout } from "../../core";
import DisplayAccount from "../display-account";
import NavbarMobile from "./components/navbar/NabbarMobile";
import AccountUser from "./header-menus/AccountUser";
import "./header.scss";
import { useWallet } from "../../../../providers/wallet_provider";

const Header: FC = () => {
	const { config } = useLayout();
	useEffect(() => {
		updateDOM(config);
	}, [config]);
	const { currentUser } = useAuth();
	const [openFormAccount, setOpenFormAccount] = useState(false);
	const [openCashierModal, setOpenCashierModal] = useState(false);
	const [shouldOpenConnectWalletModal, setShouldOpenConnectWalletModal] =
		useState(false);
	const [loginMode, setLoginMode] = useState(true);
	const [openSearchBox, setOpenSearchBox] = useState(false);
	const { screenWidth } = useWindowDimensions();
	const { account } = useWallet();

	const [shouldOpenChainNetworkModal, setShouldOpenChainNetworkModal] =
		useState(false);
	const [chainNetworkActive, setChainNetworkActive] = useState<any>({
		imgPath: "/media/images/coins/BTC.png",
		name: "BITCOIN TESTNET",
	});

	const toggleMessageBox = () => {
		const staus = document.body.hasAttribute("data-kt-app-message-box");
		if (staus) {
			document.body.removeAttribute("data-kt-app-message-box");
		} else {
			document.body.setAttribute("data-kt-app-message-box", "true");
		}
	};

	const hanldeOpenSearchBox = () => {
		setOpenSearchBox(true);
	};

	return (
		<>
			<div
				className="
        menu
        menu-rounded
        menu-column
        menu-lg-row
        my-5
        my-lg-0
        align-items-center
		justify-content-between
        fw-semibold
        header-content
        px-2 px-lg-0"
				data-kt-menu-trigger="{default: 'click'}"
				data-kt-menu-attach="parent"
				data-kt-menu-placement="bottom-end"
			>
				<div className="d-flex align-items-center">
					<Link to={"/"} className="logo-box">
						<img
							className="logo-img"
							src="/media/images/FUNBIT 2 960X210.png"
							alt="logo"
						/>
					</Link>
					{currentUser && (
						<ButtonCustom
							isDropdown
							btnClassName="rewards-box button button-grey spaces ml-40 px-12"
							label="rewards"
							svgClassName="spaces w-26 h-25"
							labelClassName="spaces mr-10 ml-10"
							imgPath="/media/svg/general/chest.png"
						/>
					)}
				</div>
				<div>
					{currentUser && screenWidth > SCREEN_WIDTH.TABLET && (
						<div className="cash-box">
							<div className="cash-total-box spaces mr-12">
								<div className="cash-total">$0.00</div>
							</div>
							<ButtonCustom
								btnClassName="button button-green"
								handleClick={() => {
									setOpenCashierModal(true);
								}}
								label="Wallet"
								iconPath="/media/svg/icons/wallet.svg"
							/>
							<ButtonCustom
								btnClassName="button button-grey-light spaces ml-12 "
								labelClassName="spaces fs-14"
								label="buy crypto"
							/>
						</div>
					)}
				</div>
				<div className="account-conatiner">
					{currentUser ? (
						<>
							{screenWidth > SCREEN_WIDTH.TABLET && (
								<ButtonCustom
									btnClassName="button button-grey spaces px-14"
									svgClassName="spaces w-20 h-20"
									label="0.00 FUN"
									iconPath="/media/svg/general/iconCash.svg"
								/>
							)}
							{screenWidth <= SCREEN_WIDTH.TABLET && (
								<ButtonCustom
									btnClassName="button button-green spaces w-40"
									handleClick={() => {
										setOpenCashierModal(true);
									}}
									iconPath="/media/svg/icons/wallet.svg"
									svgClassName="spaces w-16 h-15 text-color-black"
								/>
							)}
							<AccountUser />
						</>
					) : (
						<div className="wallet-container">
							{/* <div
                className="text-login"
                onClick={() => {
                  setOpenFormAccount(true);
                  setLoginMode(true);
                }}
              >
                Login
              </div> */}
							{/* <ButtonCustom
                btnClassName="button button-green btn--register"
                handleClick={() => {
                  setOpenFormAccount(true);
                  setLoginMode(false);
                }}
                label="REGISTER"
              /> */}
							{account ? (
								<DisplayAccount account={account} />
							) : (
								<ButtonCustom
									btnClassName="button button-green btn--register"
									handleClick={() => setShouldOpenConnectWalletModal(true)}
									label="Connect Wallet"
								/>
							)}
							<ButtonCustom
								label={
									<>
										<div className="chain-network-active">
											<img
												src={chainNetworkActive.imgPath}
												className="logo"
												alt=""
												width={28}
											/>
											<span>{chainNetworkActive.name}</span>
										</div>
									</>
								}
								btnClassName="button button-grey-light"
								handleClick={() => {
									setShouldOpenChainNetworkModal(true);
								}}
							/>
						</div>
					)}
					{screenWidth > SCREEN_WIDTH.TABLET && (
						<>
							<ButtonCustom
								handleClick={hanldeOpenSearchBox}
								svgClassName="navbar-icon spaces w-20 h-20"
								btnClassName="button button-grey spaces px-12 p-0"
								iconPath="/media/svg/general/glasses.svg"
							/>
							<ButtonCustom
								handleClick={toggleMessageBox}
								svgClassName="navbar-icon spaces w-16 h-15"
								btnClassName="button button-grey spaces px-12 p-0"
								iconPath="/media/svg/general/message.svg"
							/>
						</>
					)}
				</div>
			</div>
			{openFormAccount && (
				<Login
					loginMode={loginMode}
					handleClose={() => {
						setOpenFormAccount(false);
					}}
				/>
			)}
			{openCashierModal && (
				<CashierTabModal
					handleClose={() => {
						setOpenCashierModal(false);
					}}
				/>
			)}
			{shouldOpenConnectWalletModal && (
				<ConnectWalletModal
					handleClose={() => setShouldOpenConnectWalletModal(false)}
				/>
			)}
			{shouldOpenChainNetworkModal && (
				<ChainNetworkModal
					handleClose={() => setShouldOpenChainNetworkModal(false)}
					handleChainNetwork={(chainNetwork) => {
						setChainNetworkActive(chainNetwork);
					}}
				/>
			)}
			{/* {openSearchBox && <SearchBoxModal handleCLose={() => { setOpenSearchBox(false) }} />} */}
			{screenWidth <= SCREEN_WIDTH.TABLET && (
				<NavbarMobile toggleMessageBox={() => toggleMessageBox()} />
			)}
		</>
	);
};

const updateDOM = (config: ILayout) => {
	if (config.app?.header?.default?.fixed?.desktop) {
		document.body.setAttribute("data-kt-app-header-fixed", "true");
	}

	if (config.app?.header?.default?.fixed?.mobile) {
		document.body.setAttribute("data-kt-app-header-fixed-mobile", "true");
	}

	if (config.app?.header?.default?.stacked) {
		document.body.setAttribute("data-kt-app-header-stacked", "true");
	}

	const appHeaderDefaultStickyEnabled =
		config.app?.header?.default?.sticky?.enabled;
	let appHeaderDefaultStickyAttributes: { [attrName: string]: string } = {};
	if (appHeaderDefaultStickyEnabled) {
		appHeaderDefaultStickyAttributes = config.app?.header?.default?.sticky
			?.attributes as {
			[attrName: string]: string;
		};
	}

	const appHeaderDefaultMinimizeEnabled =
		config.app?.header?.default?.minimize?.enabled;
	let appHeaderDefaultMinimizeAttributes: { [attrName: string]: string } = {};
	if (appHeaderDefaultMinimizeEnabled) {
		appHeaderDefaultMinimizeAttributes = config.app?.header?.default?.minimize
			?.attributes as {
			[attrName: string]: string;
		};
	}

	setTimeout(() => {
		const headerElement = document.getElementById("kt_app_header");
		// header
		if (headerElement) {
			const headerAttributes = headerElement
				.getAttributeNames()
				.filter((t) => t.indexOf("data-") > -1);
			headerAttributes.forEach((attr) => headerElement.removeAttribute(attr));

			if (appHeaderDefaultStickyEnabled) {
				for (const key in appHeaderDefaultStickyAttributes) {
					if (appHeaderDefaultStickyAttributes.hasOwnProperty(key)) {
						headerElement.setAttribute(
							key,
							appHeaderDefaultStickyAttributes[key]
						);
					}
				}
			}

			if (appHeaderDefaultMinimizeEnabled) {
				for (const key in appHeaderDefaultMinimizeAttributes) {
					if (appHeaderDefaultMinimizeAttributes.hasOwnProperty(key)) {
						headerElement.setAttribute(
							key,
							appHeaderDefaultMinimizeAttributes[key]
						);
					}
				}
			}
		}
	}, 0);
};

export { Header };
