import { KTSVG } from "../../../../_metronic/helpers"

const NoBetsYet = () => {
    return (
        <div className="text-center spaces pt-24 pb-48">
            <KTSVG path={'/media/svg/navigation/rollbit-coin/Trade RLB.svg'} className='svg-icon-2 text-color-gray' width={12} height={20}/>
            <div className="spaces mt-16 fw-800">No Bets Yet</div>
        </div>
    )
}

export default NoBetsYet