import { useRoutes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import {
  ACCOUNT_ROUTES,
  BATTLES_ROUTES,
  CASINO_ROUTES,
  CATEGORY_ROUTES,
  CHALLENGES_ROUTES,
  CLANS_ROUTES,
  ERROR_ROUTES,
  FOOTER_ROUTES,
  FUN_ROUTES,
  INSCRIBE_ROUTES,
  MARKET_PLACE_ROUTES,
  NFT_ROUTES,
  ORTHER_ROUTES,
  PROVIDERS_ROUTES,
  SPONSORSHIPS_ROUTES,
  SPORTS_ROUTES,
  INSCRIPTION_ROUTES,
  TRADING_ROUTES,
  DETAIL_TICKERS_ROUTE,
} from "./constant";

const PrivateRoutes = () => {
  const privateRoutes = useRoutes([
    {
      element: <MasterLayout />,
      children: [
        ...CASINO_ROUTES,
        ...INSCRIBE_ROUTES,
        ...INSCRIPTION_ROUTES,
        ...BATTLES_ROUTES,
        ...PROVIDERS_ROUTES,
        ...CATEGORY_ROUTES,
        ...CHALLENGES_ROUTES,
        ...CLANS_ROUTES,
        ...TRADING_ROUTES,
        ...SPORTS_ROUTES,
        ...NFT_ROUTES,
        ...FUN_ROUTES,
        ...ACCOUNT_ROUTES,
        ...FOOTER_ROUTES,
        ...ORTHER_ROUTES,
        ...SPONSORSHIPS_ROUTES,
        ...MARKET_PLACE_ROUTES,
        ...DETAIL_TICKERS_ROUTE,
        ...ERROR_ROUTES,
      ],
    },
  ]);

  return <>{privateRoutes}</>;
};

export { PrivateRoutes };
