import { Modal } from "react-bootstrap"
import ButtonCustom from "../../../../components/button/ButtonCustom"

type TProps = {
    handleClose: () => void
}

const SignTranSacTionModal = ({ handleClose }: TProps) => {
    return (
        <>
            <Modal
                contentClassName={"modal-wrapper sign-transaction-modal"}
                show={true}
                size="sm"
                onHide={handleClose}
            >
                <div className="modal-content">
                    <div className="text-center text-color-white spaces fs-14 fw-bold mb-16">Sign Transaction</div>
                    <div className="price-box">+0.00089454 втс</div>
                    <div className="text-uppercase spaces fw-500 mt-16">Inputs</div>
                    <div className="info-list">
                        <div className="d-flex align-items-center justify-content-between text-color-gray">
                            <div>bc1q4...4ajhd</div>
                            <div>0.00000000 BTC</div>
                        </div>
                        <hr></hr>
                        <div className="d-flex align-items-center justify-content-between text-color-gray">
                            <div>bc1q4...4ajhd</div>
                            <div>0.00000000 BTC</div>
                        </div>
                        <hr/>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>bc1qs...gx4zj</div>
                            <div>0.00000546 <span className="text-color-gray">BTC</span></div>
                        </div>
                    </div>
                    <div className="text-uppercase spaces fw-500">Outputs</div>
                    <div className="info-list">
                        <div className="d-flex align-items-center justify-content-between text-color-gray">
                            <div>11111...oLVT2</div>
                            <div>0.00000000 BTC</div>
                        </div>
                        <hr></hr>
                        <div className="d-flex align-items-center justify-content-between text-color-gray">
                            <div>11111...oLVT2</div>
                            <div>0.00000000 BTC</div>
                        </div>
                        <hr/>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>11111...oLVT2</div>
                            <div>0.00000000 <span className="text-color-gray">BTC</span></div>
                        </div>
                    </div>
                </div>
                <div className="modal-btns spaces gap-12">
                    <ButtonCustom
                        label="Reject"
                        btnClassName="button button-grey-light"
                        handleClick={handleClose}
                    />
                    <ButtonCustom
                        label="Sign"
                        btnClassName={`button button-yellow`}
                    />
                </div>
            </Modal>
        </>
    )
}

export default SignTranSacTionModal