import { useEffect, useRef, useState } from "react";
import { CustomTabsV2 } from "../../../_metronic/layout/components/tabs/CustomTabsV2";
import OrdersTab from "./components/OrdersContent";
import "./inscribe.scss";
import { INSCRIBE_TAB_KEYS } from "./constants";
import NamesPopover from "./components/NamesPopover";
import NameTab from "./components/NameTab";
import Brc20Tab from "./components/Brc20Tab";
import FilesTab from "./components/FilesTab";
import TextTab from "./components/TextTab";

const InscribePage = () => {
	const [inscribeTabActive, setInsCribeTabActive] = useState<string | null>(
		null
	);
	const [resetField, setResetField] = useState(0);
	const [namesTabDate, setNamesTabData] = useState();

	const tabs = [
		{
			nav: {
				label: "brc-20",
				eventKey: INSCRIBE_TAB_KEYS.BRC20,
			},
			content: {
				component: <Brc20Tab resetField={resetField} />,
			},
		},
		{
			nav: {
				label: "Files",
				eventKey: INSCRIBE_TAB_KEYS.FILES,
			},
			content: {
				component: <FilesTab resetField={resetField} />,
			},
		},
		{
			nav: {
				eventKey: INSCRIBE_TAB_KEYS.NAMES,
				component: (
					<NamesPopover
						handleTabData={(data: any) => setNamesTabData(data)}
						isTabActive={inscribeTabActive === INSCRIBE_TAB_KEYS.NAMES}
					/>
				),
			},
			content: {
				component: <NameTab data={namesTabDate} resetField={resetField} />,
			},
		},
		{
			nav: {
				label: "Text",
				eventKey: INSCRIBE_TAB_KEYS.TEXT,
			},
			content: {
				component: <TextTab resetField={resetField} />,
			},
		},
	];

	return (
		<div className="page-container inscribe-page-container">
			<div className="linear-gradient-text text-center shadow-text spaces fs-28 fw-500">
				Funbit Inscribe
			</div>
			<div className="spaces fs-14 mt-24 text-center">
				Funbit only charges the service fee for
				<b className="text-color-yellow"> the first 25 inscriptions</b>
				<br />
				in a single inscribing batch order, up to a maximum of 1000
				inscriptions.
			</div>
			<div className="text-center spaces mt-32">
				<a
					className="text-center spaces fs-14 text-color-yellow text-underline"
					href="https://brimless.medium.com/Funbit-inscribe-is-now-live-1c742702841e"
					target="_blank"
					rel="noreferrer"
				>
					How to inscribe?
				</a>
			</div>
			<div className="spaces mt-16">
				<CustomTabsV2
					listTab={tabs}
					tabDefault={INSCRIBE_TAB_KEYS.BRC20}
					tabActiveKey={(tabKey: string) => {
						setInsCribeTabActive(tabKey);
						setResetField((prev) => prev + 1);
					}}
				/>
			</div>
			<OrdersTab />
		</div>
	);
};

export default InscribePage;
