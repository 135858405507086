import { ConfigProvider, InputNumber, Slider } from "antd";
import clsx from "clsx";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { KTSVG } from "../../../../../../_metronic/helpers";
import useGetBitcoinPrices from "../../../../../hooks/useGetBitcoinPrices";
import FunbitService from "../../../../../services/funbitServices";
import { satsToDollars, toNumberFormat } from "../../../../../utils";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import { refListedControl } from "./ListedTab";
import { useMutation } from "react-query";
import { useWallet } from "../../../../../../providers/wallet_provider";
import { toast } from "react-toastify";
import { useBalance } from "../../../../../../providers/wallet_provider/hooks/useBalance";

interface Tprops {
	handleClose: () => void;
	auctionId?: number;
	bidPrice?: number;
	pubkey?: string;
	unit_price: number;
	init_price: number;
	quantity: number;
	tick: string;
	feeRate?: number;
	nftValue?: number;
	serverFee?: number;
	serverFeeRate?: number;
	serverReal?: number;
	txSize?: number;
}

const BTC_TO_SATS = 100000000;

const ConfirmModal = ({
	handleClose,
	auctionId,
	bidPrice,
	pubkey,
	...props
}: Tprops) => {
	const [isLoading, setIsLoading] = useState(false);
	const { unisat, account } = useWallet();

	const { mutateAsync } = useMutation(FunbitService.createBuyItem);
	const { mutateAsync: mutateAsyncConfirmBuyItem } = useMutation(
		FunbitService.confirmBuyItem
	);

	const { data: balance } = useBalance();
	const [networkFeeCustomValue, setNetworkFeeCustomValue] = useState<number>(
		props?.feeRate || 0
	);
	const [shouldOpenCustomNetworkFeeBox, setShouldOpenCustomNetworkFeeBox] =
		useState(false);
	const btcPrice = useGetBitcoinPrices();
	const initPriceDolar = btcPrice * (props.init_price / BTC_TO_SATS);

	const handleCustom = () => {
		setShouldOpenCustomNetworkFeeBox(true);
	};

	const handleBuy = async () => {
		if (!balance?.confirmed || balance?.confirmed < bidPrice!) {
			return toast.error("Available balance not enough");
		}
		try {
			setIsLoading(true);
			const data = await mutateAsync({
				auctionId: auctionId!,
				bidPrice: bidPrice || 0,
				pubkey: pubkey || "",
				feeRate: networkFeeCustomValue,
				address: account,
			});

			if (data) {
				const { psbt, bidSignIndexes } = data;
				const signedPspt = await unisat?.signPsbt(psbt, {
					autoFinalized: false,
					toSignInputs: bidSignIndexes?.map((item: number) => ({
						index: item,
						address: account,
					})),
				});

				await mutateAsyncConfirmBuyItem({
					auctionId: auctionId!,
					signedPsbt: signedPspt!,
					address: account,
				});
			}
			handleClose();
			refListedControl.refetch();
		} catch (error: any) {
			console.log(error);
			let errorMessage = "Something went wrong";
			if (error?.response?.status === 403) {
				errorMessage = error?.response?.data?.message;
			}
			if (error?.code === 4001) {
				errorMessage = error?.message;
			}
			toast.error(errorMessage);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Modal
				contentClassName={clsx("modal-wrapper buy-confirm-modal")}
				show={true}
				size="lg"
				onHide={handleClose}
			>
				<div className="btn-exit">
					<div className="exit-icon" onClick={handleClose}>
						<KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
					</div>
				</div>
				<div className="body-content">
					<div className="text-center">
						<div className="spaces fs-18 fw-500 text-color-white">
							Confirmation
						</div>
						<div className="spaces fs-16 fw-500 my-16 text-color-gray">
							Please confirm the transaction below:
						</div>
						<div className="nft-item-components">
							<div className="content-container">
								<div
									className="spaces fs-12 text-color-gray text-start"
									style={{
										fontFamily: "Proto Mono, Helvetica, Arial, sans-serif",
									}}
								>
									{props.tick?.toLowerCase()}
								</div>
								<div className="spaces fs-18 fw-bold text-color-white">
									{props.quantity}
								</div>
							</div>
							{/* <div className="info">
                <div className="num">#62360056</div>
              </div> */}
							<div className="price-line">
								<div className="price">{props.unit_price}</div>
								<div className="unit">
									sats/
									<span
										style={{
											fontFamily: "Proto Mono, Helvetica, Arial, sans-serif",
										}}
									>
										{props.tick?.toLowerCase()}
									</span>{" "}
								</div>
							</div>
						</div>
						<div className="choose-text-transform">
							<div className="flex-column-center">
								<div className="text-color-white">LowerCase</div>
								<span
									className="spaces fs-22 text-color-yellow"
									style={{
										fontFamily: "Proto Mono, Helvetica, Arial, sans-serif",
									}}
								>
									{props.tick?.toLowerCase()}
								</span>
							</div>
							<div className="border-line"></div>
							<div className="flex-column-center">
								<div className="text-color-white">UpperCase</div>
								<span
									className="spaces fs-22 text-color-yellow"
									style={{
										fontFamily: "Proto Mono, Helvetica, Arial, sans-serif",
									}}
								>
									{props.tick?.toUpperCase()}
								</span>
							</div>
						</div>
					</div>
					<div className="fee-line">
						<div>Total Value:</div>
						<div className="text-end">{toNumberFormat(props.init_price)}</div>
						<div>
							<span className="spaces mr-8 text-color-gray">sats</span>
							<span>≈${initPriceDolar?.toFixed(2)}</span>
						</div>
					</div>
					<hr></hr>
					<div className="fee-line">
						<div>Service Fee:</div>
						<div className="text-end text-decoration-line-through text-color-gray">
							{props?.serverFee || 0}
						</div>
						<div>
							<span className="spaces mr-8 text-color-gray">sats</span>
							<span className="text-decoration-line-through text-color-gray">
								≈${satsToDollars(props?.serverFee || 0, btcPrice)}
							</span>
						</div>
					</div>
					<div className="fee-line">
						<div>Service Fee Final:</div>
						<div className="text-end">{props?.serverReal || 0}</div>
						<div>
							<span className="spaces mr-8 text-color-gray">sats</span>
							<span>≈${satsToDollars(props?.serverReal || 0, btcPrice)}</span>
						</div>
					</div>
					<hr></hr>
					<div className="fee-line">
						<div>Transaction Fee Rate:</div>
						<div className="text-end text-color-yellow">
							{networkFeeCustomValue}
						</div>
						<div className="d-flex align-items-center">
							<span className="spaces mr-8 text-color-gray">sats/vB</span>
							<ButtonCustom
								label="Customize"
								labelClassName="text-capitalize text-color-yellow fw-normal"
								btnClassName="button button-grey-light spaces h-32 min-h-32 px-8"
								handleClick={handleCustom}
							/>
						</div>
					</div>
					{shouldOpenCustomNetworkFeeBox && (
						<div className="spaces mr-12">
							<ConfigProvider
								theme={{
									components: {
										Slider: {
											handleActiveColor: "#ebb94c",
											handleColor: "#745d2e",
											trackBg: "#745d2e",
											trackHoverBg: "#a07f38",
											railBg: "#191b26",
											railHoverBg: "#463e34",
										},
										InputNumber: {
											activeBg: "#272934",
											handleBg: "#272934",
											hoverBg: "#272934",
										},
									},
									token: {
										colorPrimaryBorderHover: "#a07f38",
										colorBgElevated: "#251f19",
										colorBgContainer: "#272934",
										colorBorder: "#484c58",
										colorText: "#ffffff",
										colorPrimary: "#ebb94c",
										colorTextDescription: "#ffffff",
									},
								}}
							>
								<div className="d-flex align-items-center spaces gap-12 mt-16">
									<div className="w-100 d-flex spaces gap-12">
										<Slider
											className="w-100"
											min={1}
											max={500}
											onChange={setNetworkFeeCustomValue}
											value={networkFeeCustomValue}
										/>
										<InputNumber
											min={1}
											max={500}
											value={networkFeeCustomValue}
											onChange={(value) => {
												value && setNetworkFeeCustomValue(value);
											}}
										/>
									</div>
								</div>
							</ConfigProvider>
						</div>
					)}
					<div className="fee-line">
						<div>
							{props?.txSize || 560}
							<span className="text-color-gray"> vB </span>*{" "}
							<span className="text-color-yellow">
								{" "}
								{networkFeeCustomValue}{" "}
							</span>
							<span className="text-color-gray"> sats/vB</span>
						</div>
						<div className="text-end">
							{(networkFeeCustomValue || 0) * (props?.txSize || 560)}
						</div>
						<div>
							<span className="spaces mr-8 text-color-gray">sats</span>
							<span>
								≈$
								{(
									btcPrice *
									((networkFeeCustomValue * 560) / BTC_TO_SATS)
								)?.toFixed(2)}
							</span>
						</div>
					</div>
					<hr></hr>
					<div className="fee-line">
						<div>Total:</div>
						<div className="text-end text-color-yellow spaces fs-16 fw-bold">
							≈{toNumberFormat(props.init_price + networkFeeCustomValue * 560)}
						</div>
						<div>
							<span className="spaces mr-8 text-color-gray">sats</span>
							<span>
								≈$
								{(
									btcPrice *
									((props.init_price + networkFeeCustomValue * 560) /
										BTC_TO_SATS)
								)?.toFixed(2)}
							</span>
						</div>
					</div>
					<div className="fee-line">
						<div></div>
						<div className="text-end text-color-gray">
							{(props.init_price + networkFeeCustomValue * 560) / BTC_TO_SATS}
						</div>
						<div>
							<span className="spaces mr-8 text-color-gray">BTC</span>
						</div>
					</div>
					<div className="fee-line">
						<div>Available Balance:</div>
						<div className="text-end">
							{balance?.confirmed || 0 / BTC_TO_SATS}
						</div>
						<div>
							<span className="spaces mr-8 text-color-gray">BTC</span>
						</div>
					</div>
				</div>
				<div className="modal-btns spaces mt-36 gap-12">
					<ButtonCustom
						label="Cancel"
						labelClassName="text-capitalize text-color-gray spaces fw-600"
						btnClassName="button button-grey-light spaces min-w-140 w-140"
						handleClick={handleClose}
					/>
					<ButtonCustom
						label={
							isLoading ? (
								<>
									<Spinner animation={"border"} size="sm" /> Loading...
								</>
							) : (
								"Confirm"
							)
						}
						labelClassName="text-capitalize spaces fw-600"
						btnClassName="button button-yellow spaces min-w-140 w-140"
						handleClick={handleBuy}
					/>
				</div>
			</Modal>
		</>
	);
};

export default ConfirmModal;
