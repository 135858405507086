import { useQuery } from "react-query";
import Pagination from "../../../../components/pagination/Pagination";
import SliderNormal from "../../../../components/slider/SliderNormal";
import TradeCard from "./TradeCard";
import { AuctionListResponse } from "../../../../../../types/marketplace-service";
import { QUERY_KEY } from "../../../../../utils";
import { useAuctionListParamStore } from "../../../../../store/auction-list-param.store";
import {
	createRef,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import FunbitService from "../../../../../services/funbitServices";
import { useLocation } from "react-router-dom";
import { useWallet } from "../../../../../../providers/wallet_provider";
import TradeCardCollection from "./TradeCardCollection";

export const refListed = createRef<any>();

export const refListedControl = {
	refetch: () => refListed.current?.refetch(),
};

const fakeData = [
	{
		id: 24,
		unit_price: 100,
		init_price: 100000,
		market_type: "fixed",
		inscription_id:
			"bdd630b462be88f6e43576699816aa6f0a6dc2c41910f8eb82c04b94efea8e83i0",
		is_confirmed: true,
		seller: "tb1q2mxxz3flqhuuq6qahf5pykqmraalmgkyhxl8y9",
		inscription: {
			kind: "brc_20",
			inscription_id:
				"bdd630b462be88f6e43576699816aa6f0a6dc2c41910f8eb82c04b94efea8e83i0",
			amount: "1000",
			inscription_number: 2709518,
			brc20: {
				tick: "like",
			},
		},
	},
	{
		id: 18,
		unit_price: 100,
		init_price: 100000,
		market_type: "fixed",
		inscription_id:
			"b13d3f96f254ea630fb0c294d55b1079c009bb952fac24c8b1490339cacd2f41i0",
		is_confirmed: true,
		seller: "tb1q2mxxz3flqhuuq6qahf5pykqmraalmgkyhxl8y9",
		inscription: {
			kind: "brc_20",
			inscription_id:
				"b13d3f96f254ea630fb0c294d55b1079c009bb952fac24c8b1490339cacd2f41i0",
			amount: "1000",
			inscription_number: 2813772,
			brc20: {
				tick: "like",
			},
		},
	},
	{
		id: 25,
		unit_price: 100,
		init_price: 100000,
		market_type: "fixed",
		inscription_id:
			"f1f0d9a71fce7ac55c0e37e9b1e3ca25bbcd5d8888af211a73b97faa43045afei0",
		is_confirmed: true,
		seller: "tb1q2mxxz3flqhuuq6qahf5pykqmraalmgkyhxl8y9",
		inscription: {
			kind: "brc_20",
			inscription_id:
				"f1f0d9a71fce7ac55c0e37e9b1e3ca25bbcd5d8888af211a73b97faa43045afei0",
			amount: "1000",
			inscription_number: 2709517,
			brc20: {
				tick: "like",
			},
		},
	},
	{
		id: 56,
		unit_price: 100,
		init_price: 100000,
		market_type: "fixed",
		inscription_id:
			"ecc3c12c56cd5e10c4cc5250b06e09fc58b0eed78da870693183081751dc40b2i0",
		is_confirmed: true,
		seller: "tb1p98675vhp6nzdvrdy30hz2mjw7xa62y8x3ujfg3sffut25ga9xksqp4lgxx",
		inscription: {
			kind: "brc_20",
			inscription_id:
				"ecc3c12c56cd5e10c4cc5250b06e09fc58b0eed78da870693183081751dc40b2i0",
			amount: "1000",
			inscription_number: 3095278,
			brc20: {
				tick: "like",
			},
		},
	},
	{
		id: 55,
		unit_price: 10,
		init_price: 10000,
		market_type: "fixed",
		inscription_id:
			"3df026b5dd0c806f1d18e73b4b09ea3c65ca5711e2efb348afd0b90282f031f9i0",
		is_confirmed: true,
		seller: "tb1qeyc204q5ka4crn8f6ym2te3pe3w4h57wj30yk7",
		inscription: {
			kind: "brc_20",
			inscription_id:
				"3df026b5dd0c806f1d18e73b4b09ea3c65ca5711e2efb348afd0b90282f031f9i0",
			amount: "1000",
			inscription_number: 2849597,
			brc20: {
				tick: "like",
			},
		},
	},
	{
		id: 57,
		unit_price: 10,
		init_price: 99990,
		market_type: "fixed",
		inscription_id:
			"dacd12e9751da3748daace197cfc5e9831cee7cd3c65151128550cde82bce371i0",
		is_confirmed: true,
		seller: "tb1q2mxxz3flqhuuq6qahf5pykqmraalmgkyhxl8y9",
		inscription: {
			kind: "brc_20",
			inscription_id:
				"dacd12e9751da3748daace197cfc5e9831cee7cd3c65151128550cde82bce371i0",
			amount: "9999",
			inscription_number: 3095692,
			brc20: {
				tick: "like",
			},
		},
	},
];

const ListedCollectionTab = forwardRef((_, ref) => {
	const { auctionListParam, setAuctionListParam } = useAuctionListParamStore();
	const location = useLocation();

	const { unisat } = useWallet();

	const [pubKey, setPubKey] = useState<string>("");
	// const { data: auctionListData, refetch } = useQuery({
	// 	queryKey: [QUERY_KEY.GET_AUCTION_LIST, auctionListParam],
	// 	queryFn: () => FunbitService.marketAuctionListRequest(auctionListParam),
	// });

	useEffect(() => {
		if (!location.pathname.includes(auctionListParam.tick)) {
			setAuctionListParam({
				tick: location.pathname.substring(
					location.pathname.length - auctionListParam.tick.length
				),
			});
		}
	}, [auctionListParam.tick, location.pathname, setAuctionListParam]);

	const handleChangePage = (page: number, pageSize: number) => {
		setAuctionListParam({
			start: page,
			limit: pageSize,
		});
	};

	useImperativeHandle(ref, () => {
		return { refetch: () => {} };
	});

	useEffect(() => {
		(async () => {
			const key = await unisat?.getPublicKey();
			setPubKey(key || "");
		})();
	}, [unisat]);

	return (
		<>
			<SliderNormal
				classSilder="w-100 position-relative spaces mt-13 slider-nowrap grid-columns-150"
				sliderList={fakeData}
				sliderItem={(dataItem: AuctionListResponse) => (
					<TradeCardCollection data={dataItem} pubKey={pubKey} />
				)}
				nowrap={false}
				itemQuantity={{ laptop: 5, tablet: 3, mobile: 2 }}
			/>
			{fakeData?.length! > 0 && (
				<div className="spaces mt-16 text-center">
					<Pagination
						current={1}
						pageSize={20}
						// onChange={handleChangePage}
						total={fakeData?.length || 0}
					/>
				</div>
			)}
		</>
	);
});

export default ListedCollectionTab;
