import { Outlet } from "react-router-dom";
import SubNavigation from "../components/sub-navigation/SubNavigation";
import { SUB_NAVIGATIONS_FUNBIT } from "./contants";
import ButtonCustom from "../components/button/ButtonCustom";
import { KTSVG } from "../../../_metronic/helpers";
import "./rlb.scss";

const RollbitCoin = () => {
	return (
		<div className="page-container spaces max-w-960">
			<div className="spaces mb-24 gap-16 d-flex align-items-center justify-content-between flex-wrap">
				<div className="d-flex align-items-center">
					<div>
						<img
							width={32}
							height={32}
							src={"/media/images/funbit-coin/funbit-icon.png"}
							alt=""
						/>
					</div>
					<div>
						<div className="spaces fs-16 fw-800 text-uppercase">
							Funbit Coin{" "}
						</div>
						<div className="d-flex align-items-center flex-wrap spaces fs-12 gap-4">
							<div className="d-flex align-items-center">
								<div className="">$0.222388</div>
								<KTSVG
									path="/media/svg/icons/reduce.svg"
									className="spaces rotate-180 text-color-green"
									width={9}
									height={9}
								/>
								<div className="text-color-green">1000%</div>
							</div>
							<div className="d-flex align-items-center spaces gap-4">
								<div>
									<span className="text-color-gray">24h Volume: </span>
									<span>$2.53M</span>
								</div>
								<div>
									<span className="text-color-gray">TVL: </span>
									<span>$11.9M</span>
								</div>
								<div>
									<span className="text-color-gray">Holders: </span>
									<span>22,158</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<ButtonCustom
						label="Whitepaper"
						labelClassName="text-capitalize text-color-gray spaces fs-14 fw-500"
						btnClassName="button button-grey spaces px-16 mr-16"
						iconPath="/media/svg/icons/white-paper.svg"
						svgClassName="spaces w-15 h-15 text-color-gray"
					/>
					<ButtonCustom
						label="Contract"
						labelClassName="text-capitalize text-color-gray spaces fs-14 fw-500"
						btnClassName="button button-grey spaces px-16"
						iconPath="/media/svg/icons/contract.svg"
						svgClassName="spaces w-15 h-15 text-color-gray"
					/>
				</div>
			</div>
			<SubNavigation navigations={SUB_NAVIGATIONS_FUNBIT} />
			<Outlet />
		</div>
	);
};

export default RollbitCoin;
