export enum AuctionListSort {
  UNIT_PRICE_DESC = "unit_price-desc",
  UNIT_PRICE_ASC = "unit_price-asc",
  CREATED_AT_DESC = "created_at-desc",
  CREATED_AT_ASC = "created_at-asc",
}

export enum NftType {
  BRC_20 = "brc_20",
  NAMES = "names",
  COLLECTION = "collection",
}

export type AuctionListParam = {
  tick: string;
  limit: number;
  start: number;
  nftType: NftType;
  sort: AuctionListSort;
  address?: string;
};

export type AuctionOrderParam = {
  tick: string;
  limit: number;
  start: number;
  nftType: NftType;
  address?: string;
};


interface Inscription {
  kind: string;
  inscription_id: string;
  amount: string;
  inscription_number: null;
  brc20: {
    tick: string;
  };
}

export interface AuctionListResponse {
  id: number;
  unit_price: number;
  init_price: number;
  market_type: string;
  inscription_id: string;
  is_confirmed: boolean;
  seller: string;
  inscription: Inscription;
}





interface Listing {
  init_price: number;
  unit_price: number;
  seller: string;
  buyer: string | null;
}

interface InscriptionListing {
  inscription_number: string | null;
  amount: string;
  inscription_id: string;
  listing: Listing;
}

export interface AuctionOrderResponse {
  id: number;
  inscription_id: string;
  date: string;
  price: string;
  seller: string;
  buyer: string | null;
  kind: string;
  inscription: InscriptionListing;
}

export interface TickersInfo {
  tick: string;
  holders_count: number;
  history_count: number;
  inscription_number: number;
  inscription_id: string;
  max: string;
  limit: string;
  minted: string;
  total_minted: string;
  confirmed_minted: string;
  confirmed_minted_1h: string;
  confirmed_minted_24h: string;
  mint_times: number;
  decimal: number;
  creator: string;
  txid: string;
  deploy_height: number;
  deploy_blocktime: number;
  complete_height: number;
  complete_blocktime: number;
  inscription_number_start: number;
  inscription_number_end: number;
}

export interface InscriptionInfo {
  txid: string;
  address: string;
  output_value: number;
  content: string;
  content_length: number;
  content_type: string;
  time_stamp: number;
  genesis_transaction: string;
  inscription_id: string;
  inscription_number: string;
  view: string;
  height: string;
  brc20: {
    amt: string;
    decimal: string;
    lim: string;
    op: string;
    tick: string;
  } | null;
  init_price: number;
  unit_price: number;
  id: number;
  from: string;
  to?: string;
}

export interface CreateListItemBody {
  inscriptionId: string;
  pubkey: string;
  inscriptionType: string;
  inscriptionNumber: number;
  initPrice: number;
  unitPrice: number;
  marketType: string;
  address: string;
  eventType: string;
  isConfirmed: boolean;
}

export interface CreateListItemResponse {
  psbt: string;
  signIndexes: number[];
  id: string;
}

export interface CreateBuyItemPrepareBody {
  auctionId: number;
  address: string;
  pubkey: string;
  bidPrice: number;
}

export interface CreateBuyItemBody {
  auctionId: number;
  address: string;
  pubkey: string;
  bidPrice: number;
  feeRate: number;
}

export interface CreateBuyItemPrepareResponse {
  serverFeeRate: number;
  feeRate: number;
  txSize: number;
  nftValue: number;
  serverFee: number;
  serverReal: number;
}


export interface CreateBuyItemResponse {
  bidSignIndexes: number[];
  psbt: string;
  networkFee: number;
  feeRate: number;
  nftValue: number;
}

export interface ConfirmBuyItemBody {
  signedPsbt: string;
  auctionId: number;
  address: string;
}

export interface ListAuctionOrders {
  limit: number;
  start: number;
  tick: string;
  nftType: "brc_20" | "names" | "collection";
  address?: string;
}

export interface ListAuctionOrdersResponse {
  list: Transaction[];
  total: number;
}

interface BRC20 {
  tick: string;
}

interface InscriptionA {
  kind: string;
  inscription_id: string;
  amount: string;
  inscription_number: number | null;
  brc20: BRC20;
}

interface Transaction {
  id: number;
  unit_price: number;
  init_price: number;
  market_type: string;
  inscription_id: string;
  is_confirmed: boolean;
  seller: string;
  inscription: InscriptionA;
}