import axios, { AxiosResponse } from "axios";
import {
  CreateOrderPayload,
  FetchTickersParams,
  FetchTickersResult,
} from "../../types/funbit-service";
import {
  AuctionListParam,
  AuctionListResponse,
  AuctionOrderParam,
  AuctionOrderResponse,
  ConfirmBuyItemBody,
  CreateBuyItemBody,
  CreateBuyItemPrepareBody,
  CreateBuyItemPrepareResponse,
  CreateBuyItemResponse,
  CreateListItemBody,
  CreateListItemResponse,
  InscriptionInfo,
  ListAuctionOrders,
  ListAuctionOrdersResponse,
  TickersInfo,
} from "../../types/marketplace-service";
import { objectToQueryStringByComma } from "../utils";
import { PaginationResponse } from "../../types/common";

const isDev = false;

const baseURL = isDev ? "http://localhost:8080" : "https://api.funbit.network"

const instance = axios.create({
  baseURL
});

class FunbitService {
  static createOrder (data: CreateOrderPayload) {
    return instance.post("inscribe/order/create", data);
  }

  static getListInscribeOrderByAddress (address: string) {
    return instance.get(`inscribe/order/list/${address}`);
  }

  static getInscribeOrderById (orderId: string) {
    return instance.get(`inscribe/order/${orderId}`);
  }

  static fetchListTickers (
    params: FetchTickersParams
  ): Promise<FetchTickersResult> {
    return instance.get("/tickers/brc20s", { params }).then((res) => res.data);
  }

  static fetchInfoTicker (ticker: string): Promise<TickersInfo> {
    return instance.get(`/tickers/${ticker}`).then((res) => res.data);
  }

  static fetchInfoInscription (inscriptionId: string): Promise<InscriptionInfo> {
    return instance
      .get(`/market/auction/inscription_info/${inscriptionId}`)
      .then((res) => res.data);
  }

  static createListItem (body: CreateListItemBody): Promise<CreateListItemResponse> {
    return instance.post("/market/create_list_item", body).then((res) => res.data).catch((err) => { throw (err) });
  }

  static confirmListItem (body: {
    auctionId: string;
    psbt: string;
  }): Promise<AxiosResponse<any>> {
    return instance.post("/market/confirm_list_item", body).then((res) => res.data).catch(error => error);;
  }

  static createBuyItemPrepare (
    body: CreateBuyItemPrepareBody
  ): Promise<CreateBuyItemPrepareResponse> {
    return instance
      .post("/market/create_buy_item_prepare", body)
      .then((res) => res.data).catch(error => error);
  }

  static createBuyItem (
    body: CreateBuyItemBody
  ): Promise<CreateBuyItemResponse> {
    return instance
      .post("/market/create_buy_item", body)
      .then((res) => res.data).catch((err) => { throw (err) });
  }

  static confirmBuyItem (body: ConfirmBuyItemBody) {
    return instance.post("/market/confirm_buy_item", body);
  }

  static marketAuctionListRequest (param: AuctionListParam): Promise<PaginationResponse<AuctionListResponse>> {
    const query = objectToQueryStringByComma(param);
    return instance.get(`market/auction/list?${query}`).then((response) => response.data).catch((err) => err);
  }

  static marketAuctionOrderRequest (param: AuctionOrderParam): Promise<PaginationResponse<AuctionOrderResponse>> {
    const query = objectToQueryStringByComma(param);
    return instance.get(`market/auction/orders?${query}`).then(data => data.data).catch((err) => err);
  }

  static fetchListAuctionOrder (
    params: ListAuctionOrders
  ): Promise<ListAuctionOrdersResponse> {
    return instance
      .get("/market/auction/orders", {
        params,
      })
      .then((res) => res.data);
  }

  static createDeListItem (data: { auctionId: number }) {
    return instance.post("/market/create_delist_item", data)
  }

  static confirmDeListItem (data: {
    auctionId: number,
    psbt: string
  }) {
    return instance.post("/market/confirm_delist_item", data).then((res) => res.data);
  }

  static fetchMarketTicker (tick: string) {
    return instance.get(`/tickers/brc20s/${tick}`).then((res) => res.data).catch(err => { throw (err) });
  }

}

export default FunbitService;
