import "./textarea.scss";

type TProps = {
    rows?: number,
    placeholder?: string,
    classTextarea?: string,
    value: string,
    handleChange: (value: string) => void
}

const TextareaCustom = ({rows, placeholder, classTextarea, value, handleChange}: TProps) => {

    return (
        <textarea 
            className={`textarea-wrapper ${classTextarea}`} 
            rows={rows} 
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
        >
        </textarea>
    )
}

export default TextareaCustom