import { KTSVG } from "../../../../_metronic/helpers";
import TradeTabs from "../components/TradeTabs";
import ButtonsGruoup from "../../components/button/ButtonsGroup";
import ChartLineCustom from "../../components/chart/ChartLineCustom";

const initialData = [
	{ time: "2018-12-01", value: 22.68 },
	{ time: "2018-12-02", value: 22.68 },
	{ time: "2018-12-03", value: 31.11 },
	{ time: "2018-12-04", value: 27.02 },
	{ time: "2018-12-05", value: 27.32 },
	{ time: "2018-12-06", value: 25.17 },
	{ time: "2018-12-07", value: 28.89 },
	{ time: "2018-12-08", value: 25.46 },
	{ time: "2018-12-09", value: 23.92 },
	{ time: "2018-12-10", value: 22.68 },
	{ time: "2018-12-11", value: 22.68 },
	{ time: "2018-12-12", value: 22.68 },
	{ time: "2018-12-13", value: 31.11 },
	{ time: "2018-12-14", value: 27.02 },
	{ time: "2018-12-15", value: 27.32 },
	{ time: "2018-12-16", value: 25.17 },
	{ time: "2018-12-17", value: 28.89 },
	{ time: "2018-12-18", value: 25.46 },
	{ time: "2018-12-19", value: 23.92 },
	{ time: "2018-12-20", value: 22.68 },
	{ time: "2018-12-21", value: 22.67 },
	{ time: "2018-12-22", value: 32.51 },
	{ time: "2018-12-23", value: 28.89 },
	{ time: "2018-12-24", value: 27.02 },
	{ time: "2018-12-25", value: 27.32 },
	{ time: "2018-12-26", value: 25.17 },
	{ time: "2018-12-27", value: 28.89 },
	{ time: "2018-12-28", value: 25.46 },
	{ time: "2018-12-29", value: 23.92 },
	{ time: "2018-12-30", value: 22.68 },
	{ time: "2018-12-31", value: 22.67 },
];

const initialData1 = [
	{ time: "2018-12-01", value: 12.68 },
	{ time: "2018-12-02", value: 12.68 },
	{ time: "2018-12-03", value: 11.11 },
	{ time: "2018-12-04", value: 17.02 },
	{ time: "2018-12-05", value: 17.32 },
	{ time: "2018-12-06", value: 15.17 },
	{ time: "2018-12-07", value: 18.89 },
	{ time: "2018-12-08", value: 15.46 },
	{ time: "2018-12-09", value: 3.92 },
	{ time: "2018-12-10", value: 2.68 },
	{ time: "2018-12-11", value: 2.68 },
	{ time: "2018-12-12", value: 2.68 },
	{ time: "2018-12-13", value: 1.11 },
	{ time: "2018-12-14", value: 7.02 },
	{ time: "2018-12-15", value: 7.32 },
	{ time: "2018-12-16", value: 5.17 },
	{ time: "2018-12-17", value: 8.89 },
	{ time: "2018-12-18", value: 5.46 },
	{ time: "2018-12-19", value: 3.92 },
	{ time: "2018-12-20", value: 2.68 },
	{ time: "2018-12-21", value: 2.67 },
	{ time: "2018-12-22", value: 2.51 },
	{ time: "2018-12-23", value: 8.89 },
	{ time: "2018-12-24", value: 7.02 },
	{ time: "2018-12-25", value: 7.32 },
	{ time: "2018-12-26", value: 5.17 },
	{ time: "2018-12-27", value: 8.89 },
	{ time: "2018-12-28", value: 5.46 },
	{ time: "2018-12-29", value: 3.92 },
	{ time: "2018-12-30", value: 2.68 },
	{ time: "2018-12-31", value: 2.67 },
];

const series1 = [
	{
		lineColor: "#fe8a01",
		areaTopColor: "#312c30",
		areaBottomColor: "#1f2330",
		data: initialData,
	},
];

const series2 = [
	{
		lineColor: "#72f238",
		areaTopColor: "#263333",
		areaBottomColor: "#1f2330",
		data: initialData,
	},
	{
		lineColor: "#fe8a01",
		areaTopColor: "#312c30",
		areaBottomColor: "#1f2330",
		data: initialData1,
	},
];

const TIME_BUTTONS_GROUP = [
	{
		label: "24H",
		code: "0",
		btnClass: "button-grey-light",
	},
	{
		label: "7D",
		code: "1",
		btnClass: "button-grey-light",
	},
	{
		label: "30D",
		code: "2",
		btnClass: "button-grey-light",
	},
];

const CHART_LIST = [
	{
		title: "24H INSCRIBE REVENUE",
		value: "$781,734.70",
		percent: "10%",
	},
	{
		title: "24H FUNBIT VOLUME",
		value: "$587,201.86",
		percent: "10%",
	},
	{
		title: "24H MARKETPLACE VOLUME",
		value: "$216,138.21",
		percent: "20%",
	},
];

const MarketCapPage = () => {
	return (
		<div className="market-cap-page">
			<div className="chart-list">
				<div className="d-flex spaces gap-16 flex-wrap">
					{CHART_LIST.map((chartInfo) => (
						<div className="chart-item-wrapper">
							<div className="chart-header">
								<div className="title">{chartInfo.title}</div>
								<KTSVG
									path="/media/svg/general/info.svg"
									className="spaces w-15 h-15"
								/>
							</div>
							<div className="spaces fs-20 fw-800 text-color-white">
								{chartInfo.value}
							</div>
							<div className="d-flex align-items-center">
								<KTSVG
									path="/media/svg/navigation/rollbit-coin/buy-burn.svg"
									width={10}
									height={14}
									color="#fe8a01"
								/>
								<span className="spaces fs-14 ml-6 color-orange">
									{chartInfo.percent}
								</span>
							</div>
							<ChartLineCustom
								data={initialData}
								height={140}
								seriesData={series2}
							/>
						</div>
					))}
				</div>
				<div className="d-flex spaces gap-16 flex-wrap">
					<div className="chart-item-wrapper">
						<div className="chart-header">
							<div className="title">FUNBIT SUPPLY</div>
							<KTSVG
								path="/media/svg/general/info.svg"
								className="spaces w-15 h-15"
							/>
						</div>
						<div className="progress-bar-wrapper">
							<KTSVG
								path="/media/svg/navigation/rollbit-coin/buy-burn.svg"
								width={10}
								height={14}
								color="#fe8a01"
							/>
							<KTSVG
								path="/media/svg/icons/double-arrow-right.svg"
								svgClassName="spaces w-11 h-8"
								color="#fe8a01"
							/>
							<div className="progress-bar"></div>
						</div>
						<div>
							<div className="d-flex align-items-center spaces gap-10 pt-24 pb-8">
								<div className="line-item line--orange"></div>
								<div>Burnt FUNBIT</div>
								<div className="color-orange">38.94%</div>
							</div>
							<div className="spaces fs-20 fw-800">1,947,161,261 FUNBIT</div>
						</div>
						<div>
							<div className="d-flex align-items-center spaces gap-10 pt-24 pb-8">
								<div className="line-item line--green"></div>
								<div>Burnt FUNBIT</div>
								<div className="text-color-green">61.06%</div>
							</div>
							<div className="spaces fs-20 fw-800">3,052,838,739 FUNBIT</div>
						</div>
						<div className="lable">
							<span className="text-color-gray spaces mr-4">
								Original FUNBIT Supply:{" "}
							</span>{" "}
							5,000,000,000 FUNBIT
						</div>
					</div>
					<div className="chart-item-wrapper">
						<div className="chart-header">
							<div className="title">24H INSCRIBE REVENUE</div>
							<KTSVG
								path="/media/svg/general/info.svg"
								className="spaces w-15 h-15"
							/>
						</div>
						<div className="spaces fs-20 fw-800 mt-6 mb-6 text-color-white">
							$781,734.70
						</div>
						<div>$271,038.02</div>
						<div className="chart-content">
							<ChartLineCustom
								data={initialData}
								height={180}
								seriesData={series1}
							/>
						</div>
						<div className="group-button">
							<ButtonsGruoup
								buttonList={TIME_BUTTONS_GROUP}
								btnActiveDefault={TIME_BUTTONS_GROUP[2].code}
							/>
						</div>
					</div>
				</div>
			</div>
			<TradeTabs />
		</div>
	);
};

export default MarketCapPage;
