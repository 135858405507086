import clsx from "clsx";
import { Modal } from "react-bootstrap";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import TableCustom from "../../../../components/table/TableCustom";
import { useMyAssets } from "../../../hooks";
import { useWallet } from "../../../../../../providers/wallet_provider";

type TProps = {
	handleClose: () => void;
	handleClickButtonList: (nftInfo: any) => void;
};

const MyAssetsModal = ({ handleClose, handleClickButtonList }: TProps) => {
	const { account } = useWallet();

	const { data: dataAssets } = useMyAssets({
		address: account,
		limit: 1000,
		start: 0,
	});

	const columns = [
		{
			name: "brc-20",
			field: "ticker",
			classHeader: "text-center",
			classCell: "text-center",
		},
		{
			name: "transferable",
			field: "transferableBalance",
			classHeader: "text-center",
			classCell: "text-center",
		},
		{
			name: "available",
			field: "availableBalance",
			classHeader: "text-center",
			classCell: "text-center",
		},
		{
			name: "",
			field: "",
			classHeader: "text-center",
			classCell: "text-center",
			render: (row: any) => (
				<ButtonCustom
					label={"List"}
					labelClassName="text-color-yellow text-capitalize"
					btnClassName="button button-grey-light spaces h-32 min-h-32"
					handleClick={() => handleClickButtonList(row)}
				/>
			),
		},
	];

	return (
		<>
			<Modal
				contentClassName={clsx("modal-wrapper spaces max-w-540")}
				show={true}
				size="lg"
				onHide={handleClose}
			>
				<div className="body-content">
					<TableCustom columns={columns} data={dataAssets?.detail || []} />
				</div>
			</Modal>
		</>
	);
};

export default MyAssetsModal;
