import { useNavigate } from "react-router-dom";
import TableCustom from "../../../components/table/TableCustom";
import { columns5, data4 } from "../../constant";

type Props = {};

const CollectionsTab = (props: Props) => {
	const navigate = useNavigate();

	return (
		<div className="brc-20-tab-container">
			<TableCustom
				columns={columns5}
				data={data4}
				handleClickRow={(data) => {
					console.log(data);
					navigate(`/marketplace/collection/${data?.tick}`);
				}}
			/>
		</div>
	);
};

export default CollectionsTab;
