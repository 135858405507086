import React, { useEffect, useRef, useState } from "react";
import { KTSVG, WithChildren } from "../../../../_metronic/helpers";
import "./slider.scss";
import { useAutomaticSlide } from "../../../hooks/automaticSlice";
import { showSlide } from "../../../utils";
import { Link } from "react-router-dom";

type Props = {
  classSilder: string;
  sliderTitle?: string;
  sliderList: {}[];
  headerPath?: string;
  headerPathName?: string;
  sliderItem: (props: any) => React.ReactNode;
  iconHearderSlide?: React.ReactNode;
  nowrap?: boolean;
  itemQuantity: {
    laptop: number;
    tablet: number;
    mobile: number;
  };
};

const SilderNormal: React.FC<Props & WithChildren> = ({
  classSilder,
  sliderList,
  sliderItem,
  sliderTitle,
  headerPath,
  iconHearderSlide,
  itemQuantity,
  nowrap = true,
  headerPathName = "All view",
}) => {
  const itemRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);
  const { step, slideIndex, handleChangeIndexByClick } = useAutomaticSlide(
    sliderList,
    itemQuantity.laptop,
    itemQuantity.tablet,
    itemQuantity.mobile
  );
  const [widthItem, setWidthItem] = useState("");

  const handlePrevSlide = () => {
    if (handleChangeIndexByClick) handleChangeIndexByClick(-1);
  };

  const handleNextSlide = () => {
    if (handleChangeIndexByClick) handleChangeIndexByClick(1);
  };

  useEffect(() => {
    const itemContentWidth = itemRef.current?.getWidth();
    const totalWidth = containerRef.current?.offsetWidth || 1120;

    const paddingWidth = Math.round(
      (totalWidth - itemContentWidth * step) / (step - 1) / 2
    );
    const totalItemWidth = itemContentWidth + paddingWidth * 2;
    showSlide(slideIndex, containerRef?.current, totalItemWidth);
  }, [slideIndex, step]);

  useEffect(() => {
    if (containerRef.current) {
      const widthMobile = 425;
      const widthContainer = containerRef.current?.offsetWidth;
      const paddingWidth =
        widthContainer < widthMobile ? (step - 1) * 6 : (step - 1) * 12;
      setWidthItem(`${(widthContainer - paddingWidth) / step}px`);
    }
  }, [step]);

  return (
    <>
      {headerPath && (
        <div className="slider-header">
          <div className="slider-header--left">
            <Link
              to={headerPath}
              className="d-flex align-items-center slider-title"
            >
              {iconHearderSlide}
              <div className="title">{sliderTitle}</div>
            </Link>
            <Link to={headerPath}>
              <div className="btn-viewAll">{headerPathName}</div>
            </Link>
          </div>
          {nowrap && (
            <div className="btn-group">
              <div className="btn-left" onClick={handlePrevSlide}>
                <KTSVG
                  path="/media/svg/icons/arrowBackIos.svg"
                  width={6}
                  height={11}
                />
              </div>
              <div className="btn-right" onClick={handleNextSlide}>
                <KTSVG
                  path="/media/svg/icons/arrowBackIos.svg"
                  width={6}
                  height={12}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={classSilder}
        ref={containerRef}
        style={{
          gridTemplateColumns: `repeat(auto-fill, minmax(${widthItem}, 1fr))`,
        }}
      >
        {sliderList.map((item) =>
          sliderItem({
            ...item,
            ref: itemRef,
            style: { minWidth: widthItem },
          })
        )}
      </div>
    </>
  );
};

export default SilderNormal;
