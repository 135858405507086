import { ReactNode, useEffect, useState } from "react";

type TProps = {
	className?: string;
	startInput?: ReactNode;
	placeholder?: string;
	value?: string;
	onChange?: (value: string) => void;
};

const InputCustom = ({
	className,
	startInput,
	placeholder,
	value,
	onChange,
}: TProps) => {
	const [text, setText] = useState(value || "");

	useEffect(() => {
		const timerId = setTimeout(() => {
			if (onChange) onChange(text);
		}, 500);
		return () => {
			clearTimeout(timerId);
		};
	}, [onChange, text]);

	return (
		<div className={`input-custom-wrapper ${className}`}>
			{startInput}
			<input
				className="input-custom"
				placeholder={placeholder}
				value={text}
				onChange={(e) => setText(e.target.value)}
			/>
		</div>
	);
};

export default InputCustom;
