import { ConfigProvider, Pagination, PaginationProps } from "antd";

const Panination = (props: PaginationProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Pagination: {
            itemActiveBg: "transperant",
            itemLinkBg: "red",
          },
          Select: {
            selectorBg: "transperant",
            optionActiveBg: "#0f111a66",
            optionSelectedColor: "#1f2330",
            optionSelectedBg: "#ffb018",
          },
        },
        token: {
          colorBgContainer: "transperant",
          colorBgTextHover: "#1f2330",
          colorBorder: "#888888",
          colorPrimary: "#ffb018",
          colorText: "#FFFFFF",
          colorTextDisabled: "#FFFFFF",
          colorTextPlaceholder: "#FFFFFF",
          controlOutline: "#ffb018",
          colorBgElevated: "#1f2330",
        },
      }}
    >
      <div className="spaces my-8">
        <Pagination {...props} />
      </div>
    </ConfigProvider>
  );
};

export default Panination;
