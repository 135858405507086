import TextTab from "./components/TextTab";
import Brc20Tab from "./components/Brc20Tab";
import NameTab from "./components/NameTab";
import FilesTab from "./components/FilesTab";
import LocalOrdersTab from "./components/LocalOrdersTab";
import MyOrdersTab from "./components/MyOrdersTab";
import NamesPopover from "./components/NamesPopover";

export const INSCRIBE_TAB_KEYS = {
	FILES: "1",
	NAMES: "2",
	TEXT: "3",
	BRC20: "4",
};

// export const INSCRIBE_TAB_LIST = [
// 	{
// 		nav: {
// 			label: "brc-20",
// 			eventKey: INSCRIBE_TAB_KEYS.BRC20,
// 		},
// 		content: {
// 			component: <Brc20Tab />,
// 		},
// 	},
// 	{
// 		nav: {
// 			label: "Files",
// 			eventKey: INSCRIBE_TAB_KEYS.FILES,
// 		},
// 		content: {
// 			component: <FilesTab />,
// 		},
// 	},
// 	{
// 		nav: {
// 			eventKey: INSCRIBE_TAB_KEYS.NAMES,
// 			component: <NamesPopover />,
// 		},
// 		content: {
// 			component: <NameTab />,
// 		},
// 	},
// 	{
// 		nav: {
// 			label: "Text",
// 			eventKey: INSCRIBE_TAB_KEYS.TEXT,
// 		},
// 		content: {
// 			component: <TextTab />,
// 		},
// 	},
// ];

export const SEARCH_ORDER_OPTIONS = [
	{ label: "All", value: "all" },
	{ label: "Pending", value: "Pending" },
	{ label: "Inscribing", value: "Inscribing" },
	{ label: "Minted", value: "Minted" },
	{ label: "Closed", value: "Closed" },
	{ label: "Refunded", value: "Refunded" },
];

export const ORDER_TABS = [
	// {
	// 	key: "1",
	// 	label: "Local Orders",
	// 	children: <LocalOrdersTab />,
	// },
	{
		key: "2",
		label: <>My Orders</>,
		children: <MyOrdersTab />,
	},
];
