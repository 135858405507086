import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuctionListResponse } from "../../../../../../types/marketplace-service";
import useGetBitcoinPrices from "../../../../../hooks/useGetBitcoinPrices";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ConfirmModal from "./ConfirmModal";
import { useMutation } from "react-query";
import FunbitService from "../../../../../services/funbitServices";
import { useWallet } from "../../../../../../providers/wallet_provider";
import ConnectWalletModal from "../../../../components/modal/connect-wallet/ConnectWalletModal";

export type TradeCardProps = {
	data: AuctionListResponse;
	pubKey: string;
};

const BTC_TO_SATS = 100000000;

const TradeCardCollection = (props: TradeCardProps) => {
	const { data } = props;
	const [shouldOpenConfirmModal, setShouldOpenConfirmModal] = useState(false);
	const [shouldOpenConnectWalletModal, setShouldOpenConnectWalletModal] =
		useState(false);
	const { account } = useWallet();
	const { mutateAsync, data: prepareBuyItem } = useMutation(
		FunbitService.createBuyItemPrepare
	);

	const navigation = useNavigate();
	const btcPrice = useGetBitcoinPrices();
	const satsPrice = btcPrice * (data.unit_price / BTC_TO_SATS);
	const tickUnit = (data.unit_price * +data?.inscription?.amount) / BTC_TO_SATS;

	const handleBuy = async (e: any) => {
		e.stopPropagation();

		if (account) {
			await mutateAsync({
				auctionId: data.id,
				bidPrice: data.init_price,
				pubkey: props.pubKey,
				address: account,
			});

			setShouldOpenConfirmModal(true);
		} else {
			setShouldOpenConnectWalletModal(true);
		}
	};

	return (
		<>
			<div
				className="trade-collection-item-container"
				// onClick={() => navigation(`/inscription/${data?.inscription_id}`)}
			>
				<div className="content display-domain d-flex align-items-center justify-content-center">
					<img
						src="https://static-testnet.unisat.io/content/ac02bd2dccff8c24464659540d0bcb2a4ba2e8f8320ffe100eb32cbd973ddd9ei0"
						alt=""
						style={{
							width: "238px",
							height: "238px",
							imageRendering: "pixelated",
						}}
					/>
				</div>
				<div className="info display-domain">
					<div className="flex-row-between spaces pt-8">
						<span className="number">Test #123</span>
					</div>
					<div className="flex-row-between spaces pt-8">
						<span className="number">{`#${data?.inscription?.inscription_number}`}</span>
					</div>
					<div className="divider"></div>
					<div className="d-flex align-items-center flex-row-between">
						<span className="d-flex align-items-center spaces gap-4">
							<img src="/media/images/coins/BTC.png" width={16} alt="" />
							{tickUnit}
						</span>
						<span className="usd">{`${(
							satsPrice * +data?.inscription?.amount
						).toFixed(3)}$`}</span>
					</div>
					<ButtonCustom
						label="Buy"
						btnClassName="button button-grey-light w-100 spaces mt-10"
						// handleClick={handleBuy}
					/>
				</div>
			</div>
			{shouldOpenConnectWalletModal && (
				<ConnectWalletModal
					handleClose={() => setShouldOpenConnectWalletModal(false)}
				/>
			)}
			{shouldOpenConfirmModal && (
				<ConfirmModal
					quantity={+data?.inscription?.amount}
					tick={data?.inscription?.brc20?.tick}
					auctionId={data?.id}
					bidPrice={data?.init_price}
					handleClose={() => setShouldOpenConfirmModal(false)}
					pubkey={props.pubKey}
					{...data}
					{...prepareBuyItem}
				/>
			)}
		</>
	);
};

export default TradeCardCollection;
