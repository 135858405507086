import { Navigate } from "react-router-dom";
import BattlesPage from "../modules/battles/BattlesPage";
import CardGameBattle from "../modules/battles/components/CardGameBattle";
import GameBattleList from "../modules/battles/components/GameBattleList";
import {
	CARD_GAMES_BATTLE_ACTIVE_LIST,
	CARD_GAMES_BATTLE_AVAILABLE_LIST,
	CARD_GAMES_BATTLE_COMPLETED_LIST,
} from "../modules/battles/constants";
import CreateBattlePage from "../modules/battles/page/CreateBattlePage";
import GameBattlePage from "../modules/battles/page/GameBattlePage";
import CasinoPage from "../modules/casino/CasinoPage";
import CardGameItem from "../modules/casino/components/card-game/CardGameItem";
import {
	CARD_GAMES_BATTLE_LIST,
	PROVIDER_LIST,
	ROLLBIT_ORIGINALS,
} from "../modules/casino/constants";
import CategoryPage from "../modules/casino/pages/CategoryPage";
import Challenges from "../modules/challenges/Challenges";
import CreateChallenges from "../modules/challenges/page/CreateChallenges";
import ProviderPage from "../modules/providers/ProviderPage";
import GameListFromProviderPage from "../modules/providers/page/GameListFromProviderPage";
import ActiveTab from "../modules/challenges/components/active-tab/ActiveTab";
import ClaimedTab from "../modules/challenges/components/claimed-tab/ClaimedTab";
import Clans from "../modules/clans/Clans";
import PublicClans from "../modules/clans/components/public-clans/PublicClans";
import MyClans from "../modules/clans/components/my-clans/MyClans";
import InfoClans from "../modules/clans/components/info-clans/InfoClans";
import DetailClans from "../modules/clans/components/info-clans/DetailsClan";
import StatsClan from "../modules/clans/components/info-clans/StatsClan";
import TradingPage from "../modules/trading/TradingPage";
import LeaderboardPage from "../modules/trading/pages/LeaderboardPage";
import NFTLoan from "../modules/nft/nft-loans/NFTLoans";
import MyNFT from "../modules/nft/my-nft/MyNFT";
import Portfolio from "../modules/nft/my-nft/portfolio/Portfolio";
import MyLoans from "../modules/nft/my-nft/my-loans/MyLoans";
import ExternalPage from "../modules/nft/my-nft/external/ExternalPage";
import ForSale from "../modules/nft/marketplace/ForSale";
import MarketPlaceNFT from "../modules/nft/marketplace/MarketPlaceNFT";
import MySales from "../modules/nft/marketplace/MySales";
import MyPurchases from "../modules/nft/marketplace/MyPurchases";
import NFTBox from "../modules/nft/marketplace/NFTBox";
import NFTHomePage from "../modules/nft/NFTHomePage";
import NFTLobby from "../modules/nft/pages/NFTLobby";
import NFTLobbyRollbot from "../modules/nft/pages/NFTLobbyRollbot";
import RollbitCoin from "../modules/rlb/RollbitCoin";
import TradePage from "../modules/rlb/pages/TradePage";
import MarketCapPage from "../modules/rlb/pages/MarketCapPage";
import HoldingsPage from "../modules/rlb/pages/HoldingsPage";
import MyWalletTab from "../modules/rlb/components/MyWalletTab";
import PoolsTab from "../modules/rlb/components/PoolsTab";
import LotteryPage from "../modules/rlb/pages/lottery-page/LotteryPage";
import LotteryHomePage from "../modules/rlb/pages/lottery-page/components/LotteryHomePage";
import CurrentBets from "../modules/rlb/pages/lottery-page/components/CurrentBets";
import MyPrizes from "../modules/rlb/pages/lottery-page/components/MyPrizes";
import PrevLottery from "../modules/rlb/pages/lottery-page/components/PrevLottery";
import AccountPage from "../modules/accounts/AccountPage";
import BalencesPage from "../modules/accounts/pages/BalancesPage";
import ReferralPage from "../modules/referral/Referral";
import DepositPage from "../modules/accounts/pages/DepositPage";
import WithdrawalsPage from "../modules/accounts/pages/WithdrawalsPage";
import SettingPages from "../modules/accounts/pages/SettingPages";
import SupportPage from "../modules/page-by-footer/SupportPage";
import PartnershipProgram from "../modules/page-by-footer/PartnershipProgram";
import FAQPage from "../modules/page-by-footer/FAQPage";
import AMLPolicyPage from "../modules/page-by-footer/AMLPolicyPage";
import SportPolicyPage from "../modules/page-by-footer/SportPolicyPage";
import ResponsiveGamingPage from "../modules/page-by-footer/ResponsiveGamingPage";
import PrivatePolicy from "../modules/page-by-footer/PrivatePolicy";
import TermAndConditions from "../modules/page-by-footer/TermAndConditions";
import Stacking from "../modules/stacking/Stacking";
import PlayGamePage from "../modules/casino/pages/PlayGamePage";
import HomePage from "../modules/home-page/HomePage";
import CasinoHomePage from "../modules/casino/pages/CasinoHomePage";
import ProfilePage from "./../modules/accounts/pages/ProfilePage";
import InfoDetailCoin from "../modules/marketplace/components/info-detail-coin/InfoDetailCoin";
import InscribePage from "../modules/inscribe/InscribePage";
import FunbitHomepage from "./../modules/home-page/FunbitHomepage";
import MarketplaceTradePage from "../modules/marketplace/components/trade-page/MarketplaceTradePage";
import Marketplace from "../modules/marketplace/Marketplace";
import MarketplaceHomePage from "../modules/marketplace/components/MarketplaceHomePage";
import InscriptionDetailPage from "../modules/marketplace/components/inscription-detail/InscriptionDetailPage";
import MarketplaceCollectionTradePage from "../modules/marketplace/components/trade-page/MarketplaceCollectionTradePage";

export const CASINO_ROUTES = [
	{
		path: "/casino",
		element: <CasinoPage />,
		children: [{ path: "", element: <CasinoHomePage /> }],
	},
];

export const INSCRIBE_ROUTES = [
	{
		path: "/inscribe",
		element: <InscribePage />,
	},
];

export const INSCRIPTION_ROUTES = [
	{
		path: "/inscription",
		element: <InscriptionDetailPage />,
	},
	{
		path: "/inscription/:inscription_id",
		element: <InscriptionDetailPage />,
	},
];

export const BATTLES_ROUTES = [
	{
		path: "/battles",
		element: <BattlesPage />,
		children: [
			{
				path: "",
				element: (
					<GameBattleList gamesBattleList={CARD_GAMES_BATTLE_AVAILABLE_LIST} />
				),
			},
			{
				path: "active",
				element: (
					<GameBattleList gamesBattleList={CARD_GAMES_BATTLE_ACTIVE_LIST} />
				),
			},
			{
				path: "inactive",
				element: (
					<GameBattleList gamesBattleList={CARD_GAMES_BATTLE_COMPLETED_LIST} />
				),
			},
		],
	},
	{ path: "/battles/game-info", element: <GameBattlePage /> },
	{ path: "/battles/create", element: <CreateBattlePage /> },
];

const providerRoutes = () => {
	const providerChildren = PROVIDER_LIST.map((provider) => {
		return {
			path: provider.navPath.replace("/providers/", ""),
			element: <GameListFromProviderPage providerName={provider.name} />,
		};
	});

	const providerRoutes = [
		{
			path: "/providers",
			children: [{ path: "", element: <ProviderPage /> }, ...providerChildren],
		},
	];

	return providerRoutes;
};
export const PROVIDERS_ROUTES = providerRoutes();

export const CATEGORY_ROUTES = [
	{
		path: "/category",
		element: <CasinoPage />,
		children: [
			{
				path: "favourites",
				element: (
					<CategoryPage
						className="card-game-list"
						placeholder="Search favourites"
						cardGameList={ROLLBIT_ORIGINALS}
						renderComponent={(props: any) => <CardGameItem {...props} />}
					/>
				),
			},
			{
				path: "funbit",
				element: (
					<CategoryPage
						className="card-game-list"
						placeholder="Search funbit"
						cardGameList={ROLLBIT_ORIGINALS}
						renderComponent={(props: any) => <CardGameItem {...props} />}
					/>
				),
			},
			{
				path: "slots",
				element: (
					<CategoryPage
						className="card-game-list"
						placeholder="Search slots"
						cardGameList={ROLLBIT_ORIGINALS}
						renderComponent={(props: any) => <CardGameItem {...props} />}
					/>
				),
			},
			{
				path: "game-shows",
				element: (
					<CategoryPage
						className="card-game-list"
						placeholder="Search shows"
						cardGameList={ROLLBIT_ORIGINALS}
						renderComponent={(props: any) => <CardGameItem {...props} />}
					/>
				),
			},
			{
				path: "live-casino",
				element: (
					<CategoryPage
						className="card-game-list"
						placeholder="Search live casino"
						cardGameList={ROLLBIT_ORIGINALS}
						renderComponent={(props: any) => <CardGameItem {...props} />}
					/>
				),
			},
			{
				path: "roulette",
				element: (
					<CategoryPage
						className="card-game-list"
						placeholder="Search roulette"
						cardGameList={ROLLBIT_ORIGINALS}
						renderComponent={(props: any) => <CardGameItem {...props} />}
					/>
				),
			},
			{
				path: "blackjack",
				element: (
					<CategoryPage
						className="card-game-list"
						placeholder="Search blackjack"
						cardGameList={ROLLBIT_ORIGINALS}
						renderComponent={(props: any) => <CardGameItem {...props} />}
					/>
				),
			},
			{
				path: "sidebets",
				element: (
					<CategoryPage
						className="card-game-list"
						placeholder="Search sidebets"
						cardGameList={ROLLBIT_ORIGINALS}
						renderComponent={(props: any) => <CardGameItem {...props} />}
					/>
				),
			},
			{
				path: "challenges",
				element: (
					<CategoryPage
						className="card-game-list"
						placeholder="Search challenges"
						cardGameList={ROLLBIT_ORIGINALS}
						renderComponent={(props: any) => <CardGameItem {...props} />}
					/>
				),
			},
			{
				path: "battles",
				element: (
					<CategoryPage
						className="card-game-battle-list"
						placeholder="Search battles"
						cardGameList={CARD_GAMES_BATTLE_LIST}
						renderComponent={(props: any) => <CardGameBattle {...props} />}
					/>
				),
			},
		],
	},
];

export const CHALLENGES_ROUTES = [
	{
		path: "/challenges",
		element: <Challenges />,
		children: [
			{ path: "", element: <Navigate to="active" /> },
			{ path: "active", element: <ActiveTab /> },
			{ path: "claimed", element: <ClaimedTab /> },
		],
	},
	{ path: "challenges/create", element: <CreateChallenges /> },
];

export const CLANS_ROUTES = [
	{
		path: "/clans",
		element: <Clans />,
		children: [
			{ path: "", element: <Navigate to="public" /> },
			{ path: "public", element: <PublicClans /> },
			{ path: "my", element: <MyClans /> },
			{
				path: ":clanId",
				element: <InfoClans />,
				children: [
					{ path: "clan", element: <DetailClans /> },
					{ path: "stats", element: <StatsClan /> },
				],
			},
		],
	},
];

export const TRADING_ROUTES = [
	{
		path: "/trading",
		children: [
			{ path: "", element: <TradingPage /> },
			{ path: "rollercoaster", element: <TradingPage /> },
			{ path: "leaderboard", element: <LeaderboardPage /> },
		],
	},
];

export const SPORTS_ROUTES = [
	{
		path: "/sports",
		children: [
			{ path: "", element: <>Sports</> },
			{ path: "myBets", element: <>myBets</> },
			{ path: "live", element: <>live</> },
			{ path: "schedule", element: <>schedule</> },
			{ path: "favorites", element: <>favorites</> },
			{ path: "soccer", element: <>soccer</> },
			{ path: "tennis", element: <>tennis</> },
			{ path: "fifa", element: <>fifa</> },
			{ path: "basketball", element: <>basketball</> },
			{ path: "ice-hockey", element: <>ice-hockey</> },
			{ path: "volleyball", element: <>volleyball</> },
			{ path: "table-tennis", element: <>table-tennis</> },
			{ path: "baseball", element: <>baseball</> },
			{ path: "handball", element: <>handball</> },
			{ path: "golf", element: <>golf</> },
			{ path: "boxing", element: <>boxing</> },
			{ path: "rugby", element: <>rugby</> },
			{ path: "aussie-rules", element: <>aussie-rules</> },
			{ path: "american-football", element: <>american-football</> },
			{ path: "cycling", element: <>cycling</> },
			{ path: "specials", element: <>specials</> },
			{ path: "snooker", element: <>snooker</> },
			{ path: "cricket", element: <>cricket</> },
			{ path: "darts", element: <>darts</> },
			{ path: "waterpolo", element: <>waterpolo</> },
			{ path: "squash", element: <>squash</> },
			{ path: "formula-1", element: <>formula-1</> },
			{ path: "biathlon", element: <>biathlon</> },
			{ path: "counter-strike", element: <>counter-strike</> },
			{ path: "league-of-legends", element: <>league-of-legends</> },
			{ path: "dota-2", element: <>dota-2</> },
			{ path: "starcraft", element: <>starcraft</> },
			{ path: "mma", element: <>mma</> },
			{ path: "motorcycle-racing", element: <>motorcycle-racing</> },
			{ path: "stock-car-racing", element: <>stock-car-racing</> },
			{ path: "rocket-league", element: <>rocket-league</> },
			{ path: "nba-2k", element: <>nba-2k</> },
			{ path: "ao-tennis-2", element: <>ao-tennis-2</> },
			{ path: "efighting", element: <>eFighting</> },
			{ path: "ecricket", element: <>eCricket</> },
		],
	},
];

export const NFT_ROUTES = [
	{
		path: "/nft",
		children: [
			{ path: "loans", element: <NFTLoan /> },
			{
				path: "lobby",
				element: <NFTHomePage />,
				children: [
					{ path: "", element: <NFTLobby /> },
					{ path: "rollbots", element: <NFTLobbyRollbot /> },
					{ path: "sportsbots", element: <NFTLobbyRollbot /> },
				],
			},
			{
				path: "my-nft",
				element: <MyNFT />,
				children: [
					{ path: "", element: <Navigate to="portfolio" /> },
					{ path: "portfolio", element: <Portfolio /> },
					{ path: "my-loans", element: <MyLoans /> },
					{ path: "external", element: <ExternalPage /> },
				],
			},
			{
				path: "marketplace",
				element: <MarketPlaceNFT />,
				children: [
					{ path: "", element: <ForSale /> },
					{ path: "my-sales", element: <MySales /> },
					{ path: "my-purchases", element: <MyPurchases /> },
					{ path: "box", element: <NFTBox /> },
				],
			},
			{ path: "lootboxes/play", element: <>/lootboxes/play</> },
			{ path: "rollbot/portfolio", element: <>rollbot/portfolio</> },
			{ path: "sportsbot/portfolio", element: <>rollbot/portfolio</> },
		],
	},
];

export const FUN_ROUTES = [
	{
		path: "/fun",
		element: <RollbitCoin />,
		children: [
			{ path: "trade", element: <TradePage /> },
			{ path: "market-cap", element: <MarketCapPage /> },
			{ path: "holdings", element: <HoldingsPage /> },
			{ path: "my-wallets/deposit", element: <MyWalletTab /> },
			{ path: "pools", element: <PoolsTab /> },
			{
				path: "lottery",
				element: <LotteryPage />,
				children: [
					{ path: "current", element: <LotteryHomePage /> },
					{ path: "stakes", element: <CurrentBets /> },
					{ path: "my-prizes", element: <MyPrizes /> },
					{ path: "previous", element: <PrevLottery /> },
				],
			},
		],
	},
];

export const ACCOUNT_ROUTES = [
	{
		path: "/account",
		element: <AccountPage />,
		children: [
			{ path: "profile", element: <ProfilePage /> },
			{ path: "balances", element: <BalencesPage /> },
			{ path: "referrals/codes", element: <ReferralPage /> },
			{ path: "deposits/ALL", element: <DepositPage /> },
			{ path: "withdrawals/ALL", element: <WithdrawalsPage /> },
			{ path: "settings", element: <SettingPages /> },
		],
	},
];

export const FOOTER_ROUTES = [
	{ path: "support", element: <SupportPage /> },
	{ path: "partnership-program", element: <PartnershipProgram /> },
	{ path: "faq", element: <FAQPage /> },
	{ path: "aml", element: <AMLPolicyPage /> },
	{ path: "sports-policy", element: <SportPolicyPage /> },
	{ path: "responsible-gaming", element: <ResponsiveGamingPage /> },
	{ path: "privacy", element: <PrivatePolicy /> },
	{ path: "terms-and-conditions", element: <TermAndConditions /> },
];

export const ORTHER_ROUTES = [
	{ path: "/", element: <FunbitHomepage /> },
	{ path: "referral", element: <ReferralPage /> },
	{ path: "staking", element: <Stacking /> },
	{ path: "play-game", element: <PlayGamePage /> },
	{ path: "sponsorships/ssc-napoli", element: <>sponsorships/ssc-napoli</> },
	{ path: "jackpot", element: <>jackpot</> },
	{ path: "races/daily", element: <>races/daily</> },
];
export const MARKET_PLACE_ROUTES = [
	{
		path: "/marketplace",
		element: <Marketplace />,
		children: [
			{
				path: "",
				element: <MarketplaceHomePage />,
			},
		],
	},
	{ path: "/detailCoin", element: <InfoDetailCoin /> },
	{ path: "/brc20/:tick", element: <InfoDetailCoin /> },
];

export const DETAIL_TICKERS_ROUTE = [
	{
		path: "/marketplace/collection/:tick",
		element: <MarketplaceCollectionTradePage />,
	},
	{ path: "/marketplace/:tick", element: <MarketplaceTradePage /> },
];

export const SPONSORSHIPS_ROUTES = [
	{ path: "A16Z", element: <>A16Z</> },
	{ path: "binance-labs", element: <>binance-labs</> },
	{ path: "Funbit", element: <>Funbit</> },
	{ path: "ZK-Sync", element: <>ZK-Sync</> },
];

export const ERROR_ROUTES = [
	{ path: "*", element: <Navigate to="/error/404" /> },
];
