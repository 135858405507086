import clsx from "clsx";
import SidebarMenuGroup from "./sidebar-menu/SidebarMenuGroup";
import { KTSVG } from "../../../helpers";
import { useLayout } from "../../core";
import useWindowDimensions from "../../../../app/hooks/useWindowDimensions";
import { SCREEN_WIDTH } from "../../../../app/utils";

const returnIconSize = (width: number, height: number) => {
	return { width, height };
};

const SidebarToggleButton = () => {
	const { config } = useLayout();
	const appSidebarDefaultMinimizeDesktopEnabled =
		config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
	const appSidebarDefaultCollapseDesktopEnabled =
		config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
	const toggleType = appSidebarDefaultCollapseDesktopEnabled
		? "collapse"
		: appSidebarDefaultMinimizeDesktopEnabled
		? "minimize"
		: "";
	const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? "active" : "";
	const appSidebarDefaultMinimizeDefault =
		config.app?.sidebar?.default?.minimize?.desktop?.default;

	const { screenWidth } = useWindowDimensions();

	return (
		<>
			{(appSidebarDefaultMinimizeDesktopEnabled ||
				appSidebarDefaultCollapseDesktopEnabled) &&
				screenWidth > SCREEN_WIDTH.TABLET && (
					<>
						<div
							id="kt_app_sidebar_toggle"
							className={clsx(
								"app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px rotate",
								{ active: appSidebarDefaultMinimizeDefault }
							)}
							data-kt-toggle="true"
							data-kt-toggle-state={toggleState}
							data-kt-toggle-target="body"
							data-kt-toggle-name={`app-sidebar-${toggleType}`}
						>
							<KTSVG
								path="/media/icons/duotune/arrows/arr079.svg"
								className="svg-icon-2 rotate-180"
								width={22}
								height={22}
							/>
						</div>
					</>
				)}
		</>
	);
};

export const NAVIGATION_TYPES = {
	SIDEBAR_GROUP: "1",
	SIDEBAR_WITH_SUB: "2",
	SIDERBAR_ITEM: "3",
	SIDERBAR_TRADING: "4",
};

const MENU_ITEM_CASINO = [
	{
		path: "/category/favourites",
		title: "Favourites",
		icon: "/media/svg/navigation/casino/favourites.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/category/funbit",
		title: "Funbit Originals",
		icon: "/media/svg/navigation/casino/rollbit.svg",
		iconSize: returnIconSize(20, 16),
	},
	{
		path: "/battles",
		title: "Bonus Battles",
		icon: "/media/svg/navigation/casino/battles.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/category/slots",
		title: "Slots",
		icon: "/media/svg/navigation/casino/casino.svg",
		iconSize: returnIconSize(17, 17),
	},
	{
		path: "/category/game-shows",
		title: "Game Shows",
		icon: "/media/svg/navigation/casino/gameShows.svg",
		iconSize: returnIconSize(17, 13),
	},
	{
		path: "/category/live-casino",
		title: "Live Casino",
		icon: "/media/svg/navigation/casino/liveCasino.svg",
		iconSize: returnIconSize(17, 19),
	},
	{
		path: "/category/roulette",
		title: "Roulette",
		icon: "/media/svg/navigation/casino/roulette.svg",
		iconSize: returnIconSize(17, 17),
	},
	{
		path: "/category/blackjack",
		title: "Blackjack",
		icon: "/media/svg/navigation/casino/blackjack.svg",
		iconSize: returnIconSize(18, 10),
	},
	{
		path: "/category/sidebets",
		title: "With Sidebets",
		icon: "/media/svg/navigation/casino/sidebets.svg",
		iconSize: returnIconSize(15, 16),
	},
	{
		path: "/category/challenges",
		title: "With Challenges",
		icon: "/media/svg/navigation/casino/challenges.svg",
		iconSize: returnIconSize(16, 16),
	},
	{
		path: "/challenges",
		title: "Challenges",
		icon: "/media/svg/navigation/casino/challenges.svg",
		iconSize: returnIconSize(16, 16),
	},
	{
		path: "/clans",
		title: "Clans",
		icon: "/media/svg/navigation/casino/clans.svg",
		iconSize: returnIconSize(19, 19),
	},
	{
		path: "/trading/rollercoaster",
		title: "Rollercoaster",
		icon: "/media/svg/navigation/casino/rollercoaster.svg",
		iconSize: returnIconSize(15, 17),
	},
];

const MENU_ITEM_SPORTS = [
	{
		path: "/sports/myBets",
		title: "My Bets",
		icon: "/media/svg/navigation/sports/myBets.svg",
		iconSize: returnIconSize(22, 22),
	},
	{
		path: "/sports/live",
		title: "Live",
		icon: "/media/svg/navigation/sports/live.svg",
		iconSize: returnIconSize(22, 22),
	},
	{
		path: "/sports/schedule",
		title: "Schedule",
		icon: "/media/svg/navigation/sports/schedule.svg",
		iconSize: returnIconSize(22, 22),
	},
	{
		path: "/sports/favorites",
		title: "Favourites",
		icon: "/media/svg/navigation/sports/favourites.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/soccer",
		title: "Soccer",
		icon: "/media/svg/navigation/sports/soccer.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/tennis",
		title: "Tennis",
		icon: "/media/svg/navigation/sports/tennis.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/fifa",
		title: "FIFA",
		icon: "/media/svg/navigation/sports/fifa.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/basketball",
		title: "Basketball",
		icon: "/media/svg/navigation/sports/basketball.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/ice-hockey",
		title: "Ice Hockey",
		icon: "/media/svg/navigation/sports/ice-hockey.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/volleyball",
		title: "Volleyball",
		icon: "/media/svg/navigation/sports/volleyball.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/table-tennis",
		title: "Table Tennis",
		icon: "/media/svg/navigation/sports/table-tennis.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/baseball",
		title: "Baseball",
		icon: "/media/svg/navigation/sports/baseball.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/handball",
		title: "Handball",
		icon: "/media/svg/navigation/sports/handball.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/golf",
		title: "Golf",
		icon: "/media/svg/navigation/sports/golf.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/boxing",
		title: "Boxing",
		icon: "/media/svg/navigation/sports/boxing.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/rugby",
		title: "Rugby",
		icon: "/media/svg/navigation/sports/rugby.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/aussie-rules",
		title: "Aussie Rules",
		icon: "/media/svg/navigation/sports/aussie-rules.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/american-football",
		title: "American Football",
		icon: "/media/svg/navigation/sports/american-football.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/cycling",
		title: "Cycling",
		icon: "/media/svg/navigation/sports/cycling.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/specials",
		title: "Specials",
		icon: "/media/svg/navigation/sports/specials.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/snooker",
		title: "Snooker",
		icon: "/media/svg/navigation/sports/snooker.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/cricket",
		title: "Cricket",
		icon: "/media/svg/navigation/sports/cricket.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/darts",
		title: "Darts",
		icon: "/media/svg/navigation/sports/darts.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/waterpolo",
		title: "Waterpolo",
		icon: "/media/svg/navigation/sports/waterpolo.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/squash",
		title: "Squash",
		icon: "/media/svg/navigation/sports/squash.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/formula-1",
		title: "Formula 1",
		icon: "/media/svg/navigation/sports/formula-1.svg",
		iconSize: returnIconSize(19, 19),
	},
	{
		path: "/sports/biathlon",
		title: "Biathlon",
		icon: "/media/svg/navigation/sports/biathlon.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/sports/counter-strike",
		title: "Counter Strike",
		icon: "/media/svg/navigation/sports/counter-strike.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/sports/league-of-legends",
		title: "League of Legends",
		icon: "/media/svg/navigation/sports/league-of-legends.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/dota-2",
		title: "Dota 2",
		icon: "/media/svg/navigation/sports/dota-2.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/starcraft",
		title: "Starcraft",
		icon: "/media/svg/navigation/sports/starcraft.svg",
		iconSize: returnIconSize(22, 22),
	},
	{
		path: "/sports/mma",
		title: "MMA",
		icon: "/media/svg/navigation/sports/mma.svg",
		iconSize: returnIconSize(18, 18),
	},
	{
		path: "/sports/motorcycle-racing",
		title: "Motorcycle Racing",
		icon: "/media/svg/navigation/sports/motorcycle-racing.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/sports/stock-car-racing",
		title: "Stock Car Racing",
		icon: "/media/svg/navigation/sports/stock-car-racing.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/sports/rocket-league",
		title: "Rocket League",
		icon: "/media/svg/navigation/sports/rocket-league.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/sports/nba-2k",
		title: "NAB 2K19",
		icon: "/media/svg/navigation/sports/nba-2k.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/sports/ao-tennis-2",
		title: "AO Tennis 2",
		icon: "/media/svg/navigation/sports/ao-tennis-2.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/sports/efighting",
		title: "eFighting",
		icon: "/media/svg/navigation/sports/efighting.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/sports/ecricket",
		title: "eCricket",
		icon: "/media/svg/navigation/sports/ecricket.svg",
		iconSize: returnIconSize(20, 20),
	},
];

const MENU_ITEM_NFT = [
	{
		path: "/nft/my-nft",
		subPath: "portfolio",
		title: "My NFTs",
		icon: "/media/svg/navigation/nft/nft.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/nft/loans",
		subPath: "loans",
		title: "NFT Loans",
		icon: "/media/svg/navigation/nft/loans.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		path: "/nft/marketplace",
		subPath: "marketplace",
		title: "Marketplace",
		icon: "/media/svg/navigation/nft/marketplace.svg",
		iconSize: returnIconSize(16, 14),
	},
	{
		path: "/nft/lootboxes/play",
		subPath: "lootboxes/play",
		title: "NFT Lootboxes",
		icon: "/media/svg/navigation/nft/lootboxes.svg",
		iconSize: returnIconSize(20, 16),
	},
	{
		path: "/nft/rollbot/portfolio",
		subPath: "rollbot/portfolio",
		title: "Manage Rollbots",
		icon: "/media/svg/navigation/nft/rollbot.svg",
		iconSize: returnIconSize(16, 16),
	},
	{
		path: "/nft/sportsbot/portfolio",
		subPath: "sportsbot/portfolio",
		title: "Manage Sportsbots",
		icon: "/media/svg/navigation/nft/rollbot.svg",
		iconSize: returnIconSize(16, 16),
	},
];

export const NAVIGATIONS = [
	// {
	// 	type: NAVIGATION_TYPES.SIDEBAR_GROUP,
	// 	component: (
	// 		<SidebarMenuGroup
	// 			title="main menu"
	// 			className="spaces pt-10"
	// 			// component={<SidebarToggleButton />}
	// 		/>
	// 	),
	// },
	// {
	// 	type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	// 	path: "/inscribe",
	// 	title: "Inscribe",
	// 	icon: "/media/svg/navigation/inscribe.svg",
	// 	iconSize: returnIconSize(16, 16),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDEBAR_WITH_SUB,
	//     path: '/casino',
	//     title: 'Casino',
	//     icon: '/media/svg/navigation/casino/casino.svg',
	//     iconSize: returnIconSize(17, 17),
	//     menuItems: MENU_ITEM_CASINO,
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDEBAR_WITH_SUB,
	//     path: '/sports',
	//     title: 'Sports',
	//     icon: '/media/svg/navigation/sports/sport.svg',
	//     iconSize: returnIconSize(17, 17),
	//     menuItems: MENU_ITEM_SPORTS,
	// },
	// {
	// 	type: NAVIGATION_TYPES.SIDEBAR_WITH_SUB,
	// 	path: "/nft/lobby",
	// 	title: "NFT",
	// 	icon: "/media/svg/navigation/nft/nft.svg",
	// 	iconSize: returnIconSize(20, 20),
	// 	menuItems: MENU_ITEM_NFT,
	// },
	{
		type: NAVIGATION_TYPES.SIDEBAR_GROUP,
		component: <SidebarMenuGroup title="BRC-20" />,
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "/inscribe",
		title: "Inscribe",
		icon: "/media/svg/navigation/inscribe.svg",
		iconSize: returnIconSize(16, 16),
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "/marketplace",
		title: "Marketplace",
		icon: "/media/svg/navigation/nft/marketplace.svg",
		iconSize: returnIconSize(16, 14),
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "/?tab=collections",
		title: "Collections",
		icon: "/media/svg/navigation/nft/marketplace.svg",
		iconSize: returnIconSize(16, 14),
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "/marketplace/funs",
		title: "Trade FUNS",
		icon: "/media/svg/navigation/rollbit-coin/Trade RLB.svg",
		iconSize: returnIconSize(12, 20),
	},
	{
		type: NAVIGATION_TYPES.SIDEBAR_GROUP,
		component: <SidebarMenuGroup title="RUNE" />,
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "#",
		title: "Comming soon",
		iconSize: returnIconSize(12, 20),
	},
	{
		type: NAVIGATION_TYPES.SIDEBAR_GROUP,
		component: <SidebarMenuGroup title="funbit coin" />,
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "/fun/trade",
		title: "Trade FUNBIT",
		icon: "/media/svg/navigation/rollbit-coin/Trade RLB.svg",
		iconSize: returnIconSize(12, 20),
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "/fun/market-cap",
		title: "Market Cap",
		icon: "/media/svg/navigation/rollbit-coin/buy-burn.svg",
		iconSize: returnIconSize(13, 18),
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "/fun/holdings",
		title: "Holdings",
		icon: "/media/svg/general/coins.svg",
		iconSize: returnIconSize(14, 13),
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "/fun/pools",
		title: "Pool",
		icon: "/media/svg/navigation/rollbit-coin/pool.svg",
		iconSize: returnIconSize(20, 20),
	},
	{
		type: NAVIGATION_TYPES.SIDEBAR_GROUP,
		component: <SidebarMenuGroup title="More" />,
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "https://launchpad.funbit.network",
		title: "Launchpad",
		icon: "/media/svg/general/link.svg",
		iconSize: returnIconSize(17, 17),
		target: "_blank",
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_ITEM,
		path: "https://funbit.exchange",
		title: "Exchange",
		icon: "/media/svg/general/coin.svg",
		iconSize: returnIconSize(17, 17),
		target: "_blank",
	},
	{
		type: NAVIGATION_TYPES.SIDERBAR_TRADING,
		path: "/trading",
		title: "Crypto Futures",
		icon: "/media/svg/navigation/crypto-futures.svg",
		iconSize: returnIconSize(15, 15),
		menuItems: MENU_ITEM_NFT,
	},

	// {
	// 	type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	// 	path: "/fun/my-wallets/deposit",
	// 	title: "My Wallet",
	// 	icon: "/media/svg/icons/wallet.svg",
	// 	iconSize: returnIconSize(16, 16),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	//     path: '/fun/lottery/current',
	//     title: 'FUNBIT Lottery · $1.3K',
	//     icon: '/media/svg/navigation/rollbit-coin/RLB Lottery.svg',
	//     iconSize: returnIconSize(16, 16),
	// },

	// {
	// 	type: NAVIGATION_TYPES.SIDEBAR_GROUP,
	// 	component: <SidebarMenuGroup title="Marketplace" />,
	// },
	// {
	// 	type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	// 	path: "/marketplace",
	// 	title: "Marketplace",
	// 	icon: "/media/svg/navigation/nft/marketplace.svg",
	// 	iconSize: returnIconSize(16, 14),
	// },
	// {
	// 	type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	// 	path: "/detailCoin",
	// 	title: "Detail Coin",
	// 	icon: "/media/icons/duotune/finance/fin004.svg",
	// 	iconSize: returnIconSize(24, 24),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDEBAR_GROUP,
	//     component: <SidebarMenuGroup title='sponsorships' />
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	//     path: '/A16Z',
	//     title: 'A16Z',
	//     icon: '/media/svg/general/coin.svg',
	//     iconSize: returnIconSize(17, 17),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	//     path: '/binance-labs',
	//     title: 'Binance Labs',
	//     icon: '/media/svg/general/coin.svg',
	//     iconSize: returnIconSize(17, 17),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	//     path: '/Funbit',
	//     title: 'Funbit.io',
	//     icon: '/media/svg/general/coin.svg',
	//     iconSize: returnIconSize(17, 17),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	//     path: '/ZK-Sync',
	//     title: 'ZK Sync',
	//     icon: '/media/svg/general/coin.svg',
	//     iconSize: returnIconSize(17, 17),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDEBAR_GROUP,
	//     component: <SidebarMenuGroup title='more' />
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	//     path: '/jackpot',
	//     title: 'Jackpot · $118',
	//     icon: '/media/svg/navigation/Jackpot.svg',
	//     iconSize: returnIconSize(30, 30),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	//     path: '/races/daily',
	//     title: '$25K Race',
	//     icon: '/media/svg/navigation/Race.svg',
	//     iconSize: returnIconSize(15, 16),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	//     path: "referral",
	//     title: "Referral",
	//     icon: "/media/svg/general/link.svg",
	//     iconSize: returnIconSize(17, 17),
	// },
	// {
	//     type: NAVIGATION_TYPES.SIDERBAR_ITEM,
	//     path: 'staking',
	//     title: 'Staking',
	//     icon: '/media/svg/general/coin.svg',
	//     iconSize: returnIconSize(17, 17),
	// },
];
