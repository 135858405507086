import { Tab, Tabs } from "react-bootstrap";
import "./CustomTabs.scss";
import { KTSVG } from "../../../helpers";

const CustomTabs = ({ listTab, className }: any) => {
	return (
		<Tabs className={className}>
			{listTab?.map((tab: any) => (
				<Tab
					key={tab?.label?.name}
					title={
						<div className="d-flex align-items-center" onClick={tab.onClick}>
							<KTSVG
								svgClassName={tab?.icon?.className}
								path={tab?.icon?.path}
							/>
							<div className={tab.label?.className}>{tab?.label?.name}</div>
							{tab?.dropdown && (
								<KTSVG
									svgClassName="spaces h-6 ml-6"
									path="/media/svg/action/action-02.svg"
								/>
							)}
						</div>
					}
					eventKey={tab?.eventKey}
				>
					<div>{tab?.component}</div>
				</Tab>
			))}
		</Tabs>
	);
};

export default CustomTabs;
