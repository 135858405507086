import clsx from "clsx";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import TableCustom from "../../../components/table/TableCustom";

const FunBenefitsModal = ({ handleClose }: any) => {
	const columns = [
		{
			name: "FUNBIT HOLDINGS VALUE",
			field: "value",
			classHeader: "text-start",
			classCell: "text-start spaces fs-14",
		},
		{
			name: "TRADING FEE DISCOUNT",
			field: "discount",
			classHeader: "text-end",
			classCell: "text-end spaces fs-14",
		},
		{
			name: "FUNBIT RAKEBACK",
			field: "rakeBack",
			classHeader: "text-end",
			classCell: "text-end spaces fs-14",
		},
	];

	const dataTable = [
		{
			value: "$500K",
			discount: "0%",
			rakeBack: "10%",
		},
		{
			value: "$250K",
			discount: "0%",
			rakeBack: "9%",
		},
		{
			value: "$100K",
			discount: "0%",
			rakeBack: "8%",
		},
		{
			value: "$50K",
			discount: "0%",
			rakeBack: "7%",
		},
		{
			value: "$25K",
			discount: "0%",
			rakeBack: "6%",
		},
		{
			value: "$10K",
			discount: "0%",
			rakeBack: "5%",
		},
		{
			value: "$5K",
			discount: "0%",
			rakeBack: "4%",
		},
		{
			value: "$2.5K",
			discount: "0%",
			rakeBack: "3%",
		},
		{
			value: "$1K",
			discount: "0%",
			rakeBack: "2%",
		},
		{
			value: "$100",
			discount: "0%",
			rakeBack: "1%",
		},
	];

	return (
		<Modal
			contentClassName={clsx("modal-wrapper spaces max-w-600")}
			show={true}
			size="lg"
			onHide={handleClose}
		>
			<div className="btn-exit">
				<div className="exit-icon" onClick={handleClose}>
					<KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
				</div>
			</div>
			<div className={clsx("modal-title")}>Fun Benefits</div>
			<div className="modal-content">
				<div className="text-color-gray spaces fs-14 pb-12">
					<p>
						The extent of benefits you receive on Funbit is directly
						proportional to the amount of fun you hold, multiplied by fun market
						price.
					</p>
					<p>
						Current fun Holdings Value:{" "}
						<span className="text-color-white">$0.00</span>
					</p>
				</div>
				<TableCustom columns={columns} data={dataTable} />
			</div>
		</Modal>
	);
};

export default FunBenefitsModal;
