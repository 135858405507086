import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { TickersInfo } from "../../../../../types/marketplace-service";
import FunbitService from "../../../../services/funbitServices";
import { toNumberFormat } from "../../../../utils";
import SubNavigation from "../../../components/sub-navigation/SubNavigation";
import { dataDetailCoin } from "../../MarketPlaceConst";
import "../../marketplace.scss";
type Props = {};

const InfoDetailCoin = (props: Props) => {
	const { tick } = useParams();

	const { data } = useQuery<TickersInfo, Error>({
		queryKey: ["fetchInfoTicker", { tick }],
		queryFn: () => FunbitService.fetchInfoTicker(tick || ""),
		enabled: !!tick,
	});

	const listData = dataDetailCoin.map((item) => ({
		...item,
		value: item.isNumber
			? toNumberFormat((data as any)?.[item.field])
			: item.field === "deploy_time" || item.field === "complete_time"
			? moment((data as any)?.[item.field]).format("hh:mm:ss DD/MM/YYYY")
			: (data as any)?.[item.field],
	}));

	return (
		<div className="info-detail-coin-container">
			<div className="flex-row-between align-items-center spaces mb-20">
				<div className="display-domain text-uppercase">{tick}</div>
				<div className="progress-status">
					{(+data?.max! || 0) - (+data?.minted! || 0) <= 0
						? "Completed"
						: "Minting"}
				</div>
			</div>
			<div className="d-flex align-items-center spaces gap-16 mb-20">
				{/* <OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={
						<Tooltip id="button-tooltip" {...props}>
							<div className="detail-item spaces">
								<div className="item-title">Progress :</div>
								<div className={"item-value"}>
									1,000,000,000,000/1,000,000,000,000
								</div>
								<div className="item-title">Confirmed between 24h :</div>
								<div className={"item-value text-color-green"}>
									1,000,000,000,000
								</div>
								<div className="item-title">Confirmed between 1h and 24h :</div>
								<div className={"item-value text-primary"}>0</div>
								<div className="item-title">Confirmed in 1h :</div>
								<div className={"item-value text-warning"}>0</div>
								<div className="item-title">In memory pool :</div>
								<div className={"item-value text-danger"}>0</div>
							</div>
						</Tooltip>
					}
				>
					<ProgressBar
						className="flex-grow-1 spaces h-8"
						variant="success"
						now={((+data?.minted! || 0) / (+data?.max! || 0)) * 100}
					/>
				</OverlayTrigger> */}
				<div className="spaces fs-16 mr-8">
					{(((+data?.minted! || 0) / (+data?.max! || 0)) * 100).toFixed(2)}%
				</div>
			</div>

			<div className="info-detail-box">
				<div className="info-detail-header">
					<div className="head-title">Overview</div>
					<div className="button-box">
						<SubNavigation
							navigations={[
								{
									label: "View",
									toPath: `/brc20/${tick}`,
								},
								{
									label: "Trade",
									toPath: `/marketplace/${tick}`,
								},
							]}
						/>
					</div>
				</div>
				<div className="info-detail-body">
					{listData.map((item, index) => (
						<>
							<div className="detail-item">
								<div className="item-title">{item.title} :</div>
								<div className={`item-value ${item.isLink ? "link" : ""}`}>
									{item.value}
								</div>
							</div>
						</>
					))}
				</div>
			</div>
			{/* <div className="info-detail-box spaces mt-32">
        <CustomTabsV2 className="info-detail-tab" listTab={COIN_DETAIL_TAB} />
        <div className="text-center">
          <Panination />
        </div>
      </div> */}
		</div>
	);
};

export default InfoDetailCoin;
