import React from "react";
import "./Referral.scss";
import { KTSVG } from "../../../_metronic/helpers";
import TableCustom from './../components/table/TableCustom';
import CardCustom from "./components/cardCustom/CardCustom";
import { CustomTabsV2 } from './../../../_metronic/layout/components/tabs/CustomTabsV2';
import { SUB_NAVIGATIONS_EARNING, SUB_NAVIGATIONS_RANKING, columns, tableData } from "./ReferralConst";
import TablePagination from "../components/table/TablePagination";

export default function ReferralPage() {

  return (
    <div>
      <div className="referral-banner">
        <div className="container">
          <div className="referral-section">
            <div className="referral-banner-title">
              <div className="referral-banner-headline">
                Invite your friends to earn crypto on OCEANTECH!
              </div>
            </div>
            <div className="referral-steps-container">
              <div className="referral-step-entry">
                <div className="step-number">1</div>
                <div>
                  <div className="step-title">Invite Friends</div>
                  <div className="step-desc">Invite your friends to sign up via code or link</div>
                </div>
              </div>
              <div className="referral-step-entry">
                <div className="step-number">2</div>
                <div>
                  <div className="step-title">Invitee Registers &amp; Trades</div>
                  <div className="step-desc">
                    Friends complete registration and start trading at OCT
                  </div>
                </div>
              </div>
              <div className="referral-step-entry">
                <div className="step-number">3</div>
                <div>
                  <div className="step-title">Earn Referrals</div>
                  <div className="step-desc">
                    Get referral fees each time your friends trade on OCT
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="referral-action-container mb-5">
        <div className="container">
          <div className="referral-section">
            <div className="referral_section_left referral-section">
              <div className="referral_section_left_1">
                <div className="spaces fs-12 fw-4">Invitation Code</div>
                <div className="spaces fs-22 fw-4">
                  <span>dcB46x</span>
                </div>
                <div className="btn-primary-outline cp-btn spaces mt-16">
                  <div className="btn-text cp-ori">Copy Code</div>{" "}
                  <KTSVG path="/media/svg/action/copy.svg" />
                </div>
              </div>
              <div className="referral_section_left_2">
                <div className="or-bar-line"></div>
                <div>or</div>
                <div className="or-bar-line"></div>
              </div>
              <div className="referral_section_left_3">
                <div className="spaces fs-12 fw-4">Invitation Link</div>
                <div className="spaces fs-14 fw-4 mt-4">
                  <span>https://sure9....dcB46x</span>
                </div>
                <div className="btn-primary-outline cp-btn spaces mt-16">
                  <div className="btn-text cp-ori">Copy Link</div>
                  <KTSVG path="/media/svg/action/copy.svg" />
                </div>
              </div>
            </div>
            <div className="referral_section_right">
              <div className="spaces fs-12 fw-4 mb-4">Total Earnings</div>
              <div className="referral-stats">
                <KTSVG path="/media/svg/general/earns.svg" />
                <span className="spaces fs-22 fw-4">800 USDT</span>
              </div>
              <div className="spaces fs-12 fw-4 mt-12 mb-4">Friend(s) Invited</div>

              <div className="referral-stats">
                <KTSVG path="/media/svg/general/friend.svg" />
                <span className="spaces fs-22 fw-4">22</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardCustom title="Invitation Record">
        <TableCustom className="table-content" columns={columns} data={tableData} />
        <TablePagination />
      </CardCustom>
      <CardCustom title="Earnings History">
        <CustomTabsV2 className="referral-tab" listTab={SUB_NAVIGATIONS_EARNING} />
        <TablePagination />
      </CardCustom>
      <CardCustom title="Ranking Tier">
        <CustomTabsV2 className="referral-tab" listTab={SUB_NAVIGATIONS_RANKING} />
        <TablePagination />
      </CardCustom>
    </div>
  );
}
