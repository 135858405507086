import { useState } from "react";
import ButtonCustom from "../../components/button/ButtonCustom";
import ConnectWalletModal from "../../components/modal/connect-wallet/ConnectWalletModal";
import { useQuery } from "react-query";
import FunbitService from "../../../services/funbitServices";
import TableCustom from "../../components/table/TableCustom";
import moment from "moment";
import { useWallet } from "../../../../providers/wallet_provider";

const columns = [
	{
		name: "Order ID",
		field: "orderId",
		classHeader: "text-start text-capitalize",
		classCell: "text-start text-capitalize spaces fw-5 fs-14",
	},
	{
		name: "Quantity",
		field: "quantity",
		classHeader: "text-start text-capitalize",
		classCell: "text-start text-capitalize spaces fw-5 fs-14",
	},
	{
		name: "Points",
		field: "points",
		classHeader: "text-start text-capitalize",
		classCell: "text-start text-capitalize spaces fw-5 fs-14",
	},
	{
		name: "Status",
		field: "status",
		classHeader: "text-start text-capitalize",
		classCell: "text-start text-capitalize spaces fw-5 fs-14",
	},
	{
		name: "Date",
		field: "date",
		classHeader: "text-start text-capitalize",
		classCell: "text-start text-capitalize spaces fw-5 fs-14",
	},
];

const MyOrders = () => {
	const [shouldOpenConnectWalletModal, setShouldOpenConnectWalletModal] =
		useState(false);
	const { account } = useWallet();
	const [dataTable, setDataTable] = useState([]);
	useQuery(
		["my_orders", account],
		() => FunbitService.getListInscribeOrderByAddress(account),
		{
			onSuccess: ({ data }) => {
				const { list } = data.data;
				const tbdata = list.map((item: any) => ({
					orderId: item?.id,
					quantity: item?.files?.length,
					points: item?.point || item?.files?.length,
					status: item?.status || "closed",
					date: moment(item?.created_at || "12/02/2024").format(
						" h:mm:ss DD/MM/YYYY"
					),
				}));
				setDataTable(tbdata);
			},
			enabled: !!account,
		}
	);

	return (
		<>
			{account ? (
				<div>
					<TableCustom columns={columns} data={dataTable} />
				</div>
			) : (
				<div className="text-color-white">
					<div>Connect wallet to continue</div>
					<ButtonCustom
						label="Connect"
						btnClassName="button button-yellow spaces mt-12"
						handleClick={() => setShouldOpenConnectWalletModal(true)}
					/>
				</div>
			)}
			{shouldOpenConnectWalletModal && (
				<ConnectWalletModal
					handleClose={() => setShouldOpenConnectWalletModal(false)}
				/>
			)}
		</>
	);
};

export default MyOrders;
