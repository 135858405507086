/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { KTSVG } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import { Modal } from "react-bootstrap";
import './FormAccount.scss'
import ButtonCustom from "../../components/button/ButtonCustom";
import { UserModel } from "../core/_models";
const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  username: "",
  password: "",
  email :"",
  code:""
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
type Props = {
  handleClose: () => void;
  loginMode: boolean;
};
export function Login(props:Props) {
  const { handleClose, loginMode } = props;
  // const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // setLoading(true);
      try {
        const { data: auth } = await login(values.username, values.password);
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.access_token);
        setCurrentUser(user);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        // setLoading(false);
      }
    },
  });
  
  const [isLogin, setIsLogin] = useState(loginMode);
  const [isTFA,setIsTFA]= useState(false);
  const changeModeLogin = () => {
    setIsLogin(true);
    setIsTFA(false)
  };
  const changeModeRegister = () => {
    setIsLogin(false);
    setIsTFA(false)
  };
  useEffect(()=>{
    // formik.handleReset({})
  },[formik, isLogin])

  return (
    <Modal show centered size="xl" contentClassName="modal-login" onHide={handleClose}>
      <div onClick={handleClose}>
        <KTSVG
          className="btn-exit"
          svgClassName="spaces h-20 h-20"
          path="/media/svg/action/exit.svg"
        />
      </div>
      <div className="d-flex modal-content-body">
        <div className="form-account-right text-grey-1">
          <div className="tab">
            {/* <ButtonCustom
              handleClick={changeModeLogin}
              btnClassName={clsx("button spaces px-16 p-0 button-grey-tab", {
                "button-grey-tab-active": isLogin,
              })}
              label="Login"
            />
            <ButtonCustom
              handleClick={changeModeRegister}
              btnClassName={clsx("button spaces px-16 p-0 button-grey-tab", {
                "button-grey-tab-active": !isLogin,
              })}
              label="Register"
            /> */}
          </div>
          <div className="form">
            {!isLogin && (
              <div className="fv-row spaces mb-24">
                <label className="label-custom spaces mb-10 ">
                  Username <span>*</span>
                </label>
                <input
                  {...formik.getFieldProps("username")}
                  placeholder="Username"
                  className={clsx(
                    "form-control bg-transparent spaces h-40",
                    { "is-invalid": formik.touched.username && formik.errors.username },
                    {
                      "is-valid": formik.touched.username && !formik.errors.username,
                    }
                  )}
                  type="text"
                  name="username"
                  autoComplete="off"
                />
              </div>
            )}
            <div className="fv-row spaces mb-24">
              <label className="label-custom spaces mb-10 ">
                Email <span>*</span>
              </label>
              <input
                {...formik.getFieldProps("email")}
                placeholder="youremail@domain.com"
                className={clsx(
                  "form-control bg-transparent spaces h-40",
                  { "is-invalid": formik.touched.email && formik.errors.email },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email,
                  }
                )}
                type="text"
                name="email"
                autoComplete="off"
              />
            </div>
            <div className="fv-row spaces mb-24">
              <label className="label-custom spaces mb-10 ">
                Password
                <span>*</span>
              </label>
              <input
                {...formik.getFieldProps("password")}
                placeholder="**********"
                className={clsx(
                  "form-control bg-transparent spaces h-40",
                  { "is-invalid": formik.touched.password && formik.errors.password },
                  {
                    "is-valid": formik.touched.password && !formik.errors.password,
                  }
                )}
                type="text"
                name="password"
                autoComplete="off"
              />
              <div className="spaces mt-8">Forgot password?</div>
            </div>
            {isLogin && (
              <div className="fv-row spaces mb-24">
                <label
                  className="label-custom spaces mb-10 d-flex align-items-center gap-1 cursor-pointer "
                  onClick={() => {
                    setIsTFA(true);
                  }}
                >
                  2FACODE
                  {!isTFA && (
                    <KTSVG svgClassName="spaces h-6 w-8" path="/media/svg/action/action-02.svg" />
                  )}
                </label>
                {isTFA && (
                  <input
                    placeholder="Code"
                    {...formik.getFieldProps("code")}
                    className={clsx(
                      "form-control bg-transparent spaces h-40",
                      { "is-invalid": formik.touched.code && formik.errors.code },
                      {
                        "is-valid": formik.touched.code && !formik.errors.code,
                      }
                    )}
                    type="text"
                    name="code"
                    autoComplete="off"
                  />
                )}
                <div className="spaces mt-8">Required if you enabled two-factor authentication</div>
              </div>
            )}
          </div>
          <div>
            This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service
            apply.
          </div>
          <ButtonCustom
            handleClick={() => {
              setCurrentUser({} as UserModel);
              handleClose();
            }}
            btnClassName="button button-green spaces my-24 h-48 w-100"
            label={isLogin ? "Login" : "Start playing"}
            labelClassName=""
          />
          <div className="spaces fs-14 text-center mb-16">Or continue with</div>
          <div className="other-login">
            {/* <ButtonCustom
              btnClassName="button button-grey spaces px-16"
              svgClassName="spaces w-20 h-20 mr-8"
              labelClassName="spaces fs-14 fw-5 text-capitalize"
              label="Steam"
              iconPath="/media/svg/general/steam.svg"
            />
            <ButtonCustom
              btnClassName="button button-grey spaces px-16"
              svgClassName="spaces w-19 h-20 mr-8"
              labelClassName="spaces fs-14 fw-5 text-capitalize"
              label="Twitch"
              iconPath="/media/svg/general/twitch.svg"
            />
            <ButtonCustom
              btnClassName="button button-grey spaces px-16"
              svgClassName="spaces w-18 h-18 mr-8"
              labelClassName="spaces fs-14 fw-5 text-capitalize"
              label="Twitch"
              iconPath="/media/svg/general/metamask.svg"
            /> */}
            <ButtonCustom btnClassName="button button-green w-100 spaces h-48" label="Connect Wallet"/>
          </div>
        </div>
        <div className="form-account-left"></div>
      </div>
    </Modal>
  );
}
