import { ConfigProvider, Tabs } from "antd";
import ButtonCustom from "../../components/button/ButtonCustom";
import { KTSVG } from "../../../../_metronic/helpers";
import CheckBox from "../../components/text-field/CheckBox";
import TextField from "../../components/text-field/TextField";
import TextareaCustom from "../../components/textarea/TextareaCustom";
import { useState } from "react";
import SelectNetworkFee from "./SelectNetworkFee";
import { stringToDataURL } from "../../../utils";
import useCreateOrder from "../../../hooks/useCreateOrder";
import InscriblingOrderModal from "./InscriblingOrderModal";
import RiskWarningModal from "./RiskWarningModal";
import { useWallet } from "../../../../providers/wallet_provider";
const encoder = new TextEncoder();

const InscribingOrderStep = ({
	handlePrevStep,
	inscribeList,
	inscriptionKind,
}: any) => {
	const [satList, setSatList] = useState<any>(inscribeList);
	const [addressTabActiveKey, setAddressTabActiveKey] = useState("1");
	const { account } = useWallet();
	const [isRiskWarning, setIsRiskWarning] = useState<boolean>(false);
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const { mutate, data } = useCreateOrder({
		onSuccess: () => {
			setIsOpenModal(true);
		},
	});
	const [inscriptionInfo, setInscriptionInfo] = useState({
		receiveAddress: account || "",
		feeRate: 1,
		agreedTerm: false,
		files: [],
		outputValue: 546,
	});

	const handleRemoveSat = (index: number) => {
		let satListTemp = satList;
		satListTemp.splice(index, 1);
		!(satListTemp.length > 0) && handlePrevStep();
		setSatList([...satListTemp]);
	};

	const addressTabs = [
		{
			key: "1",
			label: "To Single Address",
			children: (
				<TextField
					placeholder="Provide the address to receive the inscription"
					value={inscriptionInfo.receiveAddress}
					setValue={(value: string) => {
						setInscriptionInfo((prev) => ({
							...prev,
							receiveAddress: value,
						}));
					}}
				/>
			),
		},
		{
			key: "2",
			label: "To Multiple Address",
			children: (
				<TextareaCustom
					rows={4}
					placeholder="aaa or aaa.funbit"
					value={""}
					handleChange={() => {}}
				/>
			),
			disabled: !(satList.length > 1),
		},
	];

	const onChangeInscriptionInfo = (data: {
		feeRate: number;
		outputValue: number;
	}) => {
		setInscriptionInfo((prev) => ({
			...prev,
			...data,
		}));
	};

	const createOrder = () => {
		if (inscriptionInfo.agreedTerm) {
			const files: { filename: any; dataURL: string }[] = [];
			satList.forEach((sat: any) => {
				files.push({
					filename: sat,
					dataURL: stringToDataURL(sat),
				});
			});
			const order = {
				receiveAddress: inscriptionInfo.receiveAddress,
				feeRate: inscriptionInfo.feeRate,
				outputValue: inscriptionInfo.outputValue,
				files: files,
				inscription_kind: inscriptionKind,
			};

			mutate(order);
		} else {
			setIsRiskWarning(true);
		}
	};

	const handleAgreedBtn = () => {
		setInscriptionInfo((prev) => ({
			...prev,
			agreedTerm: true,
		}));
		setIsRiskWarning(false);
		const files: { filename: any; dataURL: string }[] = [];
		satList.forEach((sat: any) => {
			files.push({
				filename: sat,
				dataURL: stringToDataURL(sat),
			});
		});
		const order = {
			receiveAddress: inscriptionInfo.receiveAddress,
			feeRate: inscriptionInfo.feeRate,
			outputValue: inscriptionInfo.outputValue,
			files: files,
		};

		mutate(order);
	};

	return (
		<>
			<div className="d-flex align-items-center justify-content-between w-100">
				<div className="spaces fs-16 fw-500 text-color-white">
					{satList && satList.length > 0 ? `${satList.length} Items` : ""}
				</div>
				<ButtonCustom
					label="remove all"
					btnClassName="button button-grey spaces px-14 spaces h-29 min-h-29"
					handleClick={handlePrevStep}
				/>
			</div>
			<div className="file-upload-container">
				{satList && satList.length > 0 && (
					<div className="file-upload-list">
						{satList?.map((satItem: any, index: number) => (
							<div className="file-upload-item" key={index}>
								<div className="d-flex align-items-center spaces gap-10 right-content">
									<div className="index">{index + 1}</div>
									<div className="file-name">{satItem}</div>
								</div>
								<div className="d-flex align-items-center spaces gap-10">
									<div className="file-size">
										{encoder.encode(satItem).length} KB
									</div>
									<div
										className="remove-file-icon"
										onClick={() => handleRemoveSat(index)}
									>
										<KTSVG
											svgClassName="spaces h-16 h-16"
											path="/media/svg/icons/close.svg"
										/>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
			<div className="w-100">
				<ConfigProvider
					theme={{
						components: {
							Tabs: {
								inkBarColor: "#ffb018",
								itemColor: "#b1b6c6",
								itemHoverColor: "#ffb018",
								itemSelectedColor: "#ffb018",
							},
						},
						token: {
							colorBorderSecondary: "#888888",
							colorTextDisabled: "#b1b6c6",
						},
					}}
				>
					<Tabs
						defaultActiveKey={addressTabActiveKey}
						items={addressTabs}
						activeKey={addressTabActiveKey}
						onChange={(key) => setAddressTabActiveKey(key)}
					/>
				</ConfigProvider>
			</div>
			<SelectNetworkFee
				satList={satList.map((satItem: any) => ({
					filename: satItem,
					dataURL: stringToDataURL(satItem),
				}))}
				receiveAddress={inscriptionInfo.receiveAddress}
				feeRate={inscriptionInfo.feeRate}
				outputValue={inscriptionInfo.outputValue}
				handleChangeInscriptionInfo={onChangeInscriptionInfo}
			/>
			<div className="d-flex align-items-center spaces mt-16 gap-10 w-100">
				<div>
					<KTSVG
						svgClassName="spaces h-16 h-16"
						path="/media/svg/general/info.svg"
						color="#eeff00"
					/>
				</div>
				<div>
					Please note the inscribing transaction delivers the inscription to the
					receiving address directly.
				</div>
			</div>
			<div className="w-100 spaces mt-16">
				<CheckBox
					label={<>I have read and agreed to the risk warning</>}
					labelClassName="text-lowercase"
					onChange={(value) =>
						setInscriptionInfo((prev) => ({ ...prev, agreedTerm: value }))
					}
				/>
			</div>
			<div className="spaces mt-16">
				<ButtonCustom
					handleClick={createOrder}
					label="Submit & Pay invoice"
					btnClassName="button button-yellow"
				/>
			</div>
			{isOpenModal && (
				<InscriblingOrderModal
					handleClose={() => setIsOpenModal((prev) => !prev)}
					isShow={isOpenModal}
					orderId={data?.id}
				/>
			)}
			<RiskWarningModal
				handleClose={() => setIsRiskWarning(false)}
				handleAgreedBtn={handleAgreedBtn}
				isShowBtn={!inscriptionInfo.agreedTerm}
				isShowModal={isRiskWarning}
			/>
		</>
	);
};

export default InscribingOrderStep;
