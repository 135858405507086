import { useEffect, useState } from "react";
import CustomPopover from "../../components/custom-popover/CustomPopover";
import PopoverNameContent from "./PopoverNameContent";
import { KTSVG } from "../../../../_metronic/helpers";

type TProps = {
	handleTabData?: (data: any) => void;
	isTabActive?: boolean;
};

const NamesPopover = ({ handleTabData, isTabActive }: TProps) => {
	const tickChooseList = [
		{
			key: "1",
			tickName: "sats",
			tickLink: "https://docs.sats.id/sats-names/about",
			linkName: "docs.sats.id",
		},
		{
			key: "2",
			tickName: "funbit",
			tickLink: "https://docs.sats.id/sats-names/about",
			linkName: "docs.sats.id",
		},
		{
			key: "3",
			tickName: "btc",
			tickLink: "https://docs.btcname.id/docs/",
			linkName: "docs.sats.id",
		},
		{
			key: "4",
			tickName: "xbt",
			tickLink: "https://docs.sats.id/sats-names/about",
			linkName: "docs.sats.id",
		},
		{
			key: "5",
			tickName: "ord",
			tickLink: "https://docs.ord.im/ord.im/welcome-to-ons",
			linkName: "docs.sats.id",
		},
		{
			key: "6",
			tickName: "gm",
			tickLink: "https://docs.sats.id/sats-names/about",
			linkName: "docs.sats.id",
		},
		{
			key: "7",
			tickName: "x",
			tickLink: "https://docs.sats.id/sats-names/about",
			linkName: "docs.sats.id",
		},
	];

	const [shouldOpenPopover, setShouldOpenPopover] = useState<boolean>(false);
	const [tabInfo, setTabInfo] = useState<any>("");

	const handleHidePopover = () => {
		setShouldOpenPopover(false);
	};

	const handleOpenChange = (newOpen: boolean) => {
		setShouldOpenPopover(newOpen);
	};

	useEffect(() => {
		handleTabData && handleTabData(tabInfo);
	}, [tabInfo]);

	return (
		<div className="names-popover-container">
			<CustomPopover
				open={shouldOpenPopover}
				handleOpenChange={handleOpenChange}
				content={
					<PopoverNameContent
						tickChooseList={tickChooseList}
						handleHidePopover={handleHidePopover}
						changeTabLabel={(label: string) => setTabInfo(label)}
					/>
				}
				children={
					<div className={`nav-content ${isTabActive && "active"}`}>
						<div className={`nav-label d-flex align-items-center`}>
							{tabInfo ? tabInfo?.tickName : "Names"}
							<KTSVG
								className={`transitionable ${shouldOpenPopover && "icon-item"}`}
								svgClassName="spaces w-12 h-12"
								path="/media/svg/icons/arrow-down-outline.svg"
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default NamesPopover;
