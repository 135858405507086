import { Modal } from "react-bootstrap";
import ButtonCustom from "../../button/ButtonCustom";
import clsx from "clsx";
import { KTSVG } from "../../../../../_metronic/helpers";
import "../modal.scss";
import { WALLET } from "../../../../utils";
import { useWallet } from "../../../../../providers/wallet_provider/index";

const WALLET_LIST = [
	// {
	// 	imgPath: "/media/images/connect-wallet/xverse_icon_whitecolor.svg",
	// 	title: "Xverse Wallet",
	// },
	{
		imgPath: "https://next-cdn.unisat.io/_/150/logo/color.svg",
		title: "Unisat Wallet",
		type: WALLET.UNISAT,
	},
	{
		imgPath: "/media/images/connect-wallet/bitget.png",
		title: "Bitget Wallet",
		type: WALLET.BITGET,
	},
	// {
	// 	imgPath: "/media/images/connect-wallet/phantom.jpg",
	// 	title: "Phantom Wallet",
	// },
	// {
	// 	imgPath: "/media/images/connect-wallet/magic-eden.png",
	// 	title: "Magic Eden",
	// },
];

type TProps = {
	handleClose: () => void;
};

const ConnectWalletModal = ({ handleClose }: TProps) => {
	const { connectWallet } = useWallet();

	return (
		<>
			<Modal
				contentClassName={clsx("modal-wrappe connect-wallet-modal")}
				show={true}
				onHide={handleClose}
			>
				<div className="btn-exit">
					<div className="exit-icon" onClick={handleClose}>
						<KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
					</div>
				</div>
				<div className={clsx("modal-title text-center")}>Choose Wallet</div>
				<div className="modal-content">
					<div className="wallet-list">
						{WALLET_LIST.map((walletItem) => (
							<div
								key={walletItem.type}
								className="wallet-item"
								onClick={async () => {
									const isConnect = await connectWallet(walletItem.type);
									if (isConnect) {
										handleClose();
									}
								}}
							>
								<img src={walletItem.imgPath} alt="" width={34} />
								<div className="wallet-title">{walletItem.title}</div>
							</div>
						))}
					</div>
				</div>
				<div className="modal-btns text-center">
					<ButtonCustom
						label="Close"
						btnClassName="button button-grey-light spaces px-20 mr-12"
						handleClick={handleClose}
					/>
				</div>
			</Modal>
		</>
	);
};

export default ConnectWalletModal;
