import NoEntriesPage from "../components/no-entries-page/NoEntriesPage";

export const SUB_NAVIGATIONS_ACCOUNT = [
	{
		iconPath: "/media/svg/general/profile.svg",
		svgClassName: "spaces h-19 w-19",
		label: "Profile",
		toPath: "/account/profile",
	},
	{
		iconPath: "/media/svg/general/balance.svg",
		svgClassName: "spaces h-17 w-17",
		label: "Balances",
		toPath: "/account/balances",
	},
	{
		iconPath: "/media/svg/general/referrals.svg",
		svgClassName: "spaces h-16 w-16",
		label: "Referrals",
		toPath: "/account/referrals/codes",
	},
	{
		iconPath: "/media/svg/general/coins.svg",
		svgClassName: "spaces h-16 w-16",
		label: "Deposits",
		toPath: "/account/deposits/ALL",
	},
	{
		iconPath: "/media/svg/general/coins.svg",
		svgClassName: "spaces h-16 w-16",
		label: "Withdrawals",
		toPath: "/account/withdrawals/ALL",
	},
	{
		iconPath: "/media/svg/general/setting.svg",
		svgClassName: "spaces h-16 w-16",
		label: "Settings",
		toPath: "/account/settings",
	},
	{
		iconPath: "/media/svg/general/logOut.svg",
		svgClassName: "spaces h-17 w-17",
		label: "Log Out",
		toPath: "log-out",
	},
];
export const WITHDRAW_TAB = [
	{
		nav: {
			label: "all chains",
			eventKey: "1",
		},
		content: {
			component: <NoEntriesPage title="No entries" />,
		},
	},
	{
		nav: {
			label: "Fun",
			eventKey: "7",
		},
		content: {
			component: <NoEntriesPage title="No entries" />,
		},
	},
	{
		nav: {
			label: "btc",
			eventKey: "2",
		},
		content: {
			component: <NoEntriesPage title="No entries" />,
		},
	},
	{
		nav: {
			label: "eth",
			eventKey: "3",
		},
		content: {
			component: <NoEntriesPage title="No entries" />,
		},
	},
	{
		nav: {
			label: "bnb",
			eventKey: "4",
		},
		content: {
			component: <NoEntriesPage title="No entries" />,
		},
	},
	{
		nav: {
			label: "sol",
			eventKey: "5",
		},
		content: {
			component: <NoEntriesPage title="No entries" />,
		},
	},
	{
		nav: {
			label: "arb",
			eventKey: "8",
		},
		content: {
			component: <NoEntriesPage title="No entries" />,
		},
	},
	{
		nav: {
			label: "FUNBIT",
			eventKey: "9",
		},
		content: {
			component: <NoEntriesPage title="No entries" />,
		},
	},
	{
		nav: {
			label: "nft",
			eventKey: "6",
		},
		content: {
			component: <NoEntriesPage title="No entries" />,
		},
	},
];
