import clsx from "clsx";
import { useState } from "react";

type TRadioIem = {
	label: string;
	id: string;
	disabled?: boolean;
};

type TProps = {
	radioClassName?: string;
	labelClassName?: string;
	radioList?: TRadioIem[];
	radioName?: string;
	radioIdChecked?: string;
	handleChangeChecked?: (radioId: string) => void;
};

const ProofRadio = ({
	radioName,
	radioList,
	radioClassName,
	labelClassName,
	radioIdChecked,
	handleChangeChecked,
}: TProps) => {
	const [idChecked, setIdChecked] = useState(radioIdChecked);

	const handleChange = (radioItem: TRadioIem) => {
		setIdChecked(radioItem.id);
		handleChangeChecked && handleChangeChecked(radioItem.id);
	};

	return (
		<>
			<div className={clsx("proof-radio-container", radioClassName)}>
				{radioList?.map((radioItem) => (
					<div
						key={radioItem.id}
						className="proof-radio-item"
						style={{ cursor: radioItem.disabled ? "unset" : "pointer" }}
					>
						<input
							disabled={radioItem.disabled}
							type="radio"
							id={radioItem.id}
							className="input-radio"
							name={radioName}
							checked={idChecked ? idChecked === radioItem.id : true}
							onChange={() => handleChange(radioItem)}
						/>
						<label
							htmlFor={radioItem.id}
							className={clsx("label-wrapper", labelClassName)}
						>
							<span className="label-icon"></span>
							{radioItem.label}
						</label>
					</div>
				))}
			</div>
		</>
	);
};

export default ProofRadio;
