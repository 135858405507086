import {Button, ConfigProvider, Popover } from "antd"

const CustomPopover = ({ content, open, children, handleOpenChange }: any) => {


    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgElevated: "#252525"
                },
            }}
        >
            <Popover
                content={content}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
            >
                {children}
            </Popover>
        </ConfigProvider>
    )
}

export default CustomPopover