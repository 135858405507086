export const SCREEN_WIDTH = {
  MOBILE: 425,
  TABLET: 800,
};

export enum QUERY_KEY {
  GET_AUCTION_LIST = "GET_AUCTION_LIST",
  GET_AUCTION_ORDER = "GET_AUCTION_ORDER",
}

export enum Inscription_Kind {
  FILES = 'files',
  NAMES = 'names',
  BRC20 = 'brc_20',
  TEXT = 'text',
}

export const WALLET = {
  UNISAT: 'unisat',
  BITGET: "bitget"
}