import { Modal } from "react-bootstrap"
import clsx from "clsx"
import "./modal.scss"
import ButtonCustom from "../button/ButtonCustom"
import { KTSVG } from "../../../../_metronic/helpers"

const MAINNET_NETWORK_LIST = [
    {
        imgPath: "/media/images/coins/BTC.png",
        title: "Bitcoin",
        name: "BITCOIN TESTNET"
    },
    // {
    //     imgPath: "/media/images/chain-network/ic-eth.9270fc02.svg",
    //     title: "Ethereum",
    //     name: "ETH MAINNET"
    // },
    // {
    //     imgPath: "/media/images/chain-network/ic-bsc.419dfaf2.png",
    //     title: "BNB Smart Chain",
    //     name: "BSC MAINNET"
    // },
    // {
    //     imgPath: "/media/images/chain-network/ic-matic.910e1faf.png",
    //     title: "Matic(Polygon)",
    //     name: "MATIC MAINNET"
    // },
    // {
    //     imgPath: "/media/images/chain-network/ic-avax.234db155.svg",
    //     title: "Avalanche",
    //     name: "AVAX"
    // },
]

type TProps = {
    handleClose: () => void;
    handleChainNetwork: (chainNetwok: any) => void
}

const ChainNetworkModal = ({ handleClose, handleChainNetwork }: TProps) => {
    return (
        <>
            <Modal
                contentClassName={clsx("modal-wrappe chain-network-modal")}
                show={true}
                onHide={handleClose}
            >
                <div className="btn-exit">
                    <div className="exit-icon" onClick={handleClose}>
                        <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
                    </div>
                </div>
                <div className={clsx("modal-title")}>
                    Choose Network
                </div>
                <div className="modal-content">
                    <div className="wallet-list">
                        {MAINNET_NETWORK_LIST.map((networkItem) => (
                            <div 
                                className="wallet-item" 
                                onClick={() => {
                                    handleChainNetwork(networkItem);
                                    handleClose();
                                }}
                            >
                                <img src={networkItem.imgPath} alt="" width={28} />
                                <div className="wallet-title">{networkItem.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="modal-btns text-center">
                    <ButtonCustom
                        label="Close"
                        btnClassName="button button-grey-light spaces px-20 mr-12"
                        handleClick={handleClose}
                    />
                </div>
            </Modal>
        </>
    )
}

export default ChainNetworkModal