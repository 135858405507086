import { ConfigProvider, Tabs } from "antd";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AuctionListSort } from "../../../../../types/marketplace-service";
import { useAuctionListParamStore } from "../../../../store/auction-list-param.store";
import ButtonCustom from "../../../components/button/ButtonCustom";
import ConnectWalletModal from "../../../components/modal/connect-wallet/ConnectWalletModal";
import SelectSearch from "../../../components/select/SelectSearch";
import CoinBasiclInfo from "./components/CoinBasicInfo";
import ListNFTForSaleModal from "./components/ListNFTForSaleModal";
import ListedTab, { refListed } from "./components/ListedTab";
import MyAssetsModal from "./components/MyAssetsModal";
import OrdersTab from "./components/OrdersTab";
import MyOrdersTab from "./components/MyOrdersTab";
import { useWallet } from "../../../../../providers/wallet_provider";
import CollectionBasicInfo from "./components/CollectionBasicInfo";
import ListedCollectionTab from "./components/ListedCollectionTab";

const SELECT_OPTIONS = [
	{ label: "Price: From Low to High", value: AuctionListSort.UNIT_PRICE_ASC },
	{ label: "Price: From High to Low", value: AuctionListSort.UNIT_PRICE_DESC },
	{
		label: "List Time: From Latest to Earliest",
		value: AuctionListSort.CREATED_AT_DESC,
	},
	{
		label: "List Time: From Earliest to Latest",
		value: AuctionListSort.CREATED_AT_ASC,
	},
];

const ExtraContent = ({ tick }: { tick?: string }) => {
	const [shouldOpenConnectWalletModal, setShouldOpenConnectWalletModal] =
		useState(false);
	const { account } = useWallet();
	const [shouldOpenMyAssetsModal, setShouldOpenMyAssetsModal] = useState(false);
	const [shouldOpenListNFTForSaleModal, setShouldOpenListNFTForSaleModal] =
		useState(false);
	const { setAuctionListParam } = useAuctionListParamStore();
	const [nftInfo, setNftInfo] = useState(null);

	const handleClickButtonList = (nftInfo: any) => {
		setShouldOpenMyAssetsModal(false);
		setShouldOpenListNFTForSaleModal(true);
		setNftInfo(nftInfo);
	};

	const handleSortChange = (value: any) => {
		setAuctionListParam({
			sort: value.value,
		});
	};

	return (
		<div className="nav-extra-content">
			{/* <Link to={`/brc20/${tick?.toLowerCase()}`}>
				<ButtonCustom
					label={`${tick} info`}
					labelClassName="text-uppercase"
					btnClassName="button button-grey-light"
				/>
			</Link> */}
			{/* <SelectSearch
				classHeight="spaces h-40"
				options={SELECT_OPTIONS}
				position="absolute"
				classSelectPopup="spaces w-275"
				valueDefault={SELECT_OPTIONS[0].label}
				onSelectChange={handleSortChange}
			/> */}
			<ButtonCustom
				label="My Assets"
				labelClassName="text-capitalize"
				btnClassName="button button-yellow"
				handleClick={() => {
					account
						? setShouldOpenMyAssetsModal(true)
						: setShouldOpenConnectWalletModal(true);
				}}
			/>
			{shouldOpenConnectWalletModal && (
				<ConnectWalletModal
					handleClose={() => setShouldOpenConnectWalletModal(false)}
				/>
			)}
			{shouldOpenMyAssetsModal && (
				<MyAssetsModal
					handleClose={() => setShouldOpenMyAssetsModal(false)}
					handleClickButtonList={(nftInfo: any) =>
						handleClickButtonList(nftInfo)
					}
				/>
			)}
			{shouldOpenListNFTForSaleModal && nftInfo && (
				<ListNFTForSaleModal
					handleClose={() => setShouldOpenListNFTForSaleModal(false)}
					nftInfo={nftInfo}
				/>
			)}
		</div>
	);
};

const MarketplaceCollectionTradePage = () => {
	const { tick } = useParams();

	const tradeTabs = [
		{
			key: "1",
			label: "Listed",
			children: <ListedCollectionTab ref={refListed} />,
		},
		{
			key: "2",
			label: "Orders",
			children: <OrdersTab />,
		},
		{
			key: "3",
			label: "My Orders",
			children: <MyOrdersTab />,
		},
	];

	return (
		<div>
			<CollectionBasicInfo ticker={tick} />
			<ConfigProvider
				theme={{
					components: {
						Tabs: {
							inkBarColor: "#ffb018",
							itemColor: "#b1b6c6",
							itemHoverColor: "#ffb018",
							itemSelectedColor: "#ffb018",
						},
					},
					token: {
						colorBorderSecondary: "#888888",
						colorText: "#ffffff",
						colorBgContainer: "#888888",
					},
				}}
			>
				<Tabs
					defaultActiveKey="1"
					items={tradeTabs}
					tabBarExtraContent={<ExtraContent tick={tick} />}
				/>
			</ConfigProvider>
		</div>
	);
};

export default MarketplaceCollectionTradePage;
