import { KTSVG } from "../../../_metronic/helpers";
import { formatNumber } from "../../utils";
const SATS_PER_BTC = 100_000_000;

const btcIcon = (
	<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="9" cy="9" r="9" fill="#E9983D"></circle>
		<path
			d="M13.14 7.965c.185-1.143-.624-1.672-1.078-1.964-.452-.292-1.143-.502-1.143-.502l.442-1.78-1.02-.256-.442 1.78-.815-.206.442-1.78L8.494 3l-.442 1.78-2.157-.542-.262 1.056s1.065.266 1.182.297c.12.029.104.09.094.13-.01.04-1.34 5.405-1.36 5.476-.016.072-.028.113-.127.09l-1.131-.284L4 12.176l2.088.523-.423 1.702 1.113.279.423-1.703.693.174-.423 1.703 1.06.266.424-1.702.798.2c.53.133 2.544.158 2.982-1.611.439-1.77-1.012-2.519-1.012-2.519s1.23-.38 1.417-1.523Zm-2.417 3.282c-.21.847-1.155.912-1.37.857l-1.895-.474.56-2.262 1.965.492c.377.096.951.54.74 1.387Zm.35-3.445c-.224.902-1.124.861-1.408.791L8.28 8.246l.534-2.153 1.264.316c.216.054 1.22.492.995 1.393Z"
			fill="#fff"
		></path>
	</svg>
);

export const SPORT_CARD_LIST = [
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-44 h-44"
				path="/media/svg/navigation/sports/live.svg"
			/>
		),
		sportName: "Live",
		bgClassName: "background-1",
	},
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-36 h-36"
				path="/media/svg/navigation/sports/soccer.svg"
			/>
		),
		sportName: "Soccer",
		bgClassName: "background-2",
	},
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-36 h-36"
				path="/media/svg/navigation/sports/tennis.svg"
			/>
		),
		sportName: "Tennis",
		bgClassName: "background-3",
	},
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-36 h-36"
				path="/media/svg/navigation/sports/fifa.svg"
			/>
		),
		sportName: "Fifa",
		bgClassName: "background-4",
	},
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-36 h-36"
				path="/media/svg/navigation/sports/basketball.svg"
			/>
		),
		sportName: "Basketball",
		bgClassName: "background-5",
	},
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-36 h-36"
				path="/media/svg/navigation/sports/ice-hockey.svg"
			/>
		),
		sportName: "Ice Hockey",
		bgClassName: "background-6",
	},
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-36 h-36"
				path="/media/svg/navigation/sports/volleyball.svg"
			/>
		),
		sportName: "Volleyball",
		bgClassName: "background-7",
	},
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-36 h-36"
				path="/media/svg/navigation/sports/table-tennis.svg"
			/>
		),
		sportName: "Table Tennis",
		bgClassName: "background-8",
	},
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-36 h-36"
				path="/media/svg/navigation/sports/baseball.svg"
			/>
		),
		sportName: "Baseball",
		bgClassName: "background-1",
	},
	{
		iconSport: (
			<KTSVG
				svgClassName="spaces w-36 h-36"
				path="/media/svg/navigation/sports/handball.svg"
			/>
		),
		sportName: "Handball",
		bgClassName: "background-2",
	},
];

export const NFT_CARD_LIST = [
	{
		nftId: "#8289",
		nftImg: "/media/images/nft/8289.webp",
	},
	{
		nftId: "#4537",
		nftImg: "/media/images/nft/default.webp",
	},
	{
		nftId: "#7350",
		nftImg: "/media/images/nft/default.webp",
	},
	{
		nftId: "Fidenza #183",
		nftImg: "/media/images/nft/Fidenza-183.avif",
	},
	{
		nftId: "CryptoPunk #4668",
		nftImg: "/media/images/nft/CryptoPunk-4668.avif",
	},
	{
		nftId: "Doodle #8003",
		nftImg: "/media/images/nft/Doodle-8003.avif",
	},
	{
		nftId: "Bored Ape Yacht Club #1779",
		nftImg: "/media/images/nft/Bored-ape.avif",
	},
	{
		nftId: "#1213",
		nftImg: "/media/images/nft/1213.webp",
	},
	{
		nftId: "#8289",
		nftImg: "/media/images/nft/10.webp",
	},
	{
		nftId: "Fidenza #183",
		nftImg: "/media/images/nft/Fidenza-183.avif",
	},
	{
		nftId: "CryptoPunk #4668",
		nftImg: "/media/images/nft/CryptoPunk-4668.avif",
	},
	{
		nftId: "Doodle #8003",
		nftImg: "/media/images/nft/Doodle-8003.avif",
	},
	{
		nftId: "Bored Ape Yacht Club #1779",
		nftImg: "/media/images/nft/Bored-ape.avif",
	},
	{
		nftId: "Fidenza #183",
		nftImg: "/media/images/nft/Fidenza-183.avif",
	},
	{
		nftId: "CryptoPunk #4668",
		nftImg: "/media/images/nft/CryptoPunk-4668.avif",
	},
];

export const CRYPTO_CARD_LIST = [
	{
		cryptoName: "BTC",
		cryptoImg: "/media/images/coins/BTC.png",
	},
	{
		cryptoName: "BTC",
		cryptoImg: "/media/images/coins/BTC.png",
	},
	{
		cryptoName: "BTC",
		cryptoImg: "/media/images/coins/BTC.png",
	},
	{
		cryptoName: "BTC",
		cryptoImg: "/media/images/coins/BTC.png",
	},
	{
		cryptoName: "BTC",
		cryptoImg: "/media/images/coins/BTC.png",
	},
	{
		cryptoName: "BTC",
		cryptoImg: "/media/images/coins/BTC.png",
	},
	{
		cryptoName: "BTC",
		cryptoImg: "/media/images/coins/BTC.png",
	},
	{
		cryptoName: "BTC",
		cryptoImg: "/media/images/coins/BTC.png",
	},
	{
		cryptoName: "BTC",
		cryptoImg: "/media/images/coins/BTC.png",
	},
];
export const EXPLORE_CARD_LIST = [
	{
		exploreName: "1000x Futures",
		exploreIcon: "/media/svg/navigation/crypto-futures.svg",
		exploreDes:
			" Trade a diverse selection of coins with up to 1000x leverage.",
		exploreFeatures: {
			featureIcons: [
				"/media/svg/coins/btc.png",
				"/media/svg/coins/erc_1.png",
				"/media/svg/coins/eth.png",
				"/media/svg/coins/binance.svg",
				"/media/svg/coins/sln.png",
			],
			featureDes: "  All Futures",
		},
	},
	{
		exploreName: "1000x Futures",
		exploreIcon: "/media/svg/navigation/crypto-futures.svg",
		exploreDes:
			" Trade a diverse selection of coins with up to 1000x leverage.",
		exploreFeatures: {
			featureIcons: [
				"/media/svg/coins/btc.png",
				"/media/svg/coins/erc_1.png",
				"/media/svg/coins/eth.png",
				"/media/svg/coins/binance.svg",
				"/media/svg/coins/sln.png",
			],
			featureDes: "  All Futures",
		},
	},
	{
		exploreName: "1000x Futures",
		exploreIcon: "/media/svg/navigation/crypto-futures.svg",
		exploreDes:
			" Trade a diverse selection of coins with up to 1000x leverage.",
		exploreFeatures: {
			featureIcons: [
				"/media/svg/coins/btc.png",
				"/media/svg/coins/erc_1.png",
				"/media/svg/coins/eth.png",
				"/media/svg/coins/binance.svg",
				"/media/svg/coins/sln.png",
			],
			featureDes: "  All Futures",
		},
	},
	{
		exploreName: "1000x Futures",
		exploreIcon: "/media/svg/navigation/crypto-futures.svg",
		exploreDes:
			" Trade a diverse selection of coins with up to 1000x leverage.",
		exploreFeatures: {
			featureIcons: [
				"/media/svg/coins/btc.png",
				"/media/svg/coins/erc_1.png",
				"/media/svg/coins/eth.png",
				"/media/svg/coins/binance.svg",
				"/media/svg/coins/sln.png",
			],
			featureDes: "  All Futures",
		},
	},
	{
		exploreName: "1000x Futures",
		exploreIcon: "/media/svg/navigation/crypto-futures.svg",
		exploreDes:
			" Trade a diverse selection of coins with up to 1000x leverage.",
		exploreFeatures: {
			featureIcons: [
				"/media/svg/coins/btc.png",
				"/media/svg/coins/erc_1.png",
				"/media/svg/coins/eth.png",
				"/media/svg/coins/binance.svg",
				"/media/svg/coins/sln.png",
			],
			featureDes: "  All Futures",
		},
	},
];

const get24hChange = (rowData: {
	today_vol: number;
	yesterday_vol: number;
}) => {
	return +rowData?.today_vol === 0 && +rowData?.yesterday_vol === 0
		? 0
		: +rowData?.yesterday_vol === 0
		? 100
		: ((+rowData?.today_vol - +rowData?.yesterday_vol) /
				+rowData?.yesterday_vol) *
		  100;
};

export const columns4 = [
	// {
	//     name: "",
	//     field: "index",
	//     iconTick: "/media/svg/coins/eth.png",
	//     iconVolume: "/media/images/coins/BTC.png",
	//     iconMarketCap: "/media/svg/coins/binance.svg",
	//     classHeader: "text-start text-capitalize spaces fs-16",
	//     classCell: "text-start text-capitalize spaces fw-6 fs-14",
	//     render: (rowData: any) => {
	//         if (rowData?.index <= 3) {
	//             return (
	//                 <div className="d-flex align-items-center justify-content-center spaces p-4">
	//                     <img width={24} src={`/media/images/userRank/${rowData?.index}_rank.png`} alt="" />
	//                 </div>

	//             )
	//         }
	//         return <div className="d-flex align-items-center justify-content-center">{rowData?.index}</div>
	//     },
	// },
	{
		name: "Tick",
		field: "tick",
		classHeader: "text-start text-capitalize spaces fs-16 min-w-100px",
		classCell: "text-start text-capitalize spaces fw-6 fs-14 h-80",
		render: (rowData: any) => (
			<div
				className="d-flex align-items-center"
				style={{
					color: "#ebb94c",
					fontFamily: "Proto Mono, Helvetica, Arial, sans-serif",
				}}
			>
				{/* <img width={32} src={rowData.iconTick} alt="" /> */}
				<div className="spaces ml-8 fs-16 text-uppercase">{rowData?.tick}</div>
			</div>
		),
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Volume",
		field: "volume",
		render: (rowData: any) => (
			<div>
				<div className="d-flex align-items-center justify-content-end min-w-100px gap-2">
					{+rowData?.volume / SATS_PER_BTC} {btcIcon}
				</div>
			</div>
		),
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Price",
		field: "floor_price",
		render: (rowData: any) => (
			<div className="d-flex align-items-center justify-content-end min-w-100px">
				<div className="spaces ml-8 text-lowercase">
					{formatNumber(+rowData?.floor_price, "")}
					<div className="text-color-gray">sats/{rowData?.tick}</div>
					{/* <div className="spaces fs-12 text-color-gray fw-5 text-start mt-4">
						$1.48
					</div> */}
				</div>
			</div>
		),
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Market Cap",
		field: "",
		render: (rowData: any) => (
			<div>
				{((rowData?.minted * rowData?.floor_price) / 100000000)?.toFixed(3)}{" "}
				{btcIcon}
			</div>
		),
	},
	//   {
	//     classHeader: "text-start text-capitalize spaces fs-16",
	//     classCell: "text-start spaces fw-6 fs-14",
	//     name: "Market cap",
	//     field: "marketCap",
	//     render: (rowData: any) => (
	//       <div>
	//         <div className="d-flex align-items-center ">
	//           <img
	//             width={18}
	//             className="spaces mr-4"
	//             src={rowData.iconVolume}
	//             alt=""
	//           />
	//           {rowData?.marketCap}
	//         </div>
	//         <div className="spaces fs-12 text-color-gray fw-5 text-start mt-4">
	//           $2.4M
	//         </div>
	//       </div>
	//     ),
	//   },
	//   {
	//     classHeader: "text-center text-capitalize spaces fs-16 min-w-100px",
	//     classCell: "text-center spaces fw-6 fs-14",
	//     name: "Change",
	//     field: "change",
	//     render: (rowData: any) => (
	//       <div
	//         className={
	//           Number(rowData?.change) > 0 ? "text-color-green" : "text-danger"
	//         }
	//       >
	//         {rowData?.change}%
	//       </div>
	//     ),
	//   },
	{
		classHeader: "text-end text-capitalize spaces fs-16 min-w-100px",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Change (24h)",
		field: "",
		render: (rowData: any) => (
			<div
				className={`min-w-100px ${
					get24hChange(rowData) > 0
						? "text-success"
						: get24hChange(rowData) < 0
						? "text-danger"
						: ""
				}`}
			>
				{get24hChange(rowData) > 0 ? "+" : get24hChange(rowData) < 0 ? "-" : ""}
				{get24hChange(rowData)}%
			</div>
		),
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16 min-w-100px",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Num of sales",
		field: "number_of_sale",
		render: (rowData: any) => (
			<div>{formatNumber(+rowData?.listed || 0, "")}</div>
		),
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Holders",
		field: "",
		render: (rowData: any) => <div>{rowData?.holders_count}</div>,
	},
];

export const columns5 = [
	{
		name: "",
		field: "index",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start text-capitalize spaces fw-6 fs-14",
		render: (rowData: any) => {
			if (rowData?.index <= 3) {
				return (
					<div className="d-flex align-items-center justify-content-center spaces p-4">
						<img
							width={24}
							src={`/media/images/userRank/${rowData?.index}_rank.png`}
							alt=""
						/>
					</div>
				);
			}
			return (
				<div className="d-flex align-items-center justify-content-center">
					{rowData?.index}
				</div>
			);
		},
	},
	{
		name: "Collection",
		field: "tick",
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start text-capitalize spaces fw-6 fs-14",
		render: (rowData: any) => (
			<div className="d-flex align-items-center">
				<img width={32} src={rowData.iconTick} alt="" />
				<div className="spaces ml-8 fs-16">{rowData?.tick}</div>
			</div>
		),
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Volume",
		field: "volume",
		render: (rowData: any) => (
			<div>
				<div className="d-flex align-items-center min-w-100px justify-content-end">
					<img
						width={18}
						className="spaces mr-4"
						src={rowData.iconVolume}
						alt=""
					/>
					{rowData?.volume}
				</div>
			</div>
		),
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Floor price",
		field: "price",
		render: (rowData: any) => (
			<div className="d-flex align-items-center min-w-100px justify-content-end">
				<img
					width={18}
					className="spaces mr-4"
					src={rowData.iconVolume}
					alt=""
				/>
				<div className="text-lowercase">{rowData?.price}</div>
			</div>
		),
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14 min-w-100px",
		name: "Sales",
		field: "updownNumOfSales",
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Liquidity",
		field: "change",
		render: (rowData: any) => <div>0.8%</div>,
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14 min-w-100px",
		name: "Owners",
		field: "updownNumOfSales",
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14 min-w-100px",
		name: "Listed",
		field: "holders",
		render: (rowData: any) => (
			<div>
				<div className="">7.467</div>
				<div className="spaces fs-12 text-color-gray fw-5 text-end mt-4">
					0/66
				</div>
			</div>
		),
	},
];

export const data4 = [
	{
		tick: "ligo",
		index: "1",
		iconTick: "/media/images/coins/lino.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "AINN",
		index: "2",
		iconTick: "/media/images/coins/AINN.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.804.8",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "INJ",
		index: "3",
		iconTick: "/media/images/coins/AINN.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "-0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Rats",
		index: "4",
		iconTick: "/media/images/coins/28452.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "drag",
		index: "5",
		iconTick: "/media/images/coins/drag.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Bear",
		index: "6",
		iconTick: "/media/images/coins/28483.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "ligo",
		index: "7",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "BTC",
		index: "8",
		iconTick: "/media/images/coins/BTC.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Moon",
		index: "9",
		iconTick: "/media/images/coins/28482.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 15.789,
		updown: "ligo",
		marketCap: "678,942.5",
		price: "0.032 sats",
		change: "-0.000018",
		numOfSales: "ligo",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
	},
	{
		tick: "ligo",
		index: "10",
		iconTick: "/media/images/coins/28452.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "ETH",
		index: "11",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 15.789,
		updown: "ligo",
		marketCap: "678,942.5",
		price: "0.032 sats",
		change: "-0.000018",
		numOfSales: "ligo",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
	},
	{
		tick: "ETH",
		index: "12",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 20.123,
		updown: "fake2",
		marketCap: "800,000.0",
		price: "0.035 sats",
		change: "-0.000025",
		numOfSales: "fake2",
		holders: 400.555,
		updownMarketCap: "$18.75M",
		updownChange: "-2.15%",
		updownNumOfSales: 80,
		updownHolders: "15,200",
		updownChangePercentage: "+2.40%",
	},
	{
		tick: "ETH",
		index: "13",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 25.456,
		updown: "fake3",
		marketCap: "1,200,345.6",
		price: "0.040 sats",
		change: "-0.000032",
		numOfSales: "fake3",
		holders: 512.789,
		updownMarketCap: "$22.60M",
		updownChange: "+4.78%",
		updownNumOfSales: 150,
		updownHolders: "18,300",
		updownChangePercentage: "-1.80%",
	},
	{
		tick: "ETH",
		index: "14",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 18.789,
		updown: "fake4",
		marketCap: "900,123.4",
		price: "0.038 sats",
		change: "-0.000028",
		numOfSales: "fake4",
		holders: 450.321,
		updownMarketCap: "$20.45M",
		updownChange: "+3.15%",
		updownNumOfSales: 110,
		updownHolders: "16,500",
		updownChangePercentage: "+1.20%",
	},
];

export const TIMES_OPTIONS = [
	{ label: "All Time", value: "all" },
	{ label: "24h", value: "24h" },
	{ label: "7D", value: "7D" },
	{ label: "30D", value: "30D" },
];
