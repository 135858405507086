import {
	ConfigProvider,
	GetProp,
	Tabs,
	Upload,
	UploadProps,
	message,
} from "antd";
import ButtonCustom from "../../components/button/ButtonCustom";
import { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import CheckBox from "../../components/text-field/CheckBox";
import TextField from "../../components/text-field/TextField";
import TextareaCustom from "../../components/textarea/TextareaCustom";
import SelectNetworkFee from "./SelectNetworkFee";
import useCreateOrder from "../../../hooks/useCreateOrder";
import InscriblingOrderModal from "./InscriblingOrderModal";
import RiskWarningModal from "./RiskWarningModal";
import { Inscription_Kind } from "../../../utils";
import { useWallet } from "../../../../providers/wallet_provider";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

const FilesTab = ({ resetField }: { resetField?: number }) => {
	const [fileUpload, setFileUpload] = useState<FileType[]>([]);
	const [addressTabActiveKey, setAddressTabActiveKey] = useState("1");
	const { account } = useWallet();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isRiskWarning, setIsRiskWarning] = useState(false);
	const [inscriptionInfo, setInscriptionInfo] = useState<{
		receiveAddress: string;
		feeRate: number;
		agreedTerm: boolean;
		files: {
			filename: string;
			dataURL: string;
		}[];
		outputValue: number;
	}>({
		receiveAddress: account || "",
		feeRate: 1,
		agreedTerm: false,
		files: [],
		outputValue: 546,
	});
	const { mutate, data } = useCreateOrder({
		onSuccess: () => setIsOpenModal(true),
	});

	useEffect(() => {
		setFileUpload([]);
		setInscriptionInfo({
			receiveAddress: account || "",
			feeRate: 1,
			agreedTerm: false,
			files: [],
			outputValue: 546,
		});
	}, [account, resetField]);

	useEffect(() => {
		!(fileUpload.length > 1) && setAddressTabActiveKey("1");
	}, [fileUpload]);

	const handleRemoveFile = (uid: any) => {
		const item = fileUpload.find((file) => file.uid === uid);

		const index = inscriptionInfo.files.findIndex(
			(file) => file.filename === item!.name
		);
		if (index !== -1) {
			console.log(index);
			setInscriptionInfo((prev) => ({
				...prev,
				files: [...prev.files.slice(0, index), ...prev.files.slice(index + 1)],
			}));
			setFileUpload((prev: any[]) =>
				prev?.filter((file: any) => file?.uid !== uid)
			);
		}
	};

	const props: UploadProps = {
		beforeUpload: async (file: FileType) => {
			console.log(file.size);
			const isLt365kb = file.size < 365000;
			const totalSize =
				fileUpload.reduce((acc, file) => acc + file.size, 0) + file.size;

			if (!isLt365kb && totalSize > 365) {
				return message.error("File size must be smaller than 365 KB!");
			}
			if (totalSize > 365000) {
				return message.error(
					"The total file size limit for inscribing inscriptions in bulk is 100 KB!"
				);
			}

			if (isLt365kb && totalSize < 365000) {
				const fileUrl = await getBase64(file);
				setInscriptionInfo((prev) => ({
					...prev,
					files: [...prev.files, { filename: file.name, dataURL: fileUrl }],
				}));
				setFileUpload((prev) => [...prev, file]);
			}

			return isLt365kb;
		},
		fileList: fileUpload,
	};

	const toAddressTab = [
		{
			key: "1",
			label: "To Single Address",
			children: (
				<TextField
					placeholder="Provide the address to receive the inscription"
					value={inscriptionInfo.receiveAddress}
					setValue={(value: string) =>
						setInscriptionInfo((prev) => ({ ...prev, receiveAddress: value }))
					}
				/>
			),
		},
		{
			key: "2",
			label: "To Multiple Address",
			children: (
				<TextareaCustom
					rows={4}
					placeholder="aaa or aaa.funbit"
					value={""}
					handleChange={() => {}}
				/>
			),
			disabled: true,
		},
	];

	const onChangeInscriptionInfo = (data: {
		feeRate: number;
		outputValue: number;
	}) => {
		setInscriptionInfo((prev) => ({
			...prev,
			...data,
		}));
	};

	const createOrder = () => {
		if (fileUpload.length === 0) return;
		if (!inscriptionInfo.receiveAddress) return;
		if (inscriptionInfo.agreedTerm) {
			mutate({
				receiveAddress: inscriptionInfo.receiveAddress,
				feeRate: inscriptionInfo.feeRate,
				outputValue: inscriptionInfo.outputValue,
				files: inscriptionInfo.files,
				inscription_kind: Inscription_Kind.FILES,
			});
		} else {
			setIsRiskWarning(true);
		}
	};

	const handleAgreedBtn = () => {
		setInscriptionInfo((prev) => ({
			...prev,
			agreedTerm: true,
		}));
		setIsRiskWarning(false);
		mutate({
			receiveAddress: inscriptionInfo.receiveAddress,
			feeRate: inscriptionInfo.feeRate,
			outputValue: inscriptionInfo.outputValue,
			files: inscriptionInfo.files,
		});
	};

	return (
		<>
			<div className="inscribe-tab-container">
				<div className="linear-gradient-blur"></div>
				<div className="d-flex align-items-center justify-content-between w-100">
					<div className="spaces fs-16 fw-500 text-color-white">
						{fileUpload && fileUpload.length > 0
							? `${fileUpload.length} files`
							: "Upload your files to begin"}
					</div>
					<ButtonCustom
						label="remove all"
						btnClassName="button button-grey spaces px-14 spaces h-29 min-h-29"
					/>
				</div>
				<div className="file-upload-container">
					<Upload
						{...props}
						listType="text"
						className="file-uploader"
						showUploadList={false}
					>
						<div className="d-flex align-items-center justify-content-around spaces gap-16 px-24 py-8 w-100">
							<i className="bi bi-cloud-arrow-up-fill upload-icon"></i>
							<div className="text-center">
								<span className="text-color-white spaces fw-500">
									Drag and drop your files here, or click to select files
								</span>
								<br />
								<span className="text-color-gray">
									.jpg, .webp, .png, .gif, .txt, .mp3, .mp4(h264) + more!
								</span>
							</div>
						</div>
					</Upload>
					{fileUpload && fileUpload.length > 0 && (
						<div className="file-upload-list">
							{fileUpload?.map((file: any, index: number) => (
								<div className="file-upload-item" key={`${file.uid}`}>
									<div className="d-flex align-items-center spaces gap-10">
										<div className="index">{index + 1}</div>
										<div className="file-name">{file?.name}</div>
									</div>
									<div className="d-flex align-items-center spaces gap-10">
										<div className="file-size">{file?.size / 1000} KB</div>
										<div
											className="remove-file-icon"
											onClick={() => handleRemoveFile(file?.uid)}
										>
											<KTSVG
												svgClassName="spaces h-16 h-16"
												path="/media/svg/icons/close.svg"
											/>
										</div>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
				<div className="w-100">
					<ConfigProvider
						theme={{
							components: {
								Tabs: {
									inkBarColor: "#ffb018",
									itemColor: "#b1b6c6",
									itemHoverColor: "#ffb018",
									itemSelectedColor: "#ffb018",
								},
							},
							token: {
								colorBorderSecondary: "#888888",
								colorTextDisabled: "#b1b6c6",
							},
						}}
					>
						<Tabs
							defaultActiveKey="1"
							items={toAddressTab}
							activeKey={addressTabActiveKey}
							onChange={(key) => setAddressTabActiveKey(key)}
						/>
					</ConfigProvider>
				</div>
				{fileUpload && fileUpload.length > 0 && (
					<SelectNetworkFee
						satList={inscriptionInfo.files}
						feeRate={inscriptionInfo.feeRate}
						outputValue={inscriptionInfo.outputValue}
						receiveAddress={inscriptionInfo.receiveAddress}
						handleChangeInscriptionInfo={onChangeInscriptionInfo}
					/>
				)}
				<div className="d-flex align-items-center spaces mt-16 gap-10 w-100">
					<div>
						<KTSVG
							svgClassName="spaces h-16 h-16"
							path="/media/svg/general/info.svg"
							color="#eeff00"
						/>
					</div>
					<div>
						Please note the inscribing transaction delivers the inscription to
						the receiving address directly.
					</div>
				</div>
				<div className="w-100 spaces mt-16">
					<CheckBox
						onChange={(value: boolean) =>
							setInscriptionInfo((prev) => ({ ...prev, agreedTerm: value }))
						}
						value={inscriptionInfo.agreedTerm}
						label={<>I have read and agreed to the risk warning</>}
						labelClassName="text-lowercase"
					/>
				</div>
				<div className="spaces mt-16">
					<ButtonCustom
						label="Submit & Pay invoice"
						btnClassName="button button-yellow"
						handleClick={createOrder}
					/>
				</div>
			</div>
			{isOpenModal && (
				<InscriblingOrderModal
					handleClose={() => setIsOpenModal((prev) => !prev)}
					isShow={isOpenModal}
					orderId={data?.id}
				/>
			)}
			<RiskWarningModal
				isShowBtn={!inscriptionInfo.agreedTerm}
				handleClose={() => setIsRiskWarning(false)}
				handleAgreedBtn={handleAgreedBtn}
				isShowModal={isRiskWarning}
			/>
		</>
	);
};

export default FilesTab;
