import { ReactNode } from 'react';

type Props = {
    children: ReactNode
}

const BRC20Tab = ({ children }: Props) => {
    return (
        <div className='brc-20-tab-container'>
            {children}
        </div>
    )
}

export default BRC20Tab