import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Unisat } from "../../types/unisat";
import { WALLET } from "../../app/utils";

type Props = {
	children: React.ReactNode;
};

interface uniSatCtx {
	unisat: Unisat | null;
	requiredInstall: boolean;
	isLoading: boolean;
	account: string;
	connectWallet: (type: string) => Promise<boolean>;
}

const createUnisatState = ({
	unisat,
	isLoading,
}: {
	unisat: Unisat | null;
	isLoading: boolean;
}) => {
	return {
		unisat,
		isLoading,
	};
};

const UniSatContext = createContext<uniSatCtx>({
	unisat: null,
	requiredInstall: false,
	isLoading: true,
	connectWallet: async (type: string) => false,
	account: "",
});

const WalletProvider = ({ children }: Props) => {
	const [unisatApi, setUnisatApi] = useState(
		createUnisatState({ unisat: null, isLoading: true })
	);

	const [account, setAccount] = useState("");

	const connectWallet = async (type: string) => {
		let walletProvider;
		switch (type) {
			case WALLET.BITGET:
				if (typeof window?.bitkeep !== "undefined") {
					walletProvider = window?.bitkeep?.unisat;
					await walletProvider.switchNetwork("testnet");
				}
				break;
			case WALLET.UNISAT:
				if (typeof window?.unisat !== "undefined") {
					walletProvider = window?.unisat;
				}
				break;
		}

		if (walletProvider) {
			setUnisatApi(
				createUnisatState({ unisat: walletProvider, isLoading: false })
			);

			try {
				const accounts = await walletProvider?.requestAccounts();
				setAccount(accounts[0]);
				return true;
			} catch (error) {
				console.log(error);
			}
		} else {
			setUnisatApi((api) => ({ ...api, isLoading: false }));
			console.error(`Please, install ${type} Wallet.`);
		}
		return false;
	};

	useEffect(() => {
		const refetchAccount = (account: string[]) => {
			setAccount(account?.[0]);
		};

		unisatApi?.unisat?.on("accountsChanged", refetchAccount);

		return () => unisatApi?.unisat?.on("accountsChanged", refetchAccount);
	}, [unisatApi?.unisat]);

	const _unisatApi = useMemo(() => {
		const { unisat, isLoading } = unisatApi;
		return {
			...unisatApi,
			account,
			connectWallet,
			requiredInstall: !unisat && !isLoading,
		};
	}, [account, unisatApi]);

	return (
		<UniSatContext.Provider value={_unisatApi}>
			{children}
		</UniSatContext.Provider>
	);
};

export default WalletProvider;

export const useWallet = () => {
	const context = useContext(UniSatContext);
	if (context === undefined) {
		throw new Error("useUniSatContext must be used within a WalletProvider");
	}
	return context;
};
