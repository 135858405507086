import clsx from "clsx";
import { useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { KTSVG } from "../../../../_metronic/helpers";
import ButtonCustom from "./../../components/button/ButtonCustom";
import ProofRadio from "./../../components/text-field/ProofRadio";
import { useQuery } from "react-query";
import FunbitService from "../../../services/funbitServices";
import useGetBitcoinPrices from "../../../hooks/useGetBitcoinPrices";
import {
	Inscription_Kind,
	satsToDollars,
	shortenAddress,
} from "../../../utils";
import moment from "moment";
import { useWallet } from "../../../../providers/wallet_provider";
import Spinner from "react-bootstrap/Spinner";
type Props = {
	handleClose: () => void;
	isShow: boolean;
	orderId: string;
};

const InscriblingOrderModal = ({ handleClose, isShow, orderId }: Props) => {
	const [completeStep, setCompeleStep] = useState(false);
	const btcPrice = useGetBitcoinPrices();
	const [orderPending, setOrderPending] = useState(true);
	const wallet = useWallet();
	const [isSending, setIsSending] = useState(false);

	const { data, isLoading: isDetailLoading } = useQuery(
		["order-detail", orderId],
		() => FunbitService.getInscribeOrderById(orderId),
		{
			onSuccess(data) {
				if (data.status === 200) {
					setOrderPending(
						data?.data?.data?.files?.every((file: any) => file.tx == null)
					);
				}
			},
			refetchInterval: 1500,
			enabled: isShow && orderPending,
			keepPreviousData: true,
		}
	);

	const orderResult = data?.data?.data;

	console.log(orderResult);

	const sendBitcoin = async () => {
		setIsSending(true);
		try {
			const tx = await wallet.unisat?.sendBitcoin(
				orderResult?.payAddress,
				orderResult?.total_amount,
				{
					feeRate: orderResult?.feeRate,
				}
			);
			if (tx) {
				setCompeleStep(true);
			}
		} catch (error) {
			console.log(error);
		}
		setIsSending(false);
	};
	const METHOD_PAY = [
		{
			label: "Pay with BTC",
			id: "1",
			disabled: true,
		},
		{
			label: "  Pay with wallet",
			id: "2",
		},
	];
	const [methodPayId, setMethodPayId] = useState(METHOD_PAY[1]?.id);
	return (
		<>
			<Modal
				show={isShow}
				onHide={handleClose}
				size="lg"
				contentClassName="spaces max-w-600"
			>
				<Modal.Body>
					<div onClick={handleClose}>
						<KTSVG
							className="btn-exit"
							svgClassName="spaces h-20 h-20"
							path="/media/svg/action/exit.svg"
						/>
					</div>
					<div className="order-modal-container">
						<div className="order-header">
							<div className="title">Inscribing Order</div>
							<div className="order-id">OrderId: {orderId}</div>
							{!isDetailLoading && (
								<div className="order-info">
									<div>
										Quantity:{" "}
										<span className="text-white">
											{orderResult?.files?.length}
										</span>
									</div>
									<div>
										Points:{" "}
										<span
											className={clsx({ "text-color-yellow": completeStep })}
										>
											+1
										</span>
									</div>
									<div>
										Status:
										{completeStep ? (
											<>
												<span className="text-color-yellow">Inscribing</span>
											</>
										) : (
											<span
												className="text-white"
												style={{ textTransform: "capitalize" }}
											>
												{orderResult?.status}
											</span>
										)}
									</div>
								</div>
							)}
						</div>
						{isDetailLoading ? (
							<div className="d-flex justify-content-center p-4">
								<Spinner animation="border" variant="warning" />
							</div>
						) : (
							<>
								<div className="order-steps">
									<div className={clsx("step", { active: !completeStep })}>
										{completeStep ? (
											<i className="bi bi-check-circle-fill spaces fs-28 complete-icon"></i>
										) : (
											<div className="number">1</div>
										)}
										<span className="label">Payment</span>
									</div>
									<div
										className={clsx("line flex-fill", {
											complete: completeStep,
										})}
									></div>
									<div className="step">
										{completeStep ? (
											<i className="bi bi-check-circle-fill spaces fs-28 complete-icon"></i>
										) : (
											<div className="number">2</div>
										)}
										<span className="label">Payment Result</span>
									</div>
									<div
										className={clsx("line", { complete: completeStep })}
									></div>
									<div className={clsx("step", { active: completeStep })}>
										<div className="number">3</div>
										<span className="label">Start Inscribing</span>
									</div>
								</div>
								{completeStep && (
									<div className="order-progress">
										<i className="bi bi-clock clock-icon"></i>
										<div>
											Inscribing{" "}
											<span className="text-gray">
												(waiting for comfirmation)
											</span>
										</div>
										<div className="progress-status">
											<div className="uncofirmation">1 Uncofirmed</div>
											<div className="cofirmed">0 Cofirmed</div>
											<button className="btn-boost">Boost</button>
											<button className="btn-cancel">Cancel</button>
										</div>
										<div className="w-100 d-flex align-items-center">
											<ProgressBar
												className="
                                        spaces h-6 flex-fill"
												now={100}
											/>{" "}
											100%
										</div>
									</div>
								)}
								<div className="order-payment-info">
									<div className="payment-info">
										<div className="title text-gray">Fee rate</div>
										<div className="value">
											{orderResult?.feeRate}{" "}
											<span className="text-gray">sats/vB</span>
										</div>
									</div>
									<div className="payment-info border border-start-0 border-end-0">
										<div className="title text-gray">Service rate</div>
										<div className="value">
											{orderResult?.service_rate || 0}{" "}
											<span className="text-gray">sats</span>
										</div>
									</div>
									<div className="payment-info">
										<div className="title text-gray">Total Amount</div>
										<div className="value d-flex align-items-center text-color-yellow">
											<span className="spaces fs-16 mr-2">
												{orderResult?.total_amount / 100000000}
											</span>
											<KTSVG
												path="/media/icons/duotune/general/gen028.svg"
												className="text-color-yellow"
												width={22}
											></KTSVG>
											<span className="text-gray">
												BTC($
												{satsToDollars(orderResult?.total_amount, btcPrice)})
											</span>
										</div>
									</div>
								</div>
								{!completeStep && (
									<div className="order-payment-method">
										{
											<ProofRadio
												radioIdChecked={methodPayId}
												handleChangeChecked={(id) => setMethodPayId(id)}
												radioClassName="method"
												radioList={METHOD_PAY}
											/>
										}
										<div className="d-flex justify-content-center p-6">
											{isSending ? (
												<Spinner animation="border" variant="warning" />
											) : (
												<ButtonCustom
													handleClick={() => sendBitcoin()}
													btnClassName="button button-yellow"
													label={
														METHOD_PAY.find(
															(method) => method.id === methodPayId
														)?.label
													}
												/>
											)}
										</div>
									</div>
								)}
								<div className="order-file">
									<div className="file-header">
										<div className="line flex-fill"></div>
										<div className="text-gray">Files</div>
										<div className="line flex-fill"></div>
									</div>
									{completeStep && (
										<div className="export-to-csu">
											<i className="bi bi-download"></i>
											Export to CSU (InscriptionId. Address)
										</div>
									)}
									<div style={{ maxHeight: "186px", overflow: "auto" }}>
										{orderResult?.files?.map((file: any, index: number) => (
											<div
												className="file-content"
												style={{ marginTop: "4px" }}
												key={index}
											>
												<div className="d-flex align-items-center gap-1">
													<i
														className={clsx("bi bi-check-circle-fill fs-4", {
															"complete-icon": completeStep,
														})}
													></i>
													<div>
														<div>{file?.filename}</div>
														<div className="spaces fs-12 text-gray">
															{shortenAddress(orderResult?.receiveAddress)}
														</div>
													</div>
												</div>

												<div>
													{completeStep ? (
														<>
															{file?.tx ? (
																<div className="d-flex gap-4 align-items-center view-action">
																	<u>View Inscription</u>
																	<a
																		target="_blank"
																		href={`https://mempool.space/testnet/tx/${file?.tx}`}
																		rel="noreferrer"
																	>
																		View tx
																	</a>
																</div>
															) : (
																<Spinner animation={"border"} size="sm" />
															)}
														</>
													) : (
														"Pending"
													)}
												</div>
											</div>
										))}
									</div>
									<div className="text-gray spaces fs-11 d-flex justify-content-end mt-8">
										Order created at{" "}
										{moment(orderResult?.createdAt).format(
											"DD/MM/YYYY, h:mm:ss"
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default InscriblingOrderModal;
