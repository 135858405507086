import React from "react";
import { KTSVG } from "../../../../_metronic/helpers";

type TProps = {
	label: any;
	iconPath?: string;
	labelClassName?: string;
	value?: boolean;
	onChange?: (value: boolean) => void;
};

const CheckBox = ({
	label,
	iconPath,
	labelClassName,
	onChange,
	value,
}: TProps) => {
	return (
		<div className="input-checkbox-wrapper">
			<input
				checked={value}
				type="checkbox"
				id="checkBox_1"
				className="d-none input-checkbox"
				onChange={(e) => {
					if (e.target.checked) {
						onChange && onChange(true);
					} else {
						onChange && onChange(false);
					}
				}}
			/>
			<label
				className={`input-label label-checkbox ${labelClassName}`}
				htmlFor="checkBox_1"
			>
				{label}
				{iconPath && (
					<KTSVG svgClassName="spaces mb-2 w-16 h-16" path={iconPath} />
				)}
				<KTSVG
					svgClassName="spaces mb-2 w-11 h-11 icon-check"
					path="/media/svg/icons/checkBox.svg"
				/>
			</label>
		</div>
	);
};

export default CheckBox;
