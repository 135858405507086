import axios from "axios";
import {
  ListMyAssetsParams,
  ListMyAssetsResponse,
  ResponseUnisat,
  TransferableInscriptions,
} from "../../types/unisat";
const baseURL = "https://open-api-testnet.unisat.io";

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

axiosInstance.defaults.headers.common.Authorization =
  "Bearer 7900280082dacd6599b05376e63461f175f444b1dc84dedd6be34a9ec8dbe5ae";

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.status === 200) {
      return response;
    }
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export function fetchListMyAssets({
  address,
  start,
  limit,
}: ListMyAssetsParams): Promise<ResponseUnisat<ListMyAssetsResponse>> {
  return axiosInstance
    .get(`/v1/indexer/address/${address}/brc20/summary`, {
      params: {
        limit,
        start,
      },
    })
    .then((res) => res.data);
}

export function fetchTransferableInscriptions({
  limit,
  start,
  ticker,
  address,
}: ListMyAssetsParams & { ticker: string }): Promise<
  ResponseUnisat<TransferableInscriptions>
> {
  return axiosInstance
    .get(
      `/v1/indexer/address/${address}/brc20/${ticker}/transferable-inscriptions`,
      {
        params: {
          limit,
          start,
        },
      }
    )
    .then((res) => res.data);
}
