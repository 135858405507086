import { useQuery } from "react-query";
import {
  FetchTickersParams,
  FetchTickersResult,
} from "../../../types/funbit-service";
import {
  ListAuctionOrdersResponse,
} from "../../../types/marketplace-service";
import {
  ListMyAssetsParams,
  ListMyAssetsResponse,
  ResponseUnisat,
  TransferableInscriptions,
} from "../../../types/unisat";
import FunbitService from "../../services/funbitServices";
import {
  fetchListMyAssets,
  fetchTransferableInscriptions,
} from "../../services/unisatService";

export const useFetchTickers = ({ limit, start, status, tick }: FetchTickersParams) => {
  const { data } = useQuery<FetchTickersResult, Error>({
    queryKey: ["fetchTickers", [{ limit, start, status, tick }]],
    queryFn: () => FunbitService.fetchListTickers({ limit, start, status, tick }),
    keepPreviousData: true
  });



  return {
    list: data?.list || [],
    ads: data?.ads || [],
    deploy: data?.deploy || [],
    total: data?.total,
  };
};

export const useMyAssets = ({ address, limit, start }: ListMyAssetsParams) => {
  const { data } = useQuery<ResponseUnisat<ListMyAssetsResponse>, Error>({
    queryKey: ["fetchListMyAssets", address],
    queryFn: () => fetchListMyAssets({ address, limit, start }),
    enabled: !!address,
  });

  return {
    data: data?.data,
  };
};

export const useListForSale = ({
  address,
  limit,
  start,
  ticker,
}: ListMyAssetsParams & { ticker: string }) => {
  const { data } = useQuery<ResponseUnisat<TransferableInscriptions>, Error>({
    queryKey: [
      "fetchTransferableInscriptions",
      { address, limit, start, ticker },
    ],
    queryFn: () =>
      fetchTransferableInscriptions({ address, limit, start, ticker }),
    enabled: !!(address && ticker),
  });

  const { data: dataListed } = useQuery<ListAuctionOrdersResponse, Error>({
    queryKey: ["fetchListAuctionOrder", { address, limit, start, ticker }],
    queryFn: () =>
      FunbitService.fetchListAuctionOrder({
        address,
        limit: limit,
        start: start + 1,
        tick: ticker.toLowerCase(),
        nftType: "brc_20",
      }),
  });


  const listResult = data?.data?.detail?.map((item) => {
    const itemFind = dataListed?.list?.find(
      (_item) => _item.inscription_id === item.inscriptionId
    );
    return itemFind
      ? {
        ...item,
        event: itemFind.inscription.kind,
        inscription_listing: {
          init_price: itemFind.init_price,
          unit_price: itemFind.unit_price,
          quantity: itemFind.inscription.amount,
          from: itemFind.seller,
          to: null,
          inscription_id: itemFind.inscription_id
        },
      }
      : item;
  });

  return {
    data: data?.data ? { ...data.data, detail: listResult } : undefined,
  };
};


