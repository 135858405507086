import React from "react";
import CoinInput from "../../components/coin-input/CoinInput";
import ButtonCustom from "../../components/button/ButtonCustom";
import { CustomTabsV2 } from "../../../../_metronic/layout/components/tabs/CustomTabsV2";
import { LIST_TAB_POOLS } from "../contants";

export default function PoolsTab() {
	return (
		<>
			<div className="content-box">
				<div className="add-liquidity">
					<div className="liquidity-form">
						<h3 className="liquidity-title">ADD LIQUIDITY</h3>
						<CoinInput
							typeCoin="FUNBIT"
							imgCoin="/media/images/funbit-coin/funbit-icon.png"
						/>
						<div className="spaces mt-16"></div>
						<CoinInput typeCoin="USD" imgCoin="/media/images/coin.webp" />
						<div className="tvl-box">
							<span>TVL:</span>
							<span className="text-white">$0.00</span>
						</div>
						<ButtonCustom
							label="add pool"
							labelClassName="button button-green spaces mt-24 w-100 h-50"
						/>
					</div>
					<div className="liquidity-chart">
						<div className="chart">
							<div className="line"></div>
						</div>
						<div className="chart-info">
							<div>$0.0807022</div>
							<div>$0.201755</div>
							<div>$0.322809</div>
						</div>
						<div className="liquidity-detail">
							<p className="spaces pb-16 mb-0">
								Contribute into a shared pool to facilitate trading. As a
								liquidity provider, add an equal value of each token pair into
								the pool and earn passive income from trading fees based on
								their share.
							</p>
							<p className="spaces pb-16 mb-0">
								You will not be able to open or close pools for 10s after
								opening/closing a pool or executing a trade. Opening and closing
								pools is free. You can have a max of 3 open pools.
							</p>
							<div className="liquidity-info-box">
								<div>
									<div className="info-title">Min. Pool Size</div>
									<div className="info-data">$1,000</div>
								</div>
								<div>
									<div className="info-title">Income Rate</div>
									<div className="info-data">0.5%</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CustomTabsV2 className="spaces mt-24" listTab={LIST_TAB_POOLS} />
		</>
	);
}
