import { Modal } from "react-bootstrap"
import ButtonCustom from "../../../../components/button/ButtonCustom"
import clsx from "clsx"

type TProps = {
    handleClose: () => void;
}

const MarketCapPopup = ({ handleClose }: TProps) => {
    return (
        <>
            <Modal
                contentClassName={clsx("modal-wrapper")}
                show={true}
                onHide={handleClose}
            >
                <div className="modal-content spaces fs-14 mb-12">
                    Market cap is calculated using floor price multiplied by total supply. 
                    Be cautious of risks as certain tokens may exhibit low liquidity and significant market fluctuations.
                </div>
                <div className="modal-btns text-center">
                    <ButtonCustom
                        label="Ok"
                        btnClassName="button button-grey-light spaces px-20 w-100"
                        handleClick={handleClose}
                    />
                </div>
            </Modal>
        </>
    )
}

export default MarketCapPopup