export function shortenAddress (fullAddress: string) {
  if (fullAddress.length >= 13) {
    return (
      fullAddress.substring(0, 5) +
      "..." +
      fullAddress.substring(fullAddress.length - 5)
    );
  } else {
    return fullAddress;
  }
}

const getAddressSize = (address: string) => {
  let addrSize = 25 + 1; // p2pkh
  if (address.indexOf("bc1q") === 0 || address.indexOf("tb1q") === 0) {
    addrSize = 22 + 1;
  } else if (address.indexOf("bc1p") === 0 || address.indexOf("tb1p") === 0) {
    addrSize = 34 + 1;
  } else if (address.indexOf("2") === 0 || address.indexOf("3") === 0) {
    addrSize = 23 + 1;
  }
  return addrSize;
};

export const stringToDataURL = (str: string) => {
  return `data:text/plain;charset=utf-8;base64,${btoa(str)}`;
};
const baseSize = 88;

export const calculateNetworkFee = ({
  dataURL,
  address,
  feeRate,
  fileCount = 1,
}: {
  dataURL: string;
  address: string;
  feeRate: number;
  fileCount?: number;
}) => {
  const addrSize = getAddressSize(address);

  const { fileSize, contentTypeSize } =
    getContentTypeSizeAndFileSizeFromDataURL(dataURL);

  let networkSats = Math.ceil(
    ((fileSize + contentTypeSize) / 4 + (baseSize + 8 + addrSize + 8 + 23)) *
    feeRate
  );
  if (fileCount > 1) {
    networkSats = Math.ceil(
      ((fileSize + contentTypeSize) / 4 +
        (baseSize +
          8 +
          addrSize +
          (35 + 8) * (fileCount - 1) +
          8 +
          23 +
          (baseSize + 8 + addrSize + 0.5) * (fileCount - 1))) *
      feeRate
    );
  }
  return networkSats;
};

export const calculateFeeBySize = ({
  feeFileSize,
  contentTypeSize,
  address,
  feeRate,
  fileCount = 1,
}: {
  feeFileSize: number;
  contentTypeSize: number;
  address: string;
  feeRate: number;
  fileCount?: number;
}) => {
  const feeFileCount = 25;
  const addrSize = getAddressSize(address);

  let networkSatsByFeeCount = Math.ceil(
    ((feeFileSize + contentTypeSize) / 4 + (baseSize + 8 + addrSize + 8 + 23)) *
    feeRate
  );
  if (fileCount > 1) {
    networkSatsByFeeCount = Math.ceil(
      ((feeFileSize + contentTypeSize * (feeFileCount / fileCount)) / 4 +
        (baseSize +
          8 +
          addrSize +
          (35 + 8) * (fileCount - 1) +
          8 +
          23 +
          (baseSize + 8 + addrSize + 0.5) *
          Math.min(fileCount - 1, feeFileCount - 1))) *
      feeRate
    );
  }

  return networkSatsByFeeCount * 0.0499;
};

export function satsToDollars (sats: number, bitcoinPrice: number) {
  if (sats >= 100000000) sats = sats * 10;
  let value_in_dollars =
    Number(
      String(sats).padStart(8, "0").slice(0, -9) +
      "." +
      String(sats).padStart(8, "0").slice(-9)
    ) * bitcoinPrice;
  return value_in_dollars.toFixed(2);
}

export function getFileTypeAndBase64FromDataURL (dataURL: string) {
  const regex = /^data:(.+);base64,/; // Regular expression to match the data URL format

  const result = dataURL.match(regex);

  if (result && result[1]) {
    const fileType = result[1]; // Extract the MIME type

    // Extract the base64-encoded data
    const base64Data = dataURL.split(",")[1];

    return {
      fileType: fileType,
      base64Data: base64Data,
    };
  } else {
    return null; // Unable to determine the MIME type and base64 data
  }
}

export function getContentTypeSizeAndFileSizeFromDataURL (dataURL: string) {
  const encoder = new TextEncoder();
  const { fileType, base64Data } = getFileTypeAndBase64FromDataURL(dataURL)!;
  const bytes = encoder.encode(fileType);

  const raw = atob(base64Data);

  return {
    fileSize: raw.length,
    contentTypeSize: bytes.length,
  };
}
export function truncateMiddle (
  fullStr: string,
  strLen = 8,
  separator = "...",
  frontChars = 3,
  backChars = 4
) {
  if (fullStr.length <= strLen) return fullStr;

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
}

export const toNumberFormat = (value: number | null | undefined) => {
  if (!value) {
    return "0";
  }
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export function formatNumber (number: number, currencySymbol = '') {
  var numberStr = String(number.toFixed(2)); // Convert number to string with two decimal places
  var parts = numberStr.split('.'); // Split into integer and decimal parts
  var integerPart = parts[0];
  var decimalPart = parts[1];

  // Add commas for every three digits in the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the integer and decimal parts with the appropriate separators
  var formattedMoney = integerPart;

  // Add currency symbol
  formattedMoney = currencySymbol + formattedMoney;

  return formattedMoney;
}