import { useQuery } from "react-query";
import Panination from "../../../../components/pagination/Pagination";
import TableCustom from "../../../../components/table/TableCustom";
import { QUERY_KEY, truncateMiddle } from "../../../../../utils";
import { useAuctionOrderParamStore } from "../../../../../store/auction-order-param.store";
import useGetBitcoinPrices from "../../../../../hooks/useGetBitcoinPrices";
import moment from "moment";
import FunbitService from "../../../../../services/funbitServices";
import { AuctionOrderResponse } from "../../../../../../types/marketplace-service";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const BTC_TO_SATS = 100000000;

const OrdersTab = () => {
	const { auctionOrderParam, setAuctionOrderParam } =
		useAuctionOrderParamStore();
	const { data: auctionOrderData } = useQuery({
		queryKey: [QUERY_KEY.GET_AUCTION_LIST, auctionOrderParam],
		queryFn: () => FunbitService.marketAuctionOrderRequest(auctionOrderParam),
	});

	const location = useLocation();

	useEffect(() => {
		if (!location.pathname.includes(auctionOrderParam.tick)) {
			setAuctionOrderParam({
				tick: location.pathname.substring(
					location.pathname.length - auctionOrderParam.tick.length
				),
			});
		}
	}, [auctionOrderParam.tick, location.pathname, setAuctionOrderParam]);

	const handleChangePage = (page: number, pageSize: number) => {
		setAuctionOrderParam({
			start: page,
			limit: pageSize,
		});
	};
	const btcPrice = useGetBitcoinPrices();
	const columns = [
		{
			name: "Inscription",
			field: "inscription",
			classHeader: "text-center text-capitalize spaces fs-14",
			classCell: "text-center text-capitalize spaces fw-5 fs-14",
			render: (rowData: AuctionOrderResponse) => {
				return (
					<a
						className="text-decoration-underline"
						href={`https://mempool.space/testnet/tx/${rowData.inscription_id.slice(
							0,
							-2
						)}`}
					>
						#{rowData?.inscription?.inscription_number}
					</a>
				);
			},
		},
		{
			name: "Event",
			field: "event",
			classHeader: "text-center text-capitalize spaces fs-14",
			classCell: "text-center text-capitalize spaces fw-5 fs-14",
			render: (rowData: AuctionOrderResponse) => {
				return (
					<div
						className={`
                        ${
													rowData?.kind === "list"
														? "text-color-green"
														: rowData?.kind === "sold"
														? "text-color-red"
														: ""
												}`}
					>
						{rowData?.kind}
					</div>
				);
			},
		},
		{
			name: "Price",
			field: "price",
			classHeader: "text-center text-capitalize spaces fs-14",
			classCell: "text-start text-lowercase spaces fw-5 fs-14",
			render: (rowData: AuctionOrderResponse) => {
				const satsPrice =
					btcPrice * (rowData.inscription.listing.init_price / BTC_TO_SATS);
				return (
					<div className="text-center text-color-gray">
						<div>
							<span className="text-color-white spaces mr-10">
								{rowData?.inscription?.listing?.unit_price}
							</span>
							sats/
							<span
								style={{
									fontFamily: "Proto Mono, Helvetica, Arial, sans-serif",
								}}
							>
								{auctionOrderParam?.tick}
							</span>
						</div>
						<div>$ {satsPrice.toFixed(4)}</div>
					</div>
				);
			},
		},
		{
			name: "Quantity",
			field: "quantity",
			classHeader: "text-center text-capitalize spaces fs-14",
			classCell: "text-end text-capitalize spaces fw-5 fs-14",
			render: (rowData: AuctionOrderResponse) => {
				return (
					<div className="text-center text-color-gray">
						{rowData?.inscription?.amount}
					</div>
				);
			},
		},
		{
			name: "Total Value",
			field: "totalValue",
			classHeader: "text-center text-capitalize spaces fs-14",
			classCell: "text-start text-lowercase spaces fw-5 fs-14",
			render: (rowData: AuctionOrderResponse) => {
				const satsPrice =
					btcPrice * (rowData?.inscription?.listing?.init_price / BTC_TO_SATS);
				return (
					<div className="text-center text-color-gray">
						<div>
							<span className="text-color-white spaces mr-10">
								{rowData?.inscription?.listing?.init_price}
							</span>
							sats
						</div>
						<div>$ {satsPrice.toFixed(4)}</div>
					</div>
				);
			},
		},
		{
			name: "From",
			field: "from",
			classHeader: "text-center text-capitalize spaces fs-14",
			classCell: "text-center text-capitalize spaces fw-5 fs-14",
			render: (rowData: AuctionOrderResponse) => {
				return (
					<div className="text-center text-color-gray">
						{truncateMiddle(rowData?.seller)}
					</div>
				);
			},
		},
		{
			name: "To",
			field: "to",
			classHeader: "text-center text-capitalize spaces fs-14",
			classCell: "text-center text-capitalize spaces fw-5 fs-14",
			render: (rowData: AuctionOrderResponse) => {
				return (
					<div className="text-center text-color-gray">
						{truncateMiddle(rowData?.buyer || "-")}
					</div>
				);
			},
		},
		{
			name: "Time",
			field: "time",
			classHeader: "text-center text-capitalize spaces fs-14",
			classCell: "text-center text-capitalize spaces fw-5 fs-14",
			render: (rowData: AuctionOrderResponse) => {
				return (
					<div className="text-center text-color-gray">
						{moment(rowData?.date).format("MM/DD/YYYY, h:mm:ss A")}
					</div>
				);
			},
		},
	];

	return (
		<>
			{auctionOrderData && (
				<>
					<TableCustom columns={columns} data={auctionOrderData?.list} />
					<div className="text-center">
						<Panination
							current={auctionOrderParam.start}
							pageSize={auctionOrderParam.limit}
							onChange={handleChangePage}
							total={auctionOrderData?.total || 0}
						/>
					</div>
				</>
			)}
		</>
	);
};

export default OrdersTab;
