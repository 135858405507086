import { useMutation } from "react-query";
import FunbitService from "../services/funbitServices";

type TUseCreateOrder = {
	onSuccess: (data: any) => void;
};

const useCreateOrder = ({ onSuccess }: TUseCreateOrder) => {
	const {
		mutate,
		isLoading,
		isError,
		isSuccess,
		data: dataResponse,
	} = useMutation(FunbitService.createOrder, {
		onSuccess,
	});

	return {
		mutate,
		isLoading,
		isError,
		isSuccess,
		data: dataResponse?.data?.data,
	};
};

export default useCreateOrder;
