import TableCustom from "../../../components/table/TableCustom";

const AllTradesTab = () => {
	const columns = [
		{
			name: "Asset",
			field: "asset",
			classHeader: "text-start",
			classCell: "text-start",
			render: (rowData: any) => (
				<div className="d-flex align-items-center">
					<img width={20} src={rowData.assetImg} alt="" />
					<div className="spaces ml-8 ">{rowData.assetName}</div>
				</div>
			),
		},
		{
			name: "Trader",
			field: "trader",
			classHeader: "text-start",
			classCell: "text-start",
			render: (rowData: any) => (
				<div className="d-flex align-items-center">
					<img width={18} src={rowData.rankTrader} alt="" />
					<div className="spaces ml-8 text-capitalize">
						{rowData.traderName}
					</div>
				</div>
			),
		},
		{
			name: "Size",
			field: "size",
			classHeader: "text-end",
			classCell: "text-end",
			render: (rowData: any) => (
				<div className="text-color-green">{rowData.size}</div>
			),
		},
		{
			name: "Avg. Price",
			field: "avgPrice",
			classHeader: "text-end",
			classCell: "text-end",
		},
		{
			name: "Value",
			field: "value",
			classHeader: "text-end",
			classCell: "text-end",
		},
		{
			name: "Time Ago",
			field: "timeAgo",
			classHeader: "text-end",
			classCell: "text-end",
		},
		{
			name: "Last Price",
			field: "lastPrice",
			classHeader: "text-end",
			classCell: "text-end",
		},
	];

	const dataTable = [
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			assetName: "FUNBIT",
			rankTrader: "/media/images/userRank/platinum_1star.png",
			traderName: "Hidden",
			size: "240.37",
			avgPrice: "$0.165088",
			value: "$39.68",
			timeAgo: "1s",
			lastPrice: "$0.165089",
		},
	];

	return (
		<>
			<TableCustom columns={columns} data={dataTable} />
		</>
	);
};

export default AllTradesTab;
