/* eslint-disable react/jsx-no-target-blank */
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { NAVIGATIONS, NAVIGATION_TYPES } from "../constant";
import MarketPrices from "../../../../../app/modules/trading/components/MarketPrices";
import { Fragment } from "react";

const renderSidebar = (sidebar: any) => {
	switch (sidebar.type) {
		case NAVIGATION_TYPES.SIDEBAR_GROUP:
			return sidebar.component;
		case NAVIGATION_TYPES.SIDEBAR_WITH_SUB:
			return (
				<SidebarMenuItemWithSub
					to={sidebar.path}
					title={sidebar.title}
					fontIcon="bi-chat-left"
					icon={sidebar.icon}
					iconSize={sidebar.iconSize}
				>
					{sidebar.menuItems.map((menuItem: any) => (
						<SidebarMenuItem
							key={menuItem.title}
							to={menuItem.path}
							title={menuItem.title}
							icon={menuItem.icon}
							iconSize={menuItem.iconSize}
						/>
					))}
				</SidebarMenuItemWithSub>
			);
		case NAVIGATION_TYPES.SIDERBAR_ITEM:
			return (
				<SidebarMenuItem
					to={sidebar.path}
					title={sidebar.title}
					icon={sidebar.icon}
					fontIcon="bi-app-indicator"
					iconSize={sidebar.iconSize}
					target={sidebar?.target}
				/>
			);
		case NAVIGATION_TYPES.SIDERBAR_TRADING:
			return (
				<SidebarMenuItemWithSub
					to={sidebar.path}
					title={sidebar.title}
					fontIcon="bi-chat-left"
					icon={sidebar.icon}
					iconSize={sidebar.iconSize}
				>
					<MarketPrices />
				</SidebarMenuItemWithSub>
			);
	}
};

const SidebarMenuMain = () => {
	return (
		<>
			{NAVIGATIONS.map((sidebar, idx) => {
				return <Fragment key={idx}>{renderSidebar(sidebar)}</Fragment>;
			})}
		</>
	);
};

export { SidebarMenuMain };
