import React from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import TextField from "../../../components/text-field/TextField";
import ButtonCustom from "../../../components/button/ButtonCustom";

export default function WalletWithdrawTabs() {
	return (
		<>
			<div className="content-box">
				<div>
					<div className="des-box">
						Please enter the Funbit Coin wallet address you wish to receive the
						funds on. Once confirmed, the withdrawal is usually processed within
						a few minutes.
					</div>
					<div className="warning-box">
						<KTSVG
							svgClassName="icon-warning"
							path="/media/svg/action/warning.svg"
						/>
						Please ensure that the receiving ETH address supports Funbit Coin
						before requesting a withdrawal
					</div>
				</div>
				<div className="coin-network spaces mt-24">
					<div>
						FUNBIT withdrawals are available solely through the
						<img
							width={20}
							className="spaces mr-6 ml-4"
							src="/media/svg/coins/eth.png"
							alt=""
						/>
						ETH Network
					</div>
				</div>
				<TextField
					required
					label={`RECEIVING  FUNBIT ADDRESS`}
					placeholder={`Paste your Funbit coin wallet address here`}
					inputClassName="spaces h-50"
				/>
				<div className="amount-box">
					<TextField
						required
						label="WITHDRAWAL AMOUNT"
						inputClassName="spaces h-50"
						inputStart={
							<>
								<img
									className="spaces mr-10"
									src="/media/images/coin.webp"
									width={20}
									height={20}
									alt=""
								/>
							</>
						}
					/>
					<ButtonCustom
						label="Request withdrawal"
						btnClassName="button button-green"
					/>
				</div>
				<div className="des-detail spaces fs-12 mt-8">Network Fee: $2.28</div>
				<div className="des-detail spaces fs-12 mt-16">
					*You will receive the specified Ethereum amount to your withdrawal
					address
				</div>
			</div>

			<div className="view-transaction">View Transactions</div>
		</>
	);
}
