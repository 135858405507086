import React from "react";
import ButtonCustom from "../../../components/button/ButtonCustom";
import { KTSVG } from "../../../../../_metronic/helpers";
import AddressBar from "../../../cashier/components/address-bar/AddressBar";

export default function WalletDepositTab() {
  return (
    <div className="wallet-deposit">
      <div className="coin-network">
        <div>
          You are depositing via
          <img width={20} className="spaces mr-6 ml-4" src="/media/svg/coins/eth.png" alt="" />
          ETH Network
        </div>
        <ButtonCustom
          btnClassName="button button-grey"
          label="Use SOL Network"
          imgPath="/media/svg/coins/sln.png"
        />
      </div>
      <div className="content-box">
        <div className="deposit-box">
          <div>
            <div className="des-box">
              Send the amount of Funbit Coin of your choice to the following address to be
              deposited to your Funbit Coin wallet on-site.
            </div>
            <div className="warning-box">
              <KTSVG svgClassName="icon-warning" path="/media/svg/action/warning.svg" />
              Only deposit over the ETH network. Do not use BNB or BSC networks
            </div>
            <div className="warning-box">
              <KTSVG svgClassName="icon-warning" path="/media/svg/action/warning.svg" />
              FUNBIT has migrated to Ethereum. FUNBIT withdrawals are available solely through the ETH
              network.
            </div>
          </div>
          <img className="qr-code" src="/media/svg/coins/qr_code.png" alt="" />
        </div>
        <div className="text-white spaces mt-16 mb-12 mx-0 text-uppercase fw-7 fs-12">
          Your personal FUNBIT deposit address
        </div>
        <AddressBar />
        <div className="other-option">
          Missing contract ETH deposit? <span>Request deposit address sweep</span>
        </div>
      </div>
      <div className="view-transaction">View Transactions</div>
    </div>
  );
}
