import { useQuery } from "react-query";
import Pagination from "../../../../components/pagination/Pagination";
import SliderNormal from "../../../../components/slider/SliderNormal";
import TradeCard from "./TradeCard";
import { AuctionListResponse } from "../../../../../../types/marketplace-service";
import { QUERY_KEY } from "../../../../../utils";
import { useAuctionListParamStore } from "../../../../../store/auction-list-param.store";
import {
	createRef,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import FunbitService from "../../../../../services/funbitServices";
import { useLocation } from "react-router-dom";
import { useWallet } from "../../../../../../providers/wallet_provider";

export const refListed = createRef<any>();

export const refListedControl = {
	refetch: () => refListed.current?.refetch(),
};

const ListedTab = forwardRef((_, ref) => {
	const { auctionListParam, setAuctionListParam } = useAuctionListParamStore();
	const location = useLocation();

	const { unisat } = useWallet();

	const [pubKey, setPubKey] = useState<string>("");
	const { data: auctionListData, refetch } = useQuery({
		queryKey: [QUERY_KEY.GET_AUCTION_LIST, auctionListParam],
		queryFn: () => FunbitService.marketAuctionListRequest(auctionListParam),
	});

	useEffect(() => {
		if (!location.pathname.includes(auctionListParam.tick)) {
			setAuctionListParam({
				tick: location.pathname.substring(
					location.pathname.length - auctionListParam.tick.length
				),
			});
		}
	}, [auctionListParam.tick, location.pathname, setAuctionListParam]);

	const handleChangePage = (page: number, pageSize: number) => {
		setAuctionListParam({
			start: page,
			limit: pageSize,
		});
	};

	useImperativeHandle(ref, () => {
		return { refetch };
	});

	useEffect(() => {
		(async () => {
			const key = await unisat?.getPublicKey();
			setPubKey(key || "");
		})();
	}, [unisat]);

	return (
		<>
			<SliderNormal
				classSilder="w-100 position-relative spaces mt-13 slider-nowrap grid-columns-150"
				sliderList={auctionListData?.list || []}
				sliderItem={(dataItem: AuctionListResponse) => (
					<TradeCard data={dataItem} pubKey={pubKey} />
				)}
				nowrap={false}
				itemQuantity={{ laptop: 5, tablet: 3, mobile: 2 }}
			/>
			{auctionListData?.list?.length! > 0 && (
				<div className="spaces mt-16 text-center">
					<Pagination
						current={auctionListParam.start}
						pageSize={auctionListParam.limit}
						onChange={handleChangePage}
						total={auctionListData?.total || 0}
					/>
				</div>
			)}
		</>
	);
});

export default ListedTab;
