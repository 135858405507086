import { useEffect, useState } from "react";
import ButtonCustom from "../../components/button/ButtonCustom";
import ProofRadio from "../../components/text-field/ProofRadio";
import TextareaCustom from "../../components/textarea/TextareaCustom";
import DoubleCheckStep from "./DoubleCheckStep";
import InscribingOrderStep from "./InscribingOrderStep";
import { Inscription_Kind } from "../../../utils";

const RADIO_ID_LIST = {
	SINGLE: "1",
	BULK: "2",
};

const radioList = [
	{
		label: "Single",
		id: RADIO_ID_LIST.SINGLE,
	},
	{
		label: "Bulk",
		id: RADIO_ID_LIST.BULK,
	},
];

const TextTab = ({ resetField }: { resetField?: number }) => {
	const [placeholderText, setPlaceholderText] =
		useState<string>("any text here");
	const [textValue, setTextValue] = useState("");
	const [step, setStep] = useState(1);
	const [inscribeList, setInscribeList] = useState<any>(undefined);
	const [radioId, setRadioId] = useState(RADIO_ID_LIST.SINGLE);

	const handleChangeChecked = (radioId: string) => {
		setRadioId(radioId);
		radioId === RADIO_ID_LIST.SINGLE
			? setPlaceholderText("any text here")
			: setPlaceholderText("abcd\nfunbit\n1234\netc...");
	};

	const handleNextStep = () => {
		textValue && setStep((prevStep) => prevStep + 1);

		if (radioId === RADIO_ID_LIST.SINGLE) {
			textValue && setInscribeList([textValue]);
		} else {
			const inscribeListTemp = textValue ? textValue.split("\n") : undefined;
			setInscribeList(inscribeListTemp);
		}
	};

	const handlePrevStep = () => {
		setStep((prevStep) => prevStep - 1);
	};

	useEffect(() => {
		setStep(1);
		setInscribeList([]);
		setRadioId(RADIO_ID_LIST.SINGLE);
		setTextValue("");
		setPlaceholderText("any text here");
	}, [resetField]);

	return (
		<div className="inscribe-tab-container">
			<div className="linear-gradient-blur"></div>
			{step === 1 && (
				<>
					<div className="spaces fs-14">
						If single, we will inscribe exactly what is there.
						<br />
						If bulk, we will inscribe one for every new line.
					</div>
					<ProofRadio
						radioClassName="spaces mt-16 d-flex gap-36"
						labelClassName="spaces fs-16"
						radioName="radioTextTab"
						radioList={radioList}
						radioIdChecked={radioId}
						handleChangeChecked={(radioId) => handleChangeChecked(radioId)}
					/>
					{/* {!inscribeList && (
                        <div className="spaces mt-16 fw-bold text-color-white">
                            No Valid text Found. Please check your text and try again.
                        </div>
                    )} */}
					<TextareaCustom
						classTextarea="spaces mt-16"
						rows={8}
						placeholder={placeholderText}
						value={textValue}
						handleChange={(value) => setTextValue(value)}
					/>
					<ButtonCustom
						btnClassName="button button-yellow spaces mt-16 w-100"
						labelClassName="spaces fs-14"
						label="Next"
						handleClick={handleNextStep}
					/>
				</>
			)}
			{step === 2 && (
				<DoubleCheckStep
					handleNextStep={handleNextStep}
					handlePrevStep={handlePrevStep}
					inscribeList={inscribeList}
				/>
			)}
			{step === 3 && (
				<InscribingOrderStep
					inscriptionKind={Inscription_Kind.TEXT}
					inscribeList={inscribeList}
					handlePrevStep={() => {
						setStep(1);
						setTextValue("");
					}}
				/>
			)}
		</div>
	);
};

export default TextTab;
