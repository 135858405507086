import { Badge, ProgressBar } from "react-bootstrap";
import TableCustom from "../components/table/TableCustom";

export const dataDetailCoin = [
	{
		title: "Inscription",
		isLink: true,
		value: "ba5ed36117cb044135f6d480234653735607fcb59",
		field: "inscription_id",
	},
	{
		title: "Supply",
		value: "1,000,000,000,000",
		field: "max",
		isNumber: true,
	},
	{
		title: "Minted",
		value: "1,000,000,000,000",
		field: "minted",
		isNumber: true,
	},
	{
		title: "Limit per mint",
		value: "1,000,000",
		field: "limit",
		isNumber: true,
	},
	// { title: 'Decimal', value: '18' },
	{
		title: "Deploy By",
		isLink: true,
		value: "bc1qgfn7frhkhyzqus2l7wswe4z63chwdvf6waf6mk",
		field: "creator",
	},
	{
		title: "Deploy Time",
		value: "22:55:07 3/12/2023",
		field: "deploy_blocktime",
	},
	{
		title: "Completed Time",
		value: "11:51:12 15/1/2024",
		field: "complete_blocktime",
	},
	{
		title: "Inscription Number Start",
		value: "#45733545",
		field: "inscription_number_start",
	},
	{
		title: "Inscription Number End",
		value: "#45733566",
		field: "inscription_number_end",
	},
	{ title: "Holders", value: "111,19", field: "holders_count" },
	// {
	// 	title: "Total Transactions",
	// 	value: "1,110,565",
	// 	field: "total_transactions",
	// },
];

export const COIN_DETAIL_NAV = [
	{
		label: "View",
		toPath: "/detailCoin",
	},
	{
		label: "Trade",
		toPath: "/marketplace/brc20",
	},
];

export const columns2 = [
	{
		name: "Rank",
		field: "rank",
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start text-capitalize spaces fw-6 fs-14",
	},
	{
		name: "Address",
		field: "address",
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start text-capitalize spaces fw-6 fs-14 address-link",
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16",
		classCell: "text-center spaces fw-6 fs-14",
		name: "Percentage",
		field: "percentage",
		render: (rowData: any) => (
			<div className="d-flex flex-column align-items-start">
				<span>{`${rowData.percentage} %`}</span>
				<div className="w-100">
					<ProgressBar
						variant="success"
						className="spaces h-8"
						now={rowData.percentage}
					/>
				</div>
			</div>
		),
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Value",
		field: "value",
	},
];
export const columns3 = [
	{
		name: "Number",
		field: "number",
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start text-capitalize spaces fw-6 fs-14 text-underline",
	},
	{
		classHeader: "text-end text-capitalize spaces fs-16",
		classCell: "text-end spaces fw-6 fs-14",
		name: "Method",
		field: "method",
		render: (rowData: any) => (
			<div className="d-flex flex-column align-items-start">
				<div className="w-100">
					<Badge bg="secondary" className="text-white spaces p-8 fs-12">
						{rowData.method}
					</Badge>
				</div>
			</div>
		),
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16",
		classCell: "text-center spaces fw-6 fs-14",
		name: "Quantity",
		field: "quantity",
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16",
		classCell: "text-center spaces fw-6 fs-14",
		name: "Balance",
		field: "balance",
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16",
		classCell: "text-center spaces fw-6 fs-14 text-underline",
		name: "From",
		field: "from",
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16",
		classCell: "text-center spaces fw-6 fs-14 text-underline",
		name: "To",
		field: "to",
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16",
		classCell: "text-center spaces fw-6 fs-14 text-underline",
		name: "Date Time",
		field: "dateTime",
	},
];

export const tableData = [
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userRank/platinum_1star.png",
		address: "16G1x...p9Wxh",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "1",
		percentage: 20.55,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 0",
		payout: "Active",
	},
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userIcon3.webp",
		address: "16G1x...p9Wxh",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "2",
		percentage: 60.33,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 1",
		payout: "Active",
	},
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userRank/platinum_1star.png",
		address: "16G1x...233",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "3",
		percentage: 75.28,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 2",
		payout: "Active",
	},
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userRank/platinum_1star.png",
		address: "16G1x...p9Wxh",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "4",
		percentage: 87.96,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 3",
		payout: "Active",
	},
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userIcon1.webp",
		address: "26G1x...p9Wxh",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "5",
		percentage: 12.33,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 4",
		payout: "Active",
	},
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userRank/platinum_1star.png",
		address: "16G1x...p9Wxh",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "1",
		percentage: 20.55,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 0",
		payout: "Active",
	},
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userIcon3.webp",
		address: "16G1x...p9Wxh",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "2",
		percentage: 60.33,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 1",
		payout: "Active",
	},
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userRank/platinum_1star.png",
		address: "16G1x...233",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "3",
		percentage: 75.28,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 2",
		payout: "Active",
	},
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userRank/platinum_1star.png",
		address: "16G1x...p9Wxh",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "4",
		percentage: 87.96,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 3",
		payout: "Active",
	},
	{
		toPath: "/play-game",
		gameImgPath: "/media/images/userIcon1.webp",
		address: "26G1x...p9Wxh",
		playerRankImg: "/media/images/userRank/platinum_1star.png",
		rank: "5",
		percentage: 12.33,
		value: "39,932,731,501.98529322",
		multiplier: "Lv 4",
		payout: "Active",
	},
];

const tableData2 = [
	{
		number: "#57007055",
		method: "Transfer",
		quantity: 100000000,
		balance: "350,000,000",
		from: "bc1py...3v99q",
		to: "bc1py...3v99q",
		dateTime: "11:53:04 5/2/2024",
	},
	{
		number: "#57007056",
		method: "Credit Card",
		quantity: 75000000,
		balance: "-",
		from: "Cardholder X",
		to: "Merchant Y",
		dateTime: "09:20:15 5/2/2024",
	},
	{
		number: "#57007057",
		method: "Bank Transfer",
		quantity: 50000000,
		balance: "350,000,000",
		from: "Sender P",
		to: "Receiver Q",
		dateTime: "13:45:30 5/2/2024",
	},
	{
		number: "#57007058",
		method: "Transfer",
		quantity: 200000000,
		balance: "-",
		from: "bc1py...7x33z",
		to: "bc1py...7x33z",
		dateTime: "15:10:22 5/2/2024",
	},
	{
		number: "#57007059",
		method: "Credit Card",
		quantity: 120000000,
		balance: "350,000,000",
		from: "Cardholder Z",
		to: "Merchant W",
		dateTime: "18:05:48 5/2/2024",
	},
	{
		number: "#57007060",
		method: "Bank Transfer",
		quantity: 30000000,
		balance: "-",
		from: "Sender M",
		to: "Receiver N",
		dateTime: "10:22:18 5/3/2024",
	},
	{
		number: "#57007061",
		method: "Transfer",
		quantity: 80000000,
		balance: "-",
		from: "bc1py...4u88w",
		to: "bc1py...4u88w",
		dateTime: "12:40:55 5/3/2024",
	},
	{
		number: "#57007062",
		method: "Credit Card",
		quantity: 60000000,
		balance: "350,000,000",
		from: "Cardholder Q",
		to: "Merchant R",
		dateTime: "14:15:10 5/3/2024",
	},
	{
		number: "#57007063",
		method: "Bank Transfer",
		quantity: 45000000,
		balance: "350,000,000",
		from: "Sender K",
		to: "Receiver L",
		dateTime: "17:30:45 5/3/2024",
	},
	{
		number: "#57007064",
		method: "Transfer",
		quantity: 120000000,
		balance: "350,000,000",
		from: "bc1py...9s77v",
		to: "bc1py...9s77v",
		dateTime: "19:55:21 5/3/2024",
	},
];

export const COIN_DETAIL_TAB = [
	{
		nav: {
			eventKey: "1",
			label: "Holders",
		},
		content: {
			component: (
				<TableCustom
					className="table-content"
					columns={columns2}
					data={tableData}
				/>
			),
		},
	},
	{
		nav: {
			eventKey: "2",
			label: "Transfers",
		},
		content: {
			component: (
				<TableCustom
					className="table-content"
					columns={columns3}
					data={tableData2}
				/>
			),
		},
	},
];

export const SUB_NAVIGATIONS_MARKETPLACE = [
	{
		iconPath: "/media/svg/icons/home.svg",
		svgClassName: "spaces h-16 w-16 ml-0",
		toPath: "/marketplace",
	},
];

export const columns4 = [
	{
		name: "",
		field: "index",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start text-capitalize spaces fw-6 fs-14",
	},
	{
		name: "Tick",
		field: "tick",
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start text-capitalize spaces fw-6 fs-14",
		render: (rowData: any) => (
			<div className="d-flex align-items-center">
				<img width={18} src={rowData.iconTick} alt="" />
				<div className="spaces ml-8 fs-16">{rowData?.tick}</div>
			</div>
		),
	},
	{
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-center spaces fw-6 fs-14",
		name: "Volume",
		field: "volume",
		render: (rowData: any) => (
			<div className="d-flex align-items-center">
				<img width={18} src={rowData.iconVolume} alt="" />
				<div className="spaces ml-8 ">
					{rowData?.volume}
					<div className="spaces fs-12 text-color-gray">$29,872.9</div>
				</div>
			</div>
		),
	},
	{
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start spaces fw-6 fs-14",
		name: "Price",
		field: "marketCap",
		render: (rowData: any) => (
			<div className="d-flex align-items-center">
				<div className="spaces ml-8 ">
					{rowData?.marketCap}
					<div className="spaces fs-12 text-color-gray">$2.4M</div>
				</div>
			</div>
		),
	},
	{
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start spaces fw-6 fs-14",
		name: "Market cap",
		field: "price",
		render: (rowData: any) => (
			<div className="d-flex align-items-center">
				<img width={18} src={rowData.iconVolume} alt="" />
				<div className="spaces ml-8 ">
					{rowData?.marketCap}
					<div className="spaces fs-12 text-color-gray">$0.12</div>
				</div>
			</div>
		),
	},
	{
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start spaces fw-6 fs-14",
		name: "Change",
		field: "change",
		render: (rowData: any) => (
			<div className="text-color-green ">{rowData?.change}</div>
		),
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16",
		classCell: "text-center spaces fw-6 fs-14",
		name: "Num of sales",
		field: "updownNumOfSales",
	},
	{
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start spaces fw-6 fs-14",
		name: "Holders",
		field: "holders",
		render: (rowData: any) => (
			<div>
				{rowData?.holders}
				<div className="spaces fs-12 text-color-green">2.53M</div>
			</div>
		),
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16",
		classCell: "text-center spaces fw-6 fs-14",
		name: "Holders",
		field: "holders",
		render: (rowData: any) => <i className="bi bi-star-fill spaces fs-16"></i>,
	},
];

export const data4 = [
	{
		tick: "Fun",
		index: "1",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		price: "$484,804.8",
		marketCap: "0.028 sats",
		change: "+0.012%",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Fun",
		index: "2",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		price: "$484,804.8",
		marketCap: "0.028 sats",
		change: "+0.012%",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Fun",
		index: "3",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		price: "$484,804.8",
		marketCap: "0.028 sats",
		change: "+0.012%",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Fun",
		index: "4",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		price: "$484,804.8",
		marketCap: "0.028 sats",
		change: "+0.012%",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Fun",
		index: "1",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		price: "$484,804.8",
		marketCap: "0.028 sats",
		change: "+0.012%",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Fun",
		index: "5",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		price: "$484,804.8",
		marketCap: "0.028 sats",
		change: "+0.012%",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Fun",
		index: "6",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		price: "$484,804.8",
		marketCap: "0.028 sats",
		change: "+0.012%",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "Fun",
		index: "7",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		price: "$484,804.8",
		marketCap: "0.028 sats",
		change: "+0.012%",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "ordinals_nft_icon",
		index: "8",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 15.789,
		updown: "Fun",
		price: "$678,942.5",
		marketCap: "0.032 sats",
		change: "0.000018%",
		numOfSales: "Fun",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
	},
	{
		tick: "Fun",
		index: "9",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		price: "$484,804.8",
		marketCap: "0.028 sats",
		change: "+0.012%",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
	},
	{
		tick: "ordinals_nft_icon",
		index: "10",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 15.789,
		updown: "Fun",
		price: "$678,942.5",
		marketCap: "0.032 sats",
		change: "0.000018%",
		numOfSales: "Fun",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
	},
	{
		tick: "Fun",
		index: "11",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 20.123,
		updown: "Fun",
		price: "$800,000.0",
		marketCap: "0.035 sats",
		change: "0.000025%",
		numOfSales: "Fun",
		holders: 400.555,
		updownMarketCap: "$18.75M",
		updownChange: "-2.15%",
		updownNumOfSales: 80,
		updownHolders: "15,200",
		updownChangePercentage: "+2.40%",
	},
	{
		tick: "Fun",
		index: "12",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 25.456,
		updown: "Fun",
		price: "$1,200,345.6",
		marketCap: "0.040 sats",
		change: "0.000032%",
		numOfSales: "Fun",
		holders: 512.789,
		updownMarketCap: "$22.60M",
		updownChange: "+4.78%",
		updownNumOfSales: 150,
		updownHolders: "18,300",
		updownChangePercentage: "-1.80%",
	},
	{
		tick: "Fun",
		index: "13",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 18.789,
		updown: "fake4",
		price: "$900,123.4",
		marketCap: "0.038 sats",
		change: "0.000028%",
		numOfSales: "fake4",
		holders: 450.321,
		updownMarketCap: "$20.45M",
		updownChange: "+3.15%",
		updownNumOfSales: 110,
		updownHolders: "16,500",
		updownChangePercentage: "+1.20%",
	},
	{
		tick: "Fun",
		index: "14",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 20.123,
		updown: "Fun",
		price: "$800,000.0",
		marketCap: "0.035 sats",
		change: "0.000025%",
		numOfSales: "Fun",
		holders: 400.555,
		updownMarketCap: "$18.75M",
		updownChange: "-2.15%",
		updownNumOfSales: 80,
		updownHolders: "15,200",
		updownChangePercentage: "+2.40%",
	},
	{
		tick: "Fun",
		index: "15",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 25.456,
		updown: "Fun",
		price: "$1,200,345.6",
		marketCap: "0.040 sats",
		change: "0.000032%",
		numOfSales: "Fun",
		holders: 512.789,
		updownMarketCap: "$22.60M",
		updownChange: "+4.78%",
		updownNumOfSales: 150,
		updownHolders: "18,300",
		updownChangePercentage: "-1.80%",
	},
	{
		tick: "Fun",
		index: "16",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/svg/coins/bitcoin.svg",
		iconMarketCap: "/media/svg/coins/bitcoin.svg",
		volume: 18.789,
		updown: "fake4",
		price: "$900,123.4",
		marketCap: "0.038 sats",
		change: "0.000028%",
		numOfSales: "fake4",
		holders: 450.321,
		updownMarketCap: "$20.45M",
		updownChange: "+3.15%",
		updownNumOfSales: 110,
		updownHolders: "16,500",
		updownChangePercentage: "+1.20%",
	},
];

export const columnsTable = [
	{
		name: "",
		field: "index",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start text-capitalize spaces fw-6 fs-14",
		render: (rowData: any) => {
			if (rowData?.index <= 3) {
				return (
					<div className="d-flex align-items-center justify-content-center spaces p-4">
						<img
							width={24}
							src={`/media/images/userRank/${rowData?.index}_rank.png`}
							alt=""
						/>
					</div>
				);
			}
			return (
				<div className="d-flex align-items-center justify-content-center">
					{rowData?.index}
				</div>
			);
		},
	},
	{
		name: "Tick",
		field: "tick",
		classHeader: "text-start text-capitalize spaces fs-16 min-w-100px",
		classCell: "text-start text-capitalize spaces fw-6 fs-14 h-80",
		render: (rowData: any) => (
			<div className="d-flex align-items-center">
				<img width={32} src={rowData.iconTick} alt="" />
				<div className="spaces ml-8 fs-16">{rowData?.tick}</div>
			</div>
		),
	},
	{
		classHeader: "text-start text-capitalize spaces fs-16",
		classCell: "text-start spaces fw-6 fs-14",
		name: "Price",
		field: "price",
		render: (rowData: any) => (
			<div className="d-flex align-items-center min-w-100px">
				<div className="spaces ml-8 text-lowercase">
					{rowData?.price}
					<div className="spaces fs-12 text-color-gray fw-5 text-start mt-4">
						$1.48
					</div>
				</div>
			</div>
		),
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16 min-w-80px",
		classCell: "text-center spaces fw-6 fs-14",
		name: "Num of sales",
		field: "updownNumOfSales",
		render: (rowData: any) => <div>{rowData?.updownNumOfSales}</div>,
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16 min-w-80",
		classCell: "text-center spaces fw-6 fs-14",
		name: "Holders",
		field: "holders",
		render: (rowData: any) => (
			<div className="min-w-100px">
				{rowData?.holders}
				<div className="spaces fs-14 text-color-green">+17.18%</div>
			</div>
		),
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16 min-w-100px",
		classCell: "text-center spaces fw-6 fs-14",
		name: "Deployed",
		field: "deployedTime",
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16 min-w-160",
		classCell: "text-center spaces fw-6 fs-14 min-w-160",
		name: "Progress%",
		field: "progress",
		render: (rowData: any) => (
			<div className="d-flex flex-column align-items-start">
				<div className="text-end w-100">{`${rowData.progress} %`}</div>
				<div className="w-100">
					<ProgressBar
						variant="success"
						className="spaces h-8"
						now={rowData.progress}
					/>
				</div>
			</div>
		),
	},
	{
		classHeader: "text-center text-capitalize spaces fs-16 min-w-60px",
		classCell: "text-center spaces fw-6 fs-14 p-4 min-w-60px",
		field: "icon",
		render: (rowData: any) => <i className="bi bi-star-fill spaces fs-16"></i>,
	},
];

export const dataTableAll = [
	{
		tick: "ligo",
		index: "1",
		iconTick: "/media/images/coins/lino.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/08/2023, 11:16",
		progress: "100",
	},
	{
		tick: "AINN",
		index: "2",
		iconTick: "/media/images/coins/AINN.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.804.8",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/08/2023, 11:16",
		progress: "100",
	},
	{
		tick: "INJ",
		index: "3",
		iconTick: "/media/images/coins/AINN.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "-0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/08/2023, 19:44",
		progress: "100",
	},
	{
		tick: "Rats",
		index: "4",
		iconTick: "/media/images/coins/28452.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 10:52",
		progress: "70",
	},
	{
		tick: "drag",
		index: "5",
		iconTick: "/media/images/coins/drag.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:00",
		progress: "50",
	},
	{
		tick: "Bear",
		index: "6",
		iconTick: "/media/images/coins/28483.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:16",
		progress: "90",
	},
	{
		tick: "ligo",
		index: "7",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:16",
		progress: "100",
	},
	{
		tick: "BTC",
		index: "8",
		iconTick: "/media/images/coins/BTC.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:32",
		progress: "60",
	},
	{
		tick: "Moon",
		index: "9",
		iconTick: "/media/images/coins/28482.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 15.789,
		updown: "ligo",
		marketCap: "678,942.5",
		price: "0.032 sats",
		change: "-0.000018",
		numOfSales: "ligo",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
		deployedTime: "03/09/2023, 12:32",
		progress: "70",
	},
	{
		tick: "ligo",
		index: "10",
		iconTick: "/media/images/coins/28452.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:32",
		progress: "90",
	},
	{
		tick: "ETH",
		index: "11",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 15.789,
		updown: "ligo",
		marketCap: "678,942.5",
		price: "0.032 sats",
		change: "-0.000018",
		numOfSales: "ligo",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
		deployedTime: "03/09/2023, 12:39",
		progress: "20",
	},
	{
		tick: "ETH",
		index: "12",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 20.123,
		updown: "fake2",
		marketCap: "800,000.0",
		price: "0.035 sats",
		change: "-0.000025",
		numOfSales: "fake2",
		holders: 400.555,
		updownMarketCap: "$18.75M",
		updownChange: "-2.15%",
		updownNumOfSales: 80,
		updownHolders: "15,200",
		updownChangePercentage: "+2.40%",
		deployedTime: "03/09/2023, 12:46",
		progress: "0",
	},
	{
		tick: "ETH",
		index: "13",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 25.456,
		updown: "fake3",
		marketCap: "1,200,345.6",
		price: "0.040 sats",
		change: "-0.000032",
		numOfSales: "fake3",
		holders: 512.789,
		updownMarketCap: "$22.60M",
		updownChange: "+4.78%",
		updownNumOfSales: 150,
		updownHolders: "18,300",
		updownChangePercentage: "-1.80%",
		deployedTime: "03/09/2023, 12:46",
		progress: "30",
	},
	{
		tick: "ETH",
		index: "14",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 18.789,
		updown: "fake4",
		marketCap: "900,123.4",
		price: "0.038 sats",
		change: "-0.000028",
		numOfSales: "fake4",
		holders: 450.321,
		updownMarketCap: "$20.45M",
		updownChange: "+3.15%",
		updownNumOfSales: 110,
		updownHolders: "16,500",
		updownChangePercentage: "+1.20%",
		deployedTime: "03/09/2023, 14:10",
		progress: "100",
	},
];

export const dataTableMinting = [
	{
		tick: "ligo",
		index: "1",
		iconTick: "/media/images/coins/lino.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/08/2023, 11:16",
		progress: "99.88",
	},
	{
		tick: "AINN",
		index: "2",
		iconTick: "/media/images/coins/AINN.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.804.8",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/08/2023, 11:16",
		progress: "85.37",
	},
	{
		tick: "INJ",
		index: "3",
		iconTick: "/media/images/coins/AINN.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "-0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/08/2023, 19:44",
		progress: "46.12",
	},
	{
		tick: "Rats",
		index: "4",
		iconTick: "/media/images/coins/28452.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 10:52",
		progress: "44.55",
	},
	{
		tick: "drag",
		index: "5",
		iconTick: "/media/images/coins/drag.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:00",
		progress: "40.18",
	},
	{
		tick: "Bear",
		index: "6",
		iconTick: "/media/images/coins/28483.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:16",
		progress: "22.73",
	},
	{
		tick: "ligo",
		index: "7",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:16",
		progress: "20.11",
	},
	{
		tick: "BTC",
		index: "8",
		iconTick: "/media/images/coins/BTC.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:32",
		progress: "19.6",
	},
	{
		tick: "Moon",
		index: "9",
		iconTick: "/media/images/coins/28482.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 15.789,
		updown: "ligo",
		marketCap: "678,942.5",
		price: "0.032 sats",
		change: "-0.000018",
		numOfSales: "ligo",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
		deployedTime: "03/09/2023, 12:32",
		progress: "17.07",
	},
	{
		tick: "ligo",
		index: "10",
		iconTick: "/media/images/coins/28452.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:32",
		progress: "15.82",
	},
	{
		tick: "ETH",
		index: "11",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 15.789,
		updown: "ligo",
		marketCap: "678,942.5",
		price: "0.032 sats",
		change: "-0.000018",
		numOfSales: "ligo",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
		deployedTime: "03/09/2023, 12:39",
		progress: "14.2",
	},
	{
		tick: "ETH",
		index: "12",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 20.123,
		updown: "fake2",
		marketCap: "800,000.0",
		price: "0.035 sats",
		change: "-0.000025",
		numOfSales: "fake2",
		holders: 400.555,
		updownMarketCap: "$18.75M",
		updownChange: "-2.15%",
		updownNumOfSales: 80,
		updownHolders: "15,200",
		updownChangePercentage: "+2.40%",
		deployedTime: "03/09/2023, 12:46",
		progress: "13.16",
	},
	{
		tick: "ETH",
		index: "13",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 25.456,
		updown: "fake3",
		marketCap: "1,200,345.6",
		price: "0.040 sats",
		change: "-0.000032",
		numOfSales: "fake3",
		holders: 512.789,
		updownMarketCap: "$22.60M",
		updownChange: "+4.78%",
		updownNumOfSales: 150,
		updownHolders: "18,300",
		updownChangePercentage: "-1.80%",
		deployedTime: "03/09/2023, 12:46",
		progress: "13.04",
	},
	{
		tick: "ETH",
		index: "14",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 18.789,
		updown: "fake4",
		marketCap: "900,123.4",
		price: "0.038 sats",
		change: "-0.000028",
		numOfSales: "fake4",
		holders: 450.321,
		updownMarketCap: "$20.45M",
		updownChange: "+3.15%",
		updownNumOfSales: 110,
		updownHolders: "16,500",
		updownChangePercentage: "+1.20%",
		deployedTime: "03/09/2023, 14:10",
		progress: "10.66",
	},
];

export const dataTableCompleted = [
	{
		tick: "ligo",
		index: "1",
		iconTick: "/media/images/coins/lino.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/08/2023, 11:16",
		progress: "100",
	},
	{
		tick: "AINN",
		index: "2",
		iconTick: "/media/images/coins/AINN.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.804.8",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/08/2023, 11:16",
		progress: "100",
	},
	{
		tick: "INJ",
		index: "3",
		iconTick: "/media/images/coins/AINN.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "-0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/08/2023, 19:44",
		progress: "100",
	},
	{
		tick: "Rats",
		index: "4",
		iconTick: "/media/images/coins/28452.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 10:52",
		progress: "100",
	},
	{
		tick: "drag",
		index: "5",
		iconTick: "/media/images/coins/drag.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:00",
		progress: "100",
	},
	{
		tick: "Bear",
		index: "6",
		iconTick: "/media/images/coins/28483.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:16",
		progress: "100",
	},
	{
		tick: "ligo",
		index: "7",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:16",
		progress: "100",
	},
	{
		tick: "BTC",
		index: "8",
		iconTick: "/media/images/coins/BTC.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:32",
		progress: "100",
	},
	{
		tick: "Moon",
		index: "9",
		iconTick: "/media/images/coins/28482.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 15.789,
		updown: "ligo",
		marketCap: "678,942.5",
		price: "0.032 sats",
		change: "-0.000018",
		numOfSales: "ligo",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
		deployedTime: "03/09/2023, 12:32",
		progress: "100",
	},
	{
		tick: "ligo",
		index: "10",
		iconTick: "/media/images/coins/28452.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 11.2863,
		updown: "ordinals_nft_icon",
		marketCap: "484.8048",
		price: "0.028 sats",
		change: "+0.012",
		numOfSales: "ordinals_nft_icon",
		holders: 288.0013,
		updownMarketCap: "$12.38M",
		updownChange: "+6.39%",
		updownNumOfSales: 90,
		// updownHolders: "11,100",
		updownChangePercentage: "-0.14%",
		deployedTime: "03/09/2023, 12:32",
		progress: "100",
	},
	{
		tick: "ETH",
		index: "11",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 15.789,
		updown: "ligo",
		marketCap: "678,942.5",
		price: "0.032 sats",
		change: "-0.000018",
		numOfSales: "ligo",
		holders: 326.504,
		updownMarketCap: "$14.92M",
		updownChange: "+5.22%",
		updownNumOfSales: 120,
		updownHolders: "12,800",
		updownChangePercentage: "+0.87%",
		deployedTime: "03/09/2023, 12:39",
		progress: "100",
	},
	{
		tick: "ETH",
		index: "12",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 20.123,
		updown: "fake2",
		marketCap: "800,000.0",
		price: "0.035 sats",
		change: "-0.000025",
		numOfSales: "fake2",
		holders: 400.555,
		updownMarketCap: "$18.75M",
		updownChange: "-2.15%",
		updownNumOfSales: 80,
		updownHolders: "15,200",
		updownChangePercentage: "+2.40%",
		deployedTime: "03/09/2023, 12:46",
		progress: "100",
	},
	{
		tick: "ETH",
		index: "13",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 25.456,
		updown: "fake3",
		marketCap: "1,200,345.6",
		price: "0.040 sats",
		change: "-0.000032",
		numOfSales: "fake3",
		holders: 512.789,
		updownMarketCap: "$22.60M",
		updownChange: "+4.78%",
		updownNumOfSales: 150,
		updownHolders: "18,300",
		updownChangePercentage: "-1.80%",
		deployedTime: "03/09/2023, 12:46",
		progress: "100",
	},
	{
		tick: "ETH",
		index: "14",
		iconTick: "/media/svg/coins/eth.png",
		iconVolume: "/media/images/coins/BTC.png",
		iconMarketCap: "/media/svg/coins/binance.svg",
		volume: 18.789,
		updown: "fake4",
		marketCap: "900,123.4",
		price: "0.038 sats",
		change: "-0.000028",
		numOfSales: "fake4",
		holders: 450.321,
		updownMarketCap: "$20.45M",
		updownChange: "+3.15%",
		updownNumOfSales: 110,
		updownHolders: "16,500",
		updownChangePercentage: "+1.20%",
		deployedTime: "03/09/2023, 14:10",
		progress: "100",
	},
];
