import { useState } from "react"
import { KTSVG } from "../../../../_metronic/helpers"
import ButtonCustom from "../../components/button/ButtonCustom"
import ProofRadio from "../../components/text-field/ProofRadio"
import CreateReferralBalenceDialog from "../components/dialog/CreateReferralBalenceDialog"

const BalencesPage = () => {
    const [shouldOpenCreateDialog, setShouldOpenCreateDialog] = useState(false)
    const radioList = [
        {
            label: "",
            id: "",
        }
    ]

    return (
        <>
            <div className="balences-page-container">
                <div className="frame-content">
                    <div className="frame-content-header">
                        <div>Balences</div>
                        <ButtonCustom 
                            label="Create Referral Balance" 
                            btnClassName="button button-green spaces h-30"
                            handleClick={() => setShouldOpenCreateDialog(true)}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <KTSVG svgClassName="spaces w-15 h-15 mr-10" className="text-color-gray" path='/media/svg/icons/switch.svg' />
                            Switch Balance
                        </div>
                        <div>
                            Total: <span className="text-color-green">$0.00</span>
                        </div>
                    </div>
                    <div className="spaces pt-20">
                        <div className="balence-item-container">
                            <ProofRadio radioName="radioTextTab" radioList={radioList}/>
                            <div className="spaces ml-10">
                                <span className="spaces fs-14 fw-800 mb-10 text-color-white">Main</span>
                                <div className="d-flex align-items-center">
                                    <KTSVG svgClassName="spaces w-15 h-15 mr-6" className="text-color-gray" path="/media/svg/general/coins.svg" />
                                    $0.00
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {shouldOpenCreateDialog &&
                <CreateReferralBalenceDialog handleClose={() => setShouldOpenCreateDialog(false)}/>
            }
        </>
    )
}

export default BalencesPage