import { BATTLES_CARD_STATUS } from "../battles/constants"

export const LIVE_WINS_TABS = [
    {
        code: 0,
        components: <><div className="live-point"></div>LIVE WINS</>
    },
    {
        code: 1,
        components: <>M</>
    },
    {
        code: 2,
        components: <>W</>
    },
    {
        code: 3,
        components: <>D</>
    }
]

export const TABLE_BETS_TABS = [
    {
        name: 'All Bets',
        code: 0,
    },
    {
        name: 'High Rollers',
        code: 1,
    },
    {
        name: 'Lucky Bets',
        code: 2,
    },
    {
        name: 'Trades',
        code: 3,
    },
    {
        name: 'My Bets',
        code: 4,
    }
]

export const SUB_NAVIGATIONS_CASINO = [
    {
        iconPath: "/media/svg/navigation/casino/casino.svg",
        svgClassName: "spaces h-16 w-16",
        label: 'Lobby',
        toPath: '/casino'
    },
    {
        iconPath: "/media/svg/navigation/casino/favourites.svg",
        svgClassName: "spaces h-20 w-20",
        label: 'Favourites',
        toPath: '/category/favourites'
    },
    {
        iconPath: "/media/svg/navigation/casino/rollbit.svg",
        svgClassName: "spaces h-16 w-20",
        label: 'Funbit',
        toPath: '/category/funbit'
    },
    {
        iconPath: "/media/svg/navigation/casino/casino.svg",
        svgClassName: "spaces h-16 w-16",
        label: 'Slots',
        toPath: '/category/slots'
    },
    {
        iconPath: "/media/svg/navigation/casino/gameShows.svg",
        svgClassName: "spaces h-13 w-17",
        label: 'Shows',
        toPath: '/category/game-shows'
    },
    {
        iconPath: "/media/svg/navigation/casino/liveCasino.svg",
        svgClassName: "spaces h-19 w-17",
        label: 'Live Casino',
        toPath: '/category/live-casino'
    },
    {
        iconPath: "/media/svg/navigation/casino/sidebets.svg",
        svgClassName: "spaces h-16 w-15",
        label: 'Sidebets',
        toPath: '/category/sidebets'
    },
    {
        iconPath: "/media/svg/navigation/casino/battles.svg",
        svgClassName: "spaces h-18 w-18",
        label: 'Battles',
        toPath: '/category/battles'
    },
]

export const PROVIDER_LIST = [
    {
        navPath: "/providers/pragmatic",
        imgPath: "/media/svg/providerLogos/pragmatic.svg",
        gamesTotal: 528,
        name: "pragmatic game"
    },
    {
        navPath: "/providers/evolution",
        imgPath: "/media/svg/providerLogos/evolution.svg",
        gamesTotal: 342,
        name: "evolution game"
    },
    {
        navPath: "/providers/funbit",
        imgPath: "/media/svg/providerLogos/rollbit-logo-glow.svg",
        gamesTotal: 10,
        name: "Funbit game"
    },
    {
        navPath: "/providers/nolimit",
        imgPath: "/media/svg/providerLogos/nolimit.svg",
        gamesTotal: 79,
        name: "nolimit game"
    },
    {
        navPath: "/providers/playngo",
        imgPath: "/media/svg/providerLogos/playngo.svg",
        gamesTotal: 282,
        name: "pragmatic game"
    },
    {
        navPath: "/providers/hacksaw",
        imgPath: "/media/svg/providerLogos/hacksaw.svg",
        gamesTotal: 76,
        name: "hacksaw game"
    },
    {
        navPath: "/providers/relax",
        imgPath: "/media/svg/providerLogos/relax.svg",
        gamesTotal: 93,
        name: "relax game"
    },
    {
        navPath: "/providers/bgaming",
        imgPath: "/media/svg/providerLogos/bgaming.svg",
        gamesTotal: 95,
        name: "bgaming game"
    },
    {
        navPath: "/providers/bgaming",
        imgPath: "/media/svg/providerLogos/pushgaming.svg",
        gamesTotal: 36,
        name: "bgaming game"
    },
    {
        navPath: "/providers/pushgaming",
        imgPath: "/media/svg/providerLogos/elk.svg",
        gamesTotal: 85,
        name: "pushgaming game"
    },
    {
        navPath: "/providers/spinomenal",
        imgPath: "/media/svg/providerLogos/spinomenal.svg",
        gamesTotal: 312,
        name: "spinomenal game"
    },
    {
        navPath: "/providers/playtech",
        imgPath: "/media/svg/providerLogos/playtech.svg",
        gamesTotal: 224,
        name: "playtech game"
    },
    {
        navPath: "/providers/egt",
        imgPath: "/media/svg/providerLogos/egt.svg",
        gamesTotal: 164,
        name: "egt game"
    },
    {
        navPath: "/providers/redtiger",
        imgPath: "/media/svg/providerLogos/redtiger.svg",
        gamesTotal: 156,
        name: "redtiger game"
    },
    {
        navPath: "/providers/wazdan",
        imgPath: "/media/svg/providerLogos/wazdan.svg",
        gamesTotal: 146,
        name: "wazdan game"
    },
    {
        navPath: "/providers/evoplay",
        imgPath: "/media/svg/providerLogos/evoplay.svg",
        gamesTotal: 146,
        name: "evoplay game"
    },
    {
        navPath: "/providers/bsg",
        imgPath: "/media/svg/providerLogos/bsg.svg",
        gamesTotal: 146,
        name: "bsg game"
    },
    {
        navPath: "/providers/netent",
        imgPath: "/media/svg/providerLogos/netent.svg",
        gamesTotal: 146,
        name: "netent game"
    },
    {
        navPath: "/providers/microgaming",
        imgPath: "/media/svg/providerLogos/microgaming.svg",
        gamesTotal: 146,
        name: "microgaming game"
    },
    {
        navPath: "/providers/endorphina",
        imgPath: "/media/svg/providerLogos/endorphina.svg",
        gamesTotal: 146,
        name: "endorphina game"
    },
    {
        navPath: "/providers/habanero",
        imgPath: "/media/svg/providerLogos/habanero.svg",
        gamesTotal: 146,
        name: "habanero game"
    },
    {
        navPath: "/providers/gameart",
        imgPath: "/media/svg/providerLogos/gameart.svg",
        gamesTotal: 146,
        name: "gameart game"
    },
    {
        navPath: "/providers/quickspin",
        imgPath: "/media/svg/providerLogos/quickspin.svg",
        gamesTotal: 146,
        name: "quickspin game"
    },
    {
        navPath: "/providers/isoftbet",
        imgPath: "/media/svg/providerLogos/isoftbet.svg",
        gamesTotal: 146,
        name: "isoftbet game"
    },
    {
        navPath: "/providers/amatic",
        imgPath: "/media/svg/providerLogos/amatic.svg",
        gamesTotal: 146,
        name: "amatic game"
    },
    {
        navPath: "/providers/belatra",
        imgPath: "/media/svg/providerLogos/belatra.svg",
        gamesTotal: 146,
        name: "belatra game"
    },
    {
        navPath: "/providers/thunderkick",
        imgPath: "/media/svg/providerLogos/thunderkick.svg",
        gamesTotal: 146,
        name: "thunderkick game"
    },
    {
        navPath: "/providers/mascot",
        imgPath: "/media/svg/providerLogos/mascot.svg",
        gamesTotal: 146,
        name: "mascot game"
    },
    {
        navPath: "/providers/retrogaming",
        imgPath: "/media/svg/providerLogos/retrogaming.svg",
        gamesTotal: 146,
        name: "retrogaming game"
    },
    {
        navPath: "/providers/bigtimegaming",
        imgPath: "/media/svg/providerLogos/bigtimegaming.svg",
        gamesTotal: 146,
        name: "bigtimegaming game"
    },
    {
        navPath: "/providers/platipus",
        imgPath: "/media/svg/providerLogos/platipus.svg",
        gamesTotal: 146,
        name: "platipus game"
    },
    {
        navPath: "/providers/irondogstudio",
        imgPath: "/media/svg/providerLogos/irondogstudio.svg",
        gamesTotal: 146,
        name: "irondogstudio game"
    },
    {
        navPath: "/providers/all41studios",
        imgPath: "/media/svg/providerLogos/all41studios.svg",
        gamesTotal: 146,
        name: "all41studios game"
    },
    {
        navPath: "/providers/avatarux",
        imgPath: "/media/svg/providerLogos/avatarux.svg",
        gamesTotal: 146,
        name: "avatarux game"
    },
    {
        navPath: "/providers/zillion",
        imgPath: "/media/svg/providerLogos/zillion.svg",
        gamesTotal: 146,
        name: "zillion game"
    },
    {
        navPath: "/providers/gamebeat",
        imgPath: "/media/svg/providerLogos/gamebeat.svg",
        gamesTotal: 146,
        name: "gamebeat game"
    },
    {
        navPath: "/providers/blueprint",
        imgPath: "/media/svg/providerLogos/blueprint.svg",
        gamesTotal: 146,
        name: "blueprint game"
    },
    {
        navPath: "/providers/gameburgerstudios",
        imgPath: "/media/svg/providerLogos/gameburgerstudios.svg",
        gamesTotal: 146,
        name: "gameburgerstudios game"
    },
    {
        navPath: "/providers/fantasma",
        imgPath: "/media/svg/providerLogos/fantasma.svg",
        gamesTotal: 146,
        name: "fantasma game"
    },
    {
        navPath: "/providers/merkur",
        imgPath: "/media/svg/providerLogos/merkur.svg",
        gamesTotal: 146,
        name: "merkur game"
    },
    {
        navPath: "/providers/gamesinc",
        imgPath: "/media/svg/providerLogos/gamesinc.svg",
        gamesTotal: 146,
        name: "gamesinc game"
    },
    {
        navPath: "/providers/spinplay",
        imgPath: "/media/svg/providerLogos/spinplay.svg",
        gamesTotal: 146,
        name: "spinplay game"
    },
    {
        navPath: "/providers/fugaso",
        imgPath: "/media/svg/providerLogos/fugaso.svg",
        gamesTotal: 146,
        name: "fugaso game"
    },
    {
        navPath: "/providers/printstudios",
        imgPath: "/media/svg/providerLogos/printstudios.svg",
        gamesTotal: 146,
        name: "printstudios game"
    },
    {
        navPath: "/providers/pearfiction",
        imgPath: "/media/svg/providerLogos/pearfiction.svg",
        gamesTotal: 146,
        name: "pearfiction game"
    },
    {
        navPath: "/providers/alg",
        imgPath: "/media/svg/providerLogos/alg.svg",
        gamesTotal: 146,
        name: "alg game"
    },
    {
        navPath: "/providers/tripleedgestudios",
        imgPath: "/media/svg/providerLogos/tripleedgestudios.svg",
        gamesTotal: 146,
        name: "tripleedgestudios game"
    },
]

export const CARD_GAMES_BATTLE_LIST = [
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp',
        bonusBuy: '$100',
        player: '3/3',
        status: BATTLES_CARD_STATUS.AVAILABLE
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp',
        bonusBuy: '$100',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp',
        bonusBuy: '$90',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_PubKings.webp',
        bonusBuy: '$450',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp',
        bonusBuy: '$20',
        player: '2/2',
    },
    {
        imgPath: '/media/images/games/smallSize/relax_MoneyTrain4.webp',
        bonusBuy: '$290',
        player: '2/2',
    },
    {
        imgPath: '/media/images/games/smallSize/relax_TnTTumble.webp',
        bonusBuy: '$250',
        player: '2/2',
    },
    {
        imgPath: '/media/images/games/smallSize/rollbit_plinko.webp',
        bonusBuy: '$20',
        player: '2/2',
    }
]

export const ROLLBIT_ORIGINALS = [
    {
        imgPath: '/media/images/games/smallSize/rollbit_plinko.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_PubKings.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/relax_MoneyTrain4.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/relax_TnTTumble.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/rollbit_plinko.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_PubKings.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp',
        price: '$887',
        percent: '99,60%'
    },
]

export const MORE_FROM_ROLLBIT =[
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_PubKings.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/relax_MoneyTrain4.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/relax_TnTTumble.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/rollbit_plinko.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp',
        price: '$887',
        percent: '99,60%'
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp',
        price: '$887',
        percent: '99,60%'
    },
]