import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./modules/auth";
import WalletProvider from "../providers/wallet_provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const queryClient = new QueryClient();

const App = () => {
	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
			<Suspense fallback={<LayoutSplashScreen />}>
				<I18nProvider>
					<LayoutProvider>
						<WalletProvider>
							<QueryClientProvider client={queryClient}>
								<AuthInit>
									<Outlet />
									<MasterInit />
								</AuthInit>
							</QueryClientProvider>
						</WalletProvider>
					</LayoutProvider>
				</I18nProvider>
			</Suspense>
		</>
	);
};

export { App };
