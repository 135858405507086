import React, { ReactNode, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./CustomTabs.scss";
import clsx from "clsx";
import { KTSVG } from "../../../helpers";

type IProps = {
	listTab: any;
	verticalTab?: boolean;
	className?: string;
	tabDefault?: string;
	tabHeaderContent?: ReactNode;
	tabActiveKey?: (key: string) => void;
};

export const CustomTabsV2 = ({
	listTab,
	verticalTab,
	className,
	tabDefault = "1",
	tabHeaderContent,
	tabActiveKey,
}: IProps) => {
	const [activeTab, setActiveTab] = useState(tabDefault);
	const handleTabChange = (key: string | null) => {
		if (key) {
			setActiveTab(key);
			tabActiveKey && tabActiveKey(key);
		}
	};

	return (
		<div
			className={clsx(className, "custom-tab", { "vertical-tab": verticalTab })}
		>
			<Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
				<div className="tab-header-container">
					<Nav>
						{listTab?.map((tab: any) => {
							return tab?.nav?.component ? (
								<React.Fragment key={tab?.nav?.eventKey}>
									{tab.nav.component}
								</React.Fragment>
							) : (
								<Nav.Item key={tab?.nav?.eventKey}>
									<Nav.Link eventKey={tab?.nav?.eventKey}>
										<div className="nav-content">
											{tab?.nav?.icon?.path && (
												<div className="nav-icon">
													<KTSVG
														svgClassName={tab?.nav?.icon?.className}
														path={tab?.nav?.icon?.path}
													/>
												</div>
											)}
											<div className={`nav-label ${tab?.nav?.labelClassName}`}>
												{tab?.nav?.label}
											</div>
										</div>
									</Nav.Link>
								</Nav.Item>
							);
						})}
					</Nav>
					<div>{tabHeaderContent}</div>
				</div>
				<Tab.Content>
					{listTab?.map((tab: any) => (
						<Tab.Pane key={tab?.nav?.eventKey} eventKey={tab?.nav?.eventKey}>
							{activeTab === tab?.nav?.eventKey && tab?.content?.component}
						</Tab.Pane>
					))}
				</Tab.Content>
			</Tab.Container>
		</div>
	);
};
