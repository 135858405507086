import { CustomTabsV2 } from "../../../../_metronic/layout/components/tabs/CustomTabsV2"
import NoTradeFound from "./NoTradeFound"
import AllTradesTab from "./tabs/AllTradesTab"
import MarketCapTab from "./tabs/MarketCapTab"

const LIST_TAB_TRADE = [
    {
        nav: {
            label: "My Trades",
            labelClassName: "text-capitalize spaces fw-500",
            eventKey: "1",
        },
        content: {
              component: <NoTradeFound label="No trade found" iconPath="/media/svg/navigation/rollbit-coin/Trade RLB.svg" />,
        },
    },
    {
        nav: {
            label: "All Trades",
            eventKey: "2",
            labelClassName: "text-capitalize spaces fw-500",
        },
        content: {
            component: <AllTradesTab />,
        },
    },
    {
        nav: {
            label: "Market cap",
            eventKey: "3",
            labelClassName: "text-capitalize spaces fw-500",
            icon: {
                path: "/media/svg/navigation/rollbit-coin/buy-burn.svg",
                className: "spaces w-11 h-16"
            }
        },
        content: {
            component: <MarketCapTab />,
        },
    },
]

const TradeTabs = () => {
    return (
        <>
            <CustomTabsV2 listTab={LIST_TAB_TRADE} tabDefault={"2"}/>
        </>
    )
}

export default TradeTabs