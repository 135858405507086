import { Link } from "react-router-dom";
import { KTSVG } from "../../../../../../_metronic/helpers";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import TextField from "../../../../components/text-field/TextField";
import BetsCard from "../../../components/cards/BetsCard";
import { LIST_CURRENT_BETS } from "../../../contants";

const blockMinedList = Array(100).fill(1);

const LotteryHomePage = () => {
	return (
		<div className="lottery-home-page-container">
			<div className="d-flex justify-content-between header-lottery-container">
				<div>
					<h1 className="spaces fs-38 fw-800 text-uppercase header-title">
						Wager your FUNBIT for a <br />
						chance to win the lottery
					</h1>
				</div>
				<ButtonCustom
					label="How it work"
					labelClassName="text-capitalize text-color-gray"
					btnClassName="button button-grey-light"
				/>
			</div>
			<div className="lottery-jackpot-container">
				<div className="d-flex align-items-center lottery-jackpot-item">
					<div>
						<img
							src="/media/images/funbit-coin/rakeback.png"
							alt=""
							width={64}
						/>
					</div>
					<div className="spaces pl-24">
						<div className="text-color-gray spaces fw-500 fs-14 pb-13">
							Lottery
							<KTSVG
								path="/media/svg/general/info.svg"
								svgClassName="spaces w-15 h-15 ml-6"
							/>
						</div>
						<div className="d-flex align-items-center spaces fs-18 fw-800 text-color-gray">
							<div className="spaces mr-6">$</div>
							<span className="number-item">1</span>,
							<span className="number-item">8</span>
							<span className="number-item">6</span>
							<span className="number-item">5</span>.
							<span className="number-item">4</span>
							<span className="number-item">6</span>
						</div>
					</div>
				</div>
				<div className="d-flex align-items-center lottery-jackpot-item">
					<div>
						<img
							src="/media/images/funbit-coin/shield-coins.png"
							alt=""
							width={64}
						/>
					</div>
					<div className="spaces pl-24">
						<div className="text-color-gray spaces fw-500 fs-14 pb-13">
							Jackpot
							<KTSVG
								path="/media/svg/general/info.svg"
								svgClassName="spaces w-15 h-15 ml-6"
							/>
						</div>
						<div className="d-flex align-items-center spaces fs-18 fw-800 text-color-gray">
							<div className="spaces mr-6">$</div>
							<span className="number-item">2</span>
							<span className="number-item">4</span>
							<span className="number-item">3</span>,
							<span className="number-item">3</span>
							<span className="number-item">1</span>
							<span className="number-item">5</span>.
							<span className="number-item">1</span>
							<span className="number-item">9</span>
						</div>
					</div>
				</div>
			</div>
			<div className="wager-steps-container">
				<div className="wager-step step-one">
					<div>
						<div className="step-title spaces mb-4">
							<span>Step 1: Wager FUNBIT</span>
							<KTSVG
								path="/media/svg/general/info.svg"
								className="spaces w-15 h-27 ml-6"
							/>
						</div>
						<p className="spaces fs-12 mb-24 text-color-gray">
							Each wagered FUNBIT gives you a chance to win
						</p>
						<TextField
							label="Wager Amount"
							inputStart={
								<img
									className="spaces mr-10"
									src="/media/images/coin.webp"
									width={20}
									height={20}
									alt=""
								/>
							}
							inputEnd={
								<>
									<ButtonCustom
										label="Max"
										labelClassName="text-color-gray text-capitalize"
										btnClassName="button button-grey-light spaces fs-12 px-8 h-24 min-h-24 mr-8 border-radius-4"
									/>
									<ButtonCustom
										label="Seed"
										labelClassName="text-color-gray text-capitalize"
										btnClassName="button button-grey-light spaces fs-12 px-8 h-24 min-h-24 border-radius-4"
									/>
								</>
							}
						/>
						<div className="spaces mt-24">
							<ButtonCustom
								label="Set wager"
								btnClassName="button button-yellow w-100 button-disabled"
							/>
						</div>
					</div>
				</div>
				<div className="wager-step">
					<div>
						<div className="step-title">
							<span>Step 2: Multiply</span>
							<KTSVG
								path="/media/svg/general/info.svg"
								className="spaces w-15 h-27 ml-6"
							/>
						</div>
						<div className="d-flex flex-column spaces gap-12">
							<ButtonCustom
								label="Rank"
								btnClassName="button button-grey-tab button-disabled spaces h-30 min-h-30 fs-13"
							/>
							<ButtonCustom
								label="Wager"
								btnClassName="button button-grey-tab button-disabled spaces h-30 min-h-30 fs-13"
							/>
							<ButtonCustom
								label="Rollbot"
								btnClassName="button button-grey-tab button-disabled spaces h-30 min-h-30 fs-13"
							/>
						</div>
					</div>
				</div>
				<div className="wager-step">
					<div>
						<div className="step-title">
							<span>Step 3: Teams</span>
							<KTSVG
								path="/media/svg/general/info.svg"
								className="spaces w-15 h-27 ml-6"
							/>
						</div>
						<div className="d-flex flex-column spaces gap-12">
							<ButtonCustom
								label="Create Team"
								btnClassName="button button-grey-tab spaces h-30 min-h-30 fs-13"
							/>
							<ButtonCustom
								label="Join Team"
								btnClassName="button button-grey-tab spaces h-30 min-h-30 fs-13"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="spaces mt-60 blocks-mined-container">
				<div className="spaces mb-16">
					<span className="text-color-green spaces fs-14 fw-800 mr-16">
						62 of 100 BTC blocks mined
					</span>
					<span className="text-color-gray spaces fs-12">
						Winners will be picked using hash of Bitcoin block #821030
					</span>
				</div>
				<div className="d-flex align-items-center flex-wrap spaces gap-10">
					{blockMinedList.map((blockMinded, index) => (
						<div
							className={`blocks-mined-item ${
								index < 62 ? "block-active" : ""
							}`}
						>
							<KTSVG
								path="/media/svg/icons/letter-B.svg"
								svgClassName="spaces w-8 h-24 d-flex align-items-center"
							/>
						</div>
					))}
				</div>
			</div>
			<div className="d-flex ailgn-items-center justify-content-between spaces mt-40 total-wager-container">
				<div className="d-flex align-items-center verify-item-container">
					<KTSVG
						path={"/media/svg/icons/provably-fair.svg"}
						className="svg-icon-2 text-color-gray"
						width={168}
						height={50}
					/>
					<div className="d-flex flex-column gap-6 spaces fs-12 ml-16 text-color-gray">
						<span className="verify-link">Verify Fairness</span>
						<span>[Advanced Users]</span>
					</div>
				</div>
				<Link
					to={"/fun/lottery/stakes"}
					className="d-flex align-items-center total-wager-item"
				>
					<img
						src="/media/images/funbit-coin/rlb-stack.webp"
						alt=""
						width={50}
					/>
					<div className="spaces ml-16 fs-16 fw-800">
						<span className="text-color-gray spaces mr-6">Total Wagered: </span>
						<span className="text-color-white">4,396,711,562 FUNBIT</span>
						<KTSVG
							path="/media/svg/general/info.svg"
							svgClassName="spaces w-15 h-15 ml-6 mb-2"
						/>
					</div>
				</Link>
			</div>
			<div className="previous-lottery-winners-container current-bets-container">
				<h3 className="spaces fs-18 fw-800 mb-40">PREVIOUS LOTTERY WINNERS</h3>
				<div className="list-bets spaces pt-0">
					{LIST_CURRENT_BETS.map((card) => (
						<BetsCard
							imgCard={card.imgCard}
							team={card.team}
							namePlayer={card.namePlayer}
							value={card.value}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default LotteryHomePage;
