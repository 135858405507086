import { create } from "zustand";
import {
  AuctionListParam,
  AuctionListSort,
  NftType,
} from "../../types/marketplace-service";

export const AUCTION_LIST_PARAM_INITIAL: AuctionListParam = {
  start: 1,
  limit: 20,
  nftType: NftType.BRC_20,
  sort: AuctionListSort.UNIT_PRICE_DESC,
  tick: "like",
};

export type AuctionListParamState = {
  auctionListParam: AuctionListParam;
  setAuctionListParam: (param: Partial<AuctionListParam>) => void;
};

export const useAuctionListParamStore = create<AuctionListParamState>()(
  (set) => ({
    auctionListParam: AUCTION_LIST_PARAM_INITIAL,
    setAuctionListParam: (param: Partial<AuctionListParam>) => {
      set((prev) => ({
        auctionListParam: { ...prev.auctionListParam, ...param },
      }));
    },
  })
);
