import { useEffect, useState } from "react";
import ButtonCustom from "../../components/button/ButtonCustom";
import { ConfigProvider, InputNumber, Slider } from "antd";
import { useQuery } from "react-query";
import { getMempoolRecommendedFees } from "../../../services/mempool";
import {
	calculateFeeBySize,
	calculateNetworkFee,
	getContentTypeSizeAndFileSizeFromDataURL,
	satsToDollars,
} from "../../../utils/funtionHelper";
import useGetBitcoinPrices from "../../../hooks/useGetBitcoinPrices";

const NETWORK_FEE_TYPE = {
	ECONOMY: "hourFee",
	NORMAL: "halfHourFee",
	CUSTOM: "fastestFee",
};

const NETWORK_FEE_LIST = [
	{
		name: "Economy",
		value: "12",
		id: "hourFee",
	},
	{
		name: "Normal",
		value: "12",
		id: "halfHourFee",
	},
	{
		name: "Custom",
		value: null,
		id: "fastestFee",
	},
];

const SelectNetworkFee = ({
	satList,
	receiveAddress = "",
	feeRate,
	outputValue,
	handleChangeInscriptionInfo,
}: {
	satList?: any[];
	receiveAddress?: string;
	feeRate?: number;
	outputValue?: number;
	handleChangeInscriptionInfo?: (data: any) => void;
}) => {
	const result = useGetBitcoinPrices();
	const [fees, setFees] = useState({
		networkFee: 0,
		satInscription: 0,
		serviceBaseFee: 0,
		feeBySize: 0,
	});

	const [networkFeeSelected, setNetworkFeeSelected] = useState(
		NETWORK_FEE_TYPE.ECONOMY
	);
	const [networkFeeCustomValue, setNetworkFeeCustomValue] = useState(24);

	const { data, isLoading } = useQuery({
		queryKey: "networkFeeList",
		queryFn: async () => await getMempoolRecommendedFees(""),
	});

	const [networkFeeList, setNetworkFeeList] = useState([...NETWORK_FEE_LIST]);

	useEffect(() => {
		if (!isLoading) {
			setNetworkFeeList([
				...NETWORK_FEE_LIST.map((item) => ({
					...item,
					value: data?.data?.[item.id],
				})),
			]);

			setNetworkFeeCustomValue(data?.data?.[NETWORK_FEE_TYPE.CUSTOM]);
			if (handleChangeInscriptionInfo) {
				handleChangeInscriptionInfo({
					feeRate: data?.data?.[networkFeeSelected],
				});
			}
		}
	}, [data?.data, isLoading]);

	const handleChangeNetworkFeeCustomValue = (newValue: any) => {
		setNetworkFeeCustomValue(newValue);
		setNetworkFeeList((prev) => [
			...prev.map((item) =>
				item.id === NETWORK_FEE_TYPE.CUSTOM
					? { ...item, value: newValue }
					: { ...item }
			),
		]);
		if (handleChangeInscriptionInfo) {
			handleChangeInscriptionInfo({ feeRate: newValue });
		}
	};

	useEffect(() => {
		let networkFee = 130 * satList?.length!;
		satList!.forEach((item: { dataURL: string }) => {
			const { dataURL } = item;
			const fee = calculateNetworkFee({
				dataURL: dataURL,
				feeRate: feeRate!,
				address: receiveAddress,
			});
			networkFee += fee;
		});

		setFees((prev) => ({
			...prev,
			satInscription: satList?.length! * outputValue!,
			networkFee: networkFee,
			serviceBaseFee: 0 * Math.min(25, satList?.length!),
			feeBySize: 0,
		}));
	}, [feeRate, outputValue, receiveAddress, satList]);

	return (
		<div className="network-fee-container">
			<div className="text-color-white fw-bold">
				Select the network fee you want to pay:
			</div>
			<div className="network-fee-list">
				{networkFeeList.map((networkFeeItem) => (
					<ButtonCustom
						key={networkFeeItem.id}
						label={
							<div className="spaces fs-16 d-flex flex-column align-items-center">
								<div className="text-color-white fw-bold">
									{networkFeeItem.name}
								</div>
								<div className="d-flex align-items-center">
									<span className="text-color-yellow spaces fs-24 mr-4">
										{networkFeeItem.value}
									</span>
									<span className="text-lowercase">sats/vB</span>
								</div>
							</div>
						}
						btnClassName={`button network-item ${
							networkFeeSelected === networkFeeItem.id && "selected"
						}`}
						handleClick={() => {
							setNetworkFeeSelected(networkFeeItem.id);
							handleChangeInscriptionInfo &&
								handleChangeInscriptionInfo({ feeRate: networkFeeItem.value });
						}}
					/>
				))}
			</div>
			{networkFeeSelected === NETWORK_FEE_TYPE.CUSTOM && (
				<div>
					<ConfigProvider
						theme={{
							components: {
								Slider: {
									handleActiveColor: "#ebb94c",
									handleColor: "#745d2e",
									trackBg: "#745d2e",
									trackHoverBg: "#a07f38",
									railBg: "#191b26",
									railHoverBg: "#463e34",
								},
								InputNumber: {
									activeBg: "#272934",
									handleBg: "#272934",
									hoverBg: "#272934",
								},
							},
							token: {
								colorPrimaryBorderHover: "#a07f38",
								colorBgElevated: "#251f19",
								colorBgContainer: "#272934",
								colorBorder: "#484c58",
								colorText: "#ffffff",
								colorPrimary: "#ebb94c",
								colorTextDescription: "#ffffff",
							},
						}}
					>
						<div className="d-flex align-items-center spaces gap-12 mt-16">
							<div className="w-100 d-flex spaces gap-12">
								<Slider
									className="w-100"
									min={1}
									max={500}
									onChange={handleChangeNetworkFeeCustomValue}
									value={networkFeeCustomValue}
								/>
								<InputNumber
									min={1}
									max={500}
									value={networkFeeCustomValue}
									onChange={handleChangeNetworkFeeCustomValue}
								/>
							</div>
						</div>
					</ConfigProvider>
				</div>
			)}
			<div className="text-center spaces fs-12 py-8">
				Times shown are not guaranteed. USD values are estimates only.
			</div>
			<div>
				<div className="text-color-white d-flex align-items-center justify-content-between spaces h-36">
					<div>Sats In Inscription:</div>
					<div className="d-flex align-items-center spaces gap-8">
						{satList?.length! > 1 && `${satList?.length}x`}
						{outputValue} sats
						<div className="text-color-gray spaces fs-12 w-80">
							~${satsToDollars(fees.satInscription, result)}
						</div>
					</div>
				</div>
				<div className="text-color-white d-flex align-items-center justify-content-between spaces h-36">
					<div>Network Fee:</div>
					<div className="d-flex align-items-center spaces gap-8">
						{fees.networkFee} sats
						<div className="text-color-gray spaces fs-12 w-80">
							~$
							{satsToDollars(fees.networkFee + 130 * satList?.length!, result)}
						</div>
					</div>
				</div>
				<hr className="spaces my-4"></hr>
				<div className="text-color-white d-flex align-items-center justify-content-between spaces h-36">
					<div>Service Base Fee:</div>
					<div className="d-flex align-items-center spaces gap-8">
						~{fees.serviceBaseFee} sats
						<div className="text-color-gray spaces fs-12 w-80">
							~${satsToDollars(fees.serviceBaseFee, result)}
						</div>
					</div>
				</div>
				<div className="text-color-white d-flex align-items-center justify-content-between spaces h-36">
					<div>Fee by Size:</div>
					<div className="d-flex align-items-center spaces gap-8">
						~{fees.feeBySize} sats
						<div className="text-color-gray spaces fs-12 w-80">
							~${satsToDollars(+fees.feeBySize.toFixed(0), result)}
						</div>
					</div>
				</div>
				<div className="text-color-white d-flex align-items-center justify-content-between spaces h-36">
					<div>=</div>
					<div className="d-flex align-items-center spaces gap-8">
						~{fees.serviceBaseFee + fees.feeBySize} sats
						<div className="text-color-gray spaces fs-12 w-80">
							~$
							{satsToDollars(
								fees.serviceBaseFee + +fees.feeBySize.toFixed(0),
								result
							)}
						</div>
					</div>
				</div>
				<div className="text-center spaces fs-12 py-8">
					Only charge the service fee for the first 25 inscriptions.
				</div>
				<hr className="spaces my-4"></hr>
				<div className="text-color-white d-flex align-items-center justify-content-between spaces h-36">
					<div>Total:</div>
					<div className="d-flex align-items-center spaces gap-8">
						<span className="text-decoration-line-through">
							~
							{Math.floor(fees.feeBySize) +
								fees.networkFee +
								fees.satInscription +
								fees.serviceBaseFee}{" "}
							sats
						</span>
						<div className="text-color-gray spaces fs-12 w-80">
							~$
							{satsToDollars(
								Math.floor(fees.feeBySize) +
									fees.networkFee +
									fees.satInscription +
									fees.serviceBaseFee,
								result
							)}
						</div>
					</div>
				</div>
				<div className="text-color-white d-flex align-items-center justify-content-end spaces h-36">
					<div className="d-flex align-items-center spaces gap-8">
						<span className="linear-gradient-text">
							~
							{fees.feeBySize +
								fees.networkFee +
								fees.satInscription +
								fees.serviceBaseFee}{" "}
							sats
						</span>
						<div className="text-color-yellow spaces fs-12 w-80">
							~$
							{satsToDollars(
								fees.feeBySize +
									fees.networkFee +
									fees.satInscription +
									fees.serviceBaseFee,
								result
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectNetworkFee;
