import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import TradeTabs from "../components/TradeTabs";
import ButtonCustom from "../../components/button/ButtonCustom";
import TextField from "../../components/text-field/TextField";
import { useState } from "react";
import { auto } from "@popperjs/core";

const TradePage = () => {
	const [isActionBuy, setIsActionBuy] = useState(true);

	return (
		<div className="trade-page-container">
			<div className="chart-container text-color-gray">
				<div className="chart-content">
					<AdvancedRealTimeChart
						theme="dark"
						symbol="BITSTAMP:BTCUSD"
						height="100%"
						width={auto}
						hide_top_toolbar={true}
						hide_side_toolbar={true}
						hide_legend={true}
					></AdvancedRealTimeChart>
				</div>
				<div className="operation-content">
					<div className="group-button">
						<ButtonCustom
							label="Buy"
							btnClassName={`button ${isActionBuy ? "button-grey-light" : ""}`}
							handleClick={() => setIsActionBuy(true)}
						/>
						<ButtonCustom
							label="Sell"
							btnClassName={`button ${!isActionBuy ? "button-grey-light" : ""}`}
							handleClick={() => setIsActionBuy(false)}
						/>
					</div>
					<TextField
						label="FUNBIT"
						inputStart={
							<img
								src="/media/images/funbit-coin/funbit-icon.png"
								alt=""
								width={20}
							/>
						}
						inputEnd={
							<ButtonCustom
								label="MAX"
								btnClassName="button button-green-light"
							/>
						}
						className="spaces mt-14"
					/>
					<TextField
						label="MAX SLIPPAGE %"
						inputDes="Limit: $0.151773"
						className="spaces mt-14"
					/>
					<div className="d-flex align-items-center justify-content-between spaces mt-16">
						<div>Max Cost:</div>
						<div>-</div>
					</div>
					<div className="d-flex align-items-center justify-content-between spaces mb-20">
						<div>Est. Fees (0.5%):</div>
						<div>-</div>
					</div>
					<ButtonCustom
						label={isActionBuy ? "Buy fun" : "Sell fun"}
						btnClassName={`button w-100 ${
							isActionBuy ? "button-green" : "button-red"
						}`}
					/>
				</div>
			</div>
			<TradeTabs />
		</div>
	);
};

export default TradePage;
