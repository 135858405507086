import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../../../../_metronic/helpers";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import TextField from "../../../../components/text-field/TextField";
import { useState } from "react";
import { ItemAsset } from "../../../../../../types/unisat";

type TProps = {
	handleClose: () => void;
	nftInfo: ItemAsset;
};

const AddInscribeTransferModal = ({ handleClose, nftInfo }: TProps) => {
	const [amount, setAmount] = useState(null);
	const [feeRateChoosed, setFeeRateChoose] = useState<any>({
		code: "2",
		name: "Avg",
		transfer: "20 sat/vB",
		about: "about 30 minutes",
	});
	const [step, setStep] = useState(1);

	const feeRateList = [
		{
			code: "1",
			name: "Slow",
			transfer: "19 sat/vB",
			about: "about 1 hours",
		},
		{
			code: "2",
			name: "Avg",
			transfer: "20 sat/vB",
			about: "about 30 minutes",
		},
		{
			code: "3",
			name: "Fast",
			transfer: "21 sat/vB",
			about: "about 10 minutes",
		},
	];

	return (
		<Modal
			contentClassName={"modal-wrapper inscribe-transfer-modal"}
			show={true}
			size="lg"
			onHide={handleClose}
		>
			<div className="modal-content">
				{step === 1 && (
					<>
						<div className="text-center text-color-white spaces fs-16 fw-bold mb-24">
							Inscribe TRANSFER
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<div className="text-color-gray">Available</div>
							<div>
								{nftInfo.availableBalance} {nftInfo.ticker}
							</div>
						</div>
						<TextField
							className="spaces mt-20"
							value={amount ? amount : ""}
							setValue={setAmount}
							type="number"
							placeholder="Amount"
						/>
						<div className="spaces mt-16">Fee Rate</div>
						<div className="fee-rate-list">
							{feeRateList.map((feeRateItem: any) => (
								<div
									className={`fee-rate-item ${
										feeRateItem.code === feeRateChoosed?.code && "active"
									}`}
									onClick={() => setFeeRateChoose(feeRateItem)}
								>
									<span className="spaces fs-14">{feeRateItem?.name}</span>
									<span className="spaces fs-12">{feeRateItem?.transfer}</span>
									<span className="about">{feeRateItem?.about}</span>
								</div>
							))}
						</div>
					</>
				)}
				{step === 2 && (
					<>
						<div className="text-center text-color-yellow spaces fs-16 fw-bold">
							{amount} {nftInfo.ticker}
						</div>
						<div className="text-color-gray">Preview</div>
						<div className="preview-box">
							{`("p": "br-20","op": "transfer","tick": "PHAT","amt": "4"}`}
						</div>
						<div className="spaces fw-500 mb-24 mt-50">
							<div className="d-flex align-items-center justify-content-between spaces my-4">
								<div className="text-color-gray">Payment Network Fee</div>
								<div>0.00003080 BTC</div>
							</div>
							<div className="d-flex align-items-center justify-content-between spaces my-4">
								<div className="text-color-gray">Inscription Output Value</div>
								<div>0.00003080 BTC</div>
							</div>
							<div className="d-flex align-items-center justify-content-between spaces my-4">
								<div className="text-color-gray">Inscription Network Fee</div>
								<div>0.00003080 BTC</div>
							</div>
							<div className="d-flex align-items-center justify-content-between spaces my-4">
								<div className="text-color-gray">Service Fee</div>
								<div className="text-color-gray text-decoration-line-through">
									0.00003080 BTC
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-between spaces my-4">
								<div className="text-color-gray"></div>
								<div>0.00003080 BTC</div>
							</div>
							<div className="d-flex align-items-center justify-content-between spaces my-4">
								<div className="text-color-yellow">Total</div>
								<div className="text-color-yellow">0.00003080 BTC</div>
							</div>
						</div>
					</>
				)}
			</div>
			<div className="modal-btns spaces gap-12">
				{step === 1 && (
					<>
						<ButtonCustom
							label="Cancel"
							btnClassName="button button-grey-light"
							handleClick={handleClose}
						/>
						<ButtonCustom
							label="Next"
							btnClassName={`button button-yellow ${!amount && "disabled"}`}
							handleClick={() => {
								amount && setStep((prevStep) => prevStep + 1);
							}}
						/>
					</>
				)}
				{step === 2 && (
					<>
						<ButtonCustom
							label="Back"
							btnClassName="button button-grey-light"
							handleClick={() => {
								setStep((prevStep) => prevStep - 1);
							}}
						/>
						<ButtonCustom
							label="Pay & Inscribe"
							btnClassName={`button button-yellow`}
						/>
					</>
				)}
			</div>
		</Modal>
	);
};

export default AddInscribeTransferModal;
