import { useEffect, useState } from "react";
import CustomPopover from "../../components/custom-popover/CustomPopover";
import { KTSVG, checkIsActive } from "../../../../_metronic/helpers";
import Brc20PopoverContent from "./Brc20PopoverContent";
import { useLocation, useNavigate } from "react-router-dom";

type TProps = {
	handleTabData?: (data: any) => void;
};

const NamesPopover = ({ handleTabData }: TProps) => {
	const [shouldOpenPopover, setShouldOpenPopover] = useState<boolean>(false);
	const [tickInfo, setTickInfo] = useState<any>(undefined);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const handleHidePopover = () => {
		setShouldOpenPopover(false);
	};

	const handleOpenChange = (newOpen: boolean) => {
		setShouldOpenPopover(newOpen);
	};

	useEffect(() => {
		const marketplacePath = "/marketplace/brc20";
		!checkIsActive(pathname, marketplacePath) && setTickInfo(undefined);
	}, [pathname]);

	useEffect(() => {
		handleTabData && handleTabData(tickInfo);
		tickInfo && navigate(`/marketplace/${tickInfo.tick}`);
	}, [handleTabData, navigate, tickInfo]);

	return (
		<div className="brc-20-popover-container">
			<CustomPopover
				open={shouldOpenPopover}
				handleOpenChange={handleOpenChange}
				content={
					<Brc20PopoverContent
						handleHidePopover={handleHidePopover}
						handleChangeTickInfo={setTickInfo}
						tickInfo={tickInfo}
					/>
				}
				children={
					<div className={`nav-content ${tickInfo && "active"}`}>
						<div className={`nav-label d-flex align-items-center`}>
							{tickInfo ? tickInfo?.tickName : "brc-20"}
							<KTSVG
								className={`transitionable ${shouldOpenPopover && "icon-item"}`}
								svgClassName="spaces w-12 h-12"
								path="/media/svg/icons/arrow-down-outline.svg"
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default NamesPopover;
