import { ReactNode } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import "./buttonCustom.scss";

type TProps = {
  btnClassName?: string
  svgClassName?: string
  labelClassName?: string
  label?: string | ReactNode
  handleClick?: React.MouseEventHandler<HTMLDivElement>
  iconPath?: string
  imgPath?: string
  isDropdown?: boolean
}

export default function ButtonCustom({
  btnClassName,
  handleClick,
  svgClassName,
  iconPath,
  imgPath,
  labelClassName,
  label,
  isDropdown = false
}: TProps) {
  return (
    <div className={btnClassName} onClick={handleClick}>
      <div className="d-flex align-items-center">
        {iconPath && <KTSVG svgClassName={`${svgClassName} ${label && "mr-8"}`} path={iconPath} />}
        {imgPath && <img className={svgClassName} src={imgPath} alt="" width={26} />}
        <div className={labelClassName}>{label}</div>
        {isDropdown && (<KTSVG svgClassName="dropIcon" path="/media/svg/action/action-02.svg" />)}
      </div>
    </div>
  );
}
