import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { KTSVG } from "../../../../../_metronic/helpers";
import FunbitService from "../../../../services/funbitServices";
import { shortenAddress } from "../../../../utils";
import ConfirmModal from "../trade-page/components/ConfirmModal";
import "./inscriptionDetail.scss";
import { useWallet } from "../../../../../providers/wallet_provider";
import { useMutation } from "react-query";
import ConnectWalletModal from "../../../components/modal/connect-wallet/ConnectWalletModal";

const BTC_TO_SATS = 100000000;

const InscriptionDetailPage = () => {
	const { inscription_id } = useParams();
	const [shouldOpenConfirmBuyModal, setShouldOpenConfirmBuyModal] =
		useState(false);

	const [shouldOpenConnectWalletModal, setShouldOpenConnectWalletModal] =
		useState(false);

	const { unisat, account } = useWallet();

	const { mutateAsync, data: prepareBuyItem } = useMutation(
		FunbitService.createBuyItemPrepare
	);

	const [pubKey, setPubKey] = useState<string>("");

	useEffect(() => {
		(async () => {
			if (account) {
				const key = await unisat?.getPublicKey();
				setPubKey(key!);
			}
		})();
	}, [account, unisat]);

	const { data, refetch } = useQuery({
		queryKey: ["fetchInscriptionInfo", { inscription_id }],
		queryFn: () => FunbitService.fetchInfoInscription(inscription_id || ""),
		enabled: !!inscription_id,
	});

	const handleView = useCallback(() => {
		window.open(data?.view);
	}, [data?.view]);

	const handleGenesis = useCallback(() => {
		window.open(
			`https://mempool.space/testnet/tx/${data?.genesis_transaction}`
		);
	}, [data?.genesis_transaction]);

	const handleContent = useCallback(() => {
		window.open(data?.genesis_transaction);
	}, [data?.genesis_transaction]);

	return (
		<div className="page-container inscription-detail-page">
			<div className="content--left">
				<div className="content-container value">
					{JSON.stringify(data?.brc20 || "")}
				</div>
				{!data?.to && (
					<div
						className="button-buy"
						onClick={async () => {
							try {
								if (account) {
									await mutateAsync({
										auctionId: data?.id || -1,
										bidPrice: data?.init_price || 0,
										pubkey: pubKey,
										address: account,
									});
									setShouldOpenConfirmBuyModal(true);
								} else {
									setShouldOpenConnectWalletModal(true);
								}
							} catch (error: any) {
								console.log(error?.response?.data?.message);
							}
						}}
					>
						<div className="d-flex align-items-center justify-content-between">
							<span>Buy Now</span>
							<span>
								<KTSVG
									path="/media/svg/coins/bitcoin-white.svg"
									svgClassName="spaces w-20 h-20"
								/>
								{(data?.init_price || 0) / BTC_TO_SATS}
							</span>
						</div>
					</div>
				)}
			</div>

			<div className="content--right">
				<div className="spaces fs-32 fw-bold">
					{data?.brc20?.amt}{" "}
					<span
						style={{
							fontFamily: "Proto Mono, Helvetica, Arial, sans-serif",
						}}
					>
						{data?.brc20?.tick}
					</span>
				</div>
				<div className="d-flex w-100 spaces gap-24 mt-16 mb-24 panel-group">
					<div
						className="panel"
						style={{
							fontFamily: "Proto Mono, Helvetica, Arial, sans-serif",
						}}
					>
						<div>Tick</div>
						<Link
							to={`/marketplace/${data?.brc20?.tick}`}
							className="linear-gradient-text"
						>
							{data?.brc20?.tick}
						</Link>
					</div>
					<div className="panel">
						<div>Owner</div>
						<div className="linear-gradient-text">
							{shortenAddress(data?.to || data?.from || "")}
						</div>
					</div>
				</div>
				<div className="spaces fs-24 fw-500 text-color-gray">Information</div>
				<div className="panel spaces mt-16 py-0">
					<div className="desc-item">
						<div className="key">Inscription Number</div>
						<div className="value linear-gradient-text">
							#{data?.inscription_number}
						</div>
					</div>
					<div className="desc-item">
						<div className="key">Inscription Id</div>
						<div className="value">{data?.inscription_id}</div>
					</div>
					<div className="desc-item">
						<div className="key">Address</div>
						<div className="value">{data?.address}</div>
					</div>
					<div className="desc-item">
						<div className="key">Output value</div>
						<div className="value">{data?.output_value}</div>
					</div>
					<div className="desc-item">
						<div className="key">Content</div>
						<div className="value link" onClick={handleContent}>
							{data?.content}
						</div>
					</div>
					<div className="desc-item">
						<div className="key">Content length</div>
						<div className="value">{data?.content_length}</div>
					</div>
					<div className="desc-item">
						<div className="key">Content type</div>
						<div className="value">{data?.content_type}</div>
					</div>
					<div className="desc-item">
						<div className="key">Timestamp</div>
						<div className="value">
							{moment(
								data?.time_stamp ? data?.time_stamp * 1000 : undefined
							).fromNow()}
						</div>
					</div>
					<div className="desc-item">
						<div className="key">Genesis height</div>
						<div className="value">{data?.height}</div>
					</div>
					<div className="desc-item">
						<div className="key">Genesis transaction</div>
						<div className="value link" onClick={handleGenesis}>
							{data?.genesis_transaction}
						</div>
					</div>
					<div className="desc-item">
						<div className="key">View</div>
						<div className="value link" onClick={handleView}>
							Ordinals
						</div>
					</div>
				</div>
			</div>
			{shouldOpenConnectWalletModal && (
				<ConnectWalletModal
					handleClose={() => setShouldOpenConnectWalletModal(false)}
				/>
			)}
			{shouldOpenConfirmBuyModal && (
				<ConfirmModal
					auctionId={data?.id}
					bidPrice={data?.init_price}
					init_price={data?.init_price || 0}
					unit_price={data?.unit_price || 0}
					pubkey={pubKey}
					{...data}
					{...prepareBuyItem}
					handleClose={() => {
						setShouldOpenConfirmBuyModal(false);
						refetch();
					}}
					quantity={Number(data?.brc20?.amt)}
					tick={data?.brc20?.tick || ""}
				/>
			)}
		</div>
	);
};

export default InscriptionDetailPage;
