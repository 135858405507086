import ButtonCustom from "../../components/button/ButtonCustom";

type TProps = {
	handleNextStep: () => void;
	handlePrevStep?: () => void;
	inscribeList?: any[];
	notice?: string;
	inscribeType?: string;
};

const DoubleCheckStep = ({
	handleNextStep,
	handlePrevStep,
	inscribeList,
	notice,
	inscribeType,
}: TProps) => {
	return (
		<div className="double-check-step-container">
			<div className="spaces fs-15 fw-bold text-color-white">
				Please double check your text below before continuing:
			</div>
			<div className="spaces py-16 text-center">
				You are about to inscribe
				<span className="text-color-white">
					{" "}
					{inscribeList?.length} {inscribeType}.
				</span>
			</div>
			{notice && <div className="spaces pb-16 text-center">{notice}</div>}
			<div className="sats-list">
				{inscribeList?.map((inscribeItem, index) => (
					<div className="sats-item" key={index}>
						<div className="sats-index-container">
							<span>{index + 1}</span>
						</div>
						<pre>{inscribeItem}</pre>
					</div>
				))}
			</div>
			<div className="d-flex w-100 spaces gap-24">
				<ButtonCustom
					btnClassName="button button-grey spaces mt-16 w-100"
					labelClassName="spaces fs-14"
					label="Back"
					handleClick={handlePrevStep}
				/>
				<ButtonCustom
					btnClassName="button button-yellow spaces mt-16 w-100"
					labelClassName="spaces fs-14"
					label="Next"
					handleClick={handleNextStep}
				/>
			</div>
		</div>
	);
};

export default DoubleCheckStep;
