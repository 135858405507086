import { useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import Nav from "react-bootstrap/Nav";
import { INSCRIBE_TAB_KEYS } from "../constants";

const PopoverNameContent = ({ tickChooseList, handleHidePopover, changeTabLabel }: any) => {
    const [tickActiveKey, setTickActiveKey] = useState<string | null>(null);

    const handleChooseTick = (tickChooseItem: any) => {
        setTickActiveKey(tickChooseItem?.key);
        changeTabLabel(tickChooseItem);
        handleHidePopover();
    }

    return (
        <Nav.Item key={INSCRIBE_TAB_KEYS.NAMES}>
            <Nav.Link eventKey={INSCRIBE_TAB_KEYS.NAMES}>
                <div className="tick-choose-list">
                    {tickChooseList?.map((tickItem: any) => (
                        <div
                            onClick={() => handleChooseTick(tickItem)}
                            className={`tick-choose-item ${tickActiveKey === tickItem?.key && "active"}`}
                        >
                            <div className="tick-name">{tickItem?.tickName}</div>
                            <a
                                className="tag"
                                target="_blank"
                                href={tickItem?.tickLink}
                                rel="noreferrer"
                            >
                                <KTSVG svgClassName="spaces w-14 h-14" path="/media/svg/icons/global.svg" />
                                <span>{tickItem?.linkName}</span>
                            </a>
                        </div>
                    ))}
                </div>
            </Nav.Link>
        </Nav.Item>
    )
}

export default PopoverNameContent