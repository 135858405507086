import { KTSVG } from "../../../../../_metronic/helpers";
import TableCustom from "../../../components/table/TableCustom";

const MarketCapTab = () => {
	const columns = [
		{
			name: "Coin",
			field: "coin",
			classHeader: "text-start",
			classCell: "text-start",
			render: (rowData: any) => (
				<div className="d-flex align-items-center">
					<img width={20} src={rowData.assetImg} alt="" />
				</div>
			),
		},
		{
			name: "Buyback Value",
			field: "buybackValue",
			classHeader: "text-end",
			classCell: "text-end",
		},
		{
			name: "FUNBIT Bought",
			field: "FUNBIT_bought",
			classHeader: "text-end",
			classCell: "text-end",
		},
		{
			name: "FUNBIT Burned",
			field: "FUNBIT_Burned",
			classHeader: "text-end",
			classCell: "text-end",
		},
		{
			name: "FUNBIT Distributed",
			field: "FUNBIT_Distributed",
			classHeader: "text-end",
			classCell: "text-end",
		},
		{
			name: "FUNBIT Price",
			field: "FUNBIT_Price",
			classHeader: "text-end",
			classCell: "text-end",
		},
		{
			name: "Time Ago",
			field: "timeAgo",
			classHeader: "text-end",
			classCell: "text-end",
		},
		{
			name: (
				<KTSVG
					path="/media/svg/icons/linkIcon.svg"
					width={17}
					height={17}
					svgClassName="text-color-gray"
				/>
			),
			field: "icon",
			classHeader: "text-end",
			classCell: "text-end",
			render: (rowData: any) => (
				<KTSVG
					path="/media/svg/navigation/rollbit-coin/buy-burn.svg"
					width={10}
					height={14}
					color="#fe8a01"
				/>
			),
		},
	];

	const dataTable = [
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},

		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
		{
			assetImg: "/media/images/funbit-coin/funbit-icon.png",
			buybackValue: "$93,047.85",
			FUNBIT_bought: "556,834.73 FUNBIT",
			FUNBIT_Burned: "501,151.25 FUNBIT",
			FUNBIT_Distributed: "55,683.47 FUNBIT",
			FUNBIT_Price: "$0.167101",
			timeAgo: "2h",
		},
	];

	return (
		<>
			<TableCustom columns={columns} data={dataTable} />
		</>
	);
};

export default MarketCapTab;
