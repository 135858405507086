import axios from 'axios';
const baseURL = 'https://mempool.space'

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 10000,
});

axiosInstance.interceptors.response.use((response) => {
    if (response?.status === 200) {
        return response
    }
}, (error) => {
    console.log(error)
    return Promise.reject(error);
})

//https://mempool.space/api/v1/fees/recommended


export const getMempoolRecommendedFees = async (network: 'testnet/' | '' = 'testnet/') => {
    return await axiosInstance.get(`/${network}api/v1/fees/recommended`)
}